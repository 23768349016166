import React, { Component, Fragment } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";
import validator from "validator";

import SpinnerComponent from "../../components/SpinnerComponent";
import ErrorDialog from "../../components/ErrorDialog";
import IndietroButton from "../../components/IndietroButton";

import { clearToken, retrieveToken } from "../../../utils/storage";

import theme from "../../../theme.js";
import { getEsercizioCommerciale } from "../../../utils/api/esercizi_commerciali_api";

const styles = {
  root: {
    height: "100%",
    padding: "26px 10px 26px 10px",
  },
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "20px",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "30px",
  },
  fieldscontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
};

export default class DettaglioEsercizioCommerciale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationErrors: {
        nome: "",
        ragioneSociale: "",
        emailGestore: "",
      },
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: true,
      notfoundRecord: false,
      saveButtonEnabled: false,
      saveMessageVisible: false,

      _isMounted: false, // flag per tracciare se il componente è montato
    };
  }

  componentDidMount() {
    this._isMounted = true; // imposto il flag a true quando il componente è montato
    this.fetchRecord(this.props.esercizioId);
  }

  componentWillUnmount() {
    this._isMounted = false; // resetto il flag quando il componente è smontato
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  fetchRecord = (esercizioId) => {
    this.setState({
      loading: true,
    });
    getEsercizioCommerciale(esercizioId, 0, 1000, "id", this.props.gruppoId)
      .then((result) => {
        if (this._isMounted) {
          this.setState({
            notfoundRecord: false,
            record: result[0],
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
            notfoundRecord: true,
          });
        }
      });
  };

  validateForm = () => {
    let data = this.state.record;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "nome":
        if (value === null || value.length === 0) {
          validationErrors.nome = "Inserire nome";
        } else {
          validationErrors.nome = "";
        }
        break;
      case "ragioneSociale":
        if (value === null || value.length === 0) {
          validationErrors.ragioneSociale = "Inserire ragione sociale";
        } else {
          validationErrors.ragioneSociale = "";
        }
        break;
      case "emailGestore":
        if (value === null || value.length === 0) {
          validationErrors.emailGestore =
            "Inserire indirizzo email del titolare";
        } else {
          if (validator.isEmail(value)) {
            validationErrors.emailGestore = "";
          } else {
            validationErrors.emailGestore =
              "L'indirizzo email inserito non è valido";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleChange = (event) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    if (this.state.saveMessageVisible) {
      this.setState({
        saveMessageVisible: false,
      });
    }
    let name = event.target.name;
    let value = event.target.value;
    let validationErrors = this.state.validationErrors;
    let data = this.state.record;
    let esercizio = this.state.record.esercizio;

    esercizio[name] = value;
    //Reset validations :
    if (name === "nome") {
      validationErrors[name] = "";
    }
    this.setState({
      data,
      validationErrors,
    });
  };

  inviomail = () => {
    let isFormValid = this.validateForm();
    if (isFormValid) {
      this.setState({
        loading: true,
      });
      let token = retrieveToken();
      if (token === null) {
        // If token was deleted, redirect to home page:
        this.handleInvalidToken();
      } else {
        let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
        let authtoken = "Bearer ".concat(token);
        fetch(
          ENDPOINT +
            "/api/esercizi_commerciali/invio_mail/" +
            this.state.record.esercizio.id,
          {
            method: "POST",
            headers: {
              Authorization: authtoken,
              "Content-Type": "application/json",
            },
            withCredentials: true,
            credentials: "include",
          }
        )
          .then((response) => {
            let status = response.status;
            if (status !== 200) {
              if (status === 400) {
                let statusToString = "email già confermata";
                throw new Error(statusToString);
              }
              if (status === 401 || status === 403) {
                let statusToString = "" + status;
                throw new Error(statusToString);
              } else {
                throw new Error("Si è verificato un errore.");
              }
            }
            response.json();
          })
          .then((result) => {
            this.setState({
              loading: false,
            });
            alert("email inviata");
          })
          .catch((error) => {
            let msg = error.message;

            if (msg === "401" || msg === "403") {
              this.handleInvalidToken();
            } else {
              this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: msg,
              });
            }
          });
      }
    }
  };

  updateParentState = (data) => {
    let record = this.state.record;
    record.giorniChiusura = data;
    this.setState({
      record: record,
      saveButtonEnabled: true,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    // const disabledSubmitbutton =
    //   this.state.loading || !this.state.saveButtonEnabled;
    return (
      <div style={styles.mainContainer}>
        <Grid container justify="center" style={{ marginBottom: "26px" }}>
          <div style={{ minHeight: "700px" }}>
            {this.state.loading ? (
              <SpinnerComponent size={24} />
            ) : this.state.notfoundRecord ? (
              <Typography
                variant="h6"
                style={{ paddingTop: "6px", paddingBottom: "6px" }}
              >
                Nessun record trovato
              </Typography>
            ) : (
              <Fragment>
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                  <Container style={{ padding: 10 }}>
                    <Grid container justify="center" alignItems="center">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={styles.sectioncontainer}
                      >
                        {/* <Typography variant="h5" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Dati esercizio</Typography> */}
                        <Grid container style={styles.userdatacontainer}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              style={{ paddingBottom: "10px" }}
                            >
                              <TextField
                                label="ID esercizio"
                                disabled
                                value={
                                  this.state.record.id === null
                                    ? ""
                                    : this.state.record.id
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />

                              <TextField
                                label="Nome"
                                value={
                                  this.state.record.nome === null
                                    ? ""
                                    : this.state.record.nome
                                }
                                name="nome"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                              <TextField
                                label="Ragione sociale"
                                value={
                                  this.state.record.ragioneSociale === null
                                    ? ""
                                    : this.state.record.ragioneSociale
                                }
                                name="ragioneSociale"
                                style={styles.textfield}
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                label="Partita IVA"
                                value={
                                  this.state.record.partitaIva === null
                                    ? ""
                                    : this.state.record.partitaIva
                                }
                                name="partitaIva"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                              <TextField
                                label="Codice ATECO"
                                value={
                                  this.state.record.codiceAteco === null
                                    ? ""
                                    : this.state.record.codiceAteco
                                }
                                name="codiceAteco"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                              <TextField
                                label="Telefono"
                                value={
                                  this.state.record.telefono === null
                                    ? ""
                                    : this.state.record.telefono
                                }
                                name="telefono"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                              <TextField
                                label="Nome titolare"
                                value={
                                  this.state.record.nomeGestore === null
                                    ? ""
                                    : this.state.record.nomeGestore
                                }
                                name="nomeGestore"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                              <TextField
                                label="Cognome titolare"
                                value={
                                  this.state.record.cognomeGestore === null
                                    ? ""
                                    : this.state.record.cognomeGestore
                                }
                                name="cognomeGestore"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              style={{ paddingBottom: "10px" }}
                            >
                              <TextField
                                label="Email titolare"
                                value={
                                  this.state.record.emailGestore === null
                                    ? ""
                                    : this.state.record.emailGestore
                                }
                                name="emailGestore"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                                helperText={
                                  this.state.validationErrors.emailGestore
                                }
                                error={
                                  this.state.validationErrors.emailGestore
                                    .length > 0
                                    ? true
                                    : false
                                }
                              />
                              <TextField
                                label="Telefono titolare"
                                value={
                                  this.state.record.telefonoGestore === null
                                    ? ""
                                    : this.state.record.telefonoGestore
                                }
                                name="telefonoGestore"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                              <TextField
                                label="Indirizzo"
                                value={
                                  this.state.record.indirizzoSede === null
                                    ? ""
                                    : this.state.record.indirizzoSede
                                }
                                name="indirizzoSede"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                              <TextField
                                label="Numero civico"
                                value={
                                  this.state.record.numeroSede === null
                                    ? ""
                                    : this.state.record.numeroSede
                                }
                                name="numeroSede"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />

                              <TextField
                                label="Città"
                                value={
                                  this.state.record.cittaSede === null
                                    ? ""
                                    : this.state.record.cittaSede
                                }
                                name="cittaSede"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                              <TextField
                                label="Provincia"
                                value={
                                  this.state.record.provinciaSede === null
                                    ? ""
                                    : this.state.record.provinciaSede
                                }
                                name="provinciaSede"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                              <TextField
                                label="Codice SDI"
                                defaultValue={
                                  this.state.record.codiceSdi === null
                                    ? ""
                                    : this.state.record.codiceSdi
                                }
                                name="codiceSdi"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                              <TextField
                                label="PEC"
                                defaultValue={
                                  this.state.record.pec === null
                                    ? ""
                                    : this.state.record.pec
                                }
                                name="pec"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={styles.textfield}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>

                  <Grid
                    container
                    justify="center"
                    style={{ paddingTop: "10px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ textAlign: "center" }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.primary.main,
                          visibility: this.state.saveMessageVisible
                            ? "inherit"
                            : "hidden",
                        }}
                      >
                        Dati aggiornati con successo.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ textAlign: "right" }}
                    >
                      <IndietroButton isDisabled={this.state.loading} />
                    </Grid>
                  </Grid>
                </form>
              </Fragment>
            )}
          </div>
        </Grid>

        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        ></ErrorDialog>
      </div>
    );
  }
}

DettaglioEsercizioCommerciale.propTypes = {
  esercizioId: PropTypes.string.isRequired,
  // gruppoId: PropTypes.number.isRequired,
};
