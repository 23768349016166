import React from "react";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search, Sync } from "@material-ui/icons";
import {
  getEserciziCommercialiDaGruppi,
  getEserciziCommercialiDaGruppiCount,
  getEserciziCommercialiForGruppiPersonalizzati,
  getSyncEserciziCommerciali,
} from "../../../utils/api/esercizi_commerciali_api";
import SelectTable from "../../components/SelectTable";
import PropTypes from "prop-types";
import { syncEserciziCommerciali } from "../../../utils/api/sync_api";
import moment from "moment";
import theme from "../../../theme";
import SelectTableForModifica from "../../components/SelectTableForModifica";
import SelectTableForCreazione from "../../components/SelectTableForCreazione";

const buttonStyles = {
  background: "linear-gradient(to right,#0d5fab,#00b1b7,#0d5fab)",
  color: "white",
  borderRadius: 30,
};

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px grey",
    borderRadius: 20,
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "600px",
    display: "flex",
    width: "75%",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evely",
    gap: "20px",
  },
  modalTitle: {
    fontSize: 20,
    paddingTop: 20,
    paddingBottom: 10,
    alignSelf: "center",
  },
  tableContainer: {
    maxHeight: "500px", // Imposta l'altezza massima desiderata
    overflow: "auto",
    width: "100%",
    minWidth: "70vw",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  button: {
    ...buttonStyles,
    marginRight: "20px",
  },
  confirmButton: (disabled) => ({
    ...buttonStyles,
    background: buttonStyles.background,
  }),
  syncDate: {
    display: "flex",
    paddingTop: "5px",
    paddingBottom: "5px",
    justifyContent: "right",
    alignItems: " center",
  },
};

export default class ModalSelezioneEserciziGruppoPersonalizzato extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      esercizi: [],
      selectedEsercizi: this.props.selectedEserciziForPersonalizzato || [],
      loadedPage: 0,
      dataDaMostrare:null,
      orarioDaMostrare:null,
      tablePageNumber: 1,
      pageSize: 10,
      id: "",
      nome: "",
      ragioneSociale: "",
      provincia: "",
      citta: "",
      indirizzo: "",
    };
  }

  componentDidMount() {
    this.setState(
      {
        columns: this.getColumns(),
      },
      () => {
        this.setPageNumber();
        this.setOrarioSincronizzazione();
        this.fetchEsercizi(this.state.loadedPage);
      }
    );
  }

  setOrarioSincronizzazione = async () => {
    const result = await getSyncEserciziCommerciali();
    let dataSincLocale = moment(result.dataTermine);
    let orarioLocale = dataSincLocale.format("HH:mm");
    let dataLocale = dataSincLocale.format("DD.MM.YYYY");
    this.setState({
      orarioDaMostrare:orarioLocale,
      dataDaMostrare:dataLocale
    })
  };

  setPageNumber = () => {
    getEserciziCommercialiDaGruppiCount(this.props.selectedGroups)
      .then((result) => {
        this.setState((prevState) => ({
          tablePageNumber: Math.ceil(result / prevState.pageSize),
        }));
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  fetchEsercizi = (pageIndex) => {
    const { id, nome, ragioneSociale, indirizzo, citta, provincia } =
      this.state;
      getEserciziCommercialiForGruppiPersonalizzati(
      this.props.selectedGroups,
    )
      .then((result) => {
        this.setState({
          esercizi: result,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  searchEsercizi = () => {
    this.setState({ loadedPage: 0 });
    this.fetchEsercizi(0);
  };

  handleSelectionChange = (selectedEsercizi) => {
    this.setState({ selectedEsercizi });
  };

  handleSelectAllChange = (selectedAll) => {
    if (selectedAll) {
      const allEserciziIds = this.state.esercizi.map(
        (esercizio) => esercizio.id
      );
      this.setState((prevState) => ({
        selectedEsercizi: Array.from(
          new Set([...prevState.selectedEsercizi, ...allEserciziIds])
        ),
      }));
    } else {
      const currentPageEserciziIds = new Set(
        this.state.esercizi.map((esercizio) => esercizio.id)
      );
      this.setState((prevState) => ({
        selectedEsercizi: prevState.selectedEsercizi.filter(
          (id) => !currentPageEserciziIds.has(id)
        ),
      }));
    }
  };

  saveEserciziSelection = () => {
    if (typeof this.props.onSelectedEserciziChange === "function") {
      this.props.onSelectedEserciziChange(this.state.selectedEsercizi);
    }

    // this.setState({ selectedEsercizi: [] });

    if (this.props.onClose) this.props.onClose();
  };

  getColumns = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        filterable: true,
        /*Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.id}
            onChange={(e) => {
              this.setState({
                id: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),*/
      },
      {
        Header: "Nome Esercizio",
        id: "nome",
        accessor: (d) => d.nome,
        filterable: true,
        /*Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.nome}
            onChange={(e) => {
              this.setState({
                nome: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),*/
      },
      {
        Header: "Ragione Sociale",
        id: "ragioneSociale",
        accessor: (d) => d.ragioneSociale,
        filterable: true,
        /*Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.ragioneSociale}
            onChange={(e) => {
              this.setState({
                ragioneSociale: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),*/
      },
      {
        Header: "Provincia",
        id: "provincia",
        accessor: (d) => d.provinciaSede,
        filterable: true,
        /*Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.provincia}
            onChange={(e) => {
              this.setState({
                provincia: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),*/
      },
      {
        Header: "Città",
        id: "citta",
        accessor: (d) => d.cittaSede,
        filterable: true,
        /*Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.citta}
            onChange={(e) => {
              this.setState({
                citta: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),*/
      },
      {
        Header: "Indirizzo",
        id: "indirizzo",
        accessor: (d) => d.indirizzoSede,
        filterable: true,
        /*Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.indirizzo}
            onChange={(e) => {
              this.setState({
                indirizzo: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),*/
      },
    ];
  };

  handlePageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () => this.fetchEsercizi(pageIndex)
    );
  };

  handlePageSizeChange = (newPageSize) => {
    this.setState({ pageSize: newPageSize }, () => {
      this.setPageNumber();
      this.fetchEsercizi(this.state.loadedPage);
    });
  };

  render() {
    const isButtonDisabled = this.state.selectedEsercizi.length === 0;

    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={styles.modalcontainer}>
          <div style={styles.modalsectioncontainer}>
            <Typography style={styles.modalTitle}>
              Scegliere gli esercizi da associare al nuovo gruppo di auditing
            </Typography>
            <div style={styles.syncDate}>
            <Sync style={{ color: theme.palette.primary.main }} />
            <Typography
                  variant="subtitle2"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  Ultima sincronizzazione alle {this.state.orarioDaMostrare} del {this.state.dataDaMostrare}
                </Typography>
            </div>
            <SelectTableForCreazione
              selectType={"checkbox"}
              data={this.state.esercizi}
              columns={this.state.columns}
              selectedElement={this.state.selectedEsercizi}
              passSelectionToParent={this.handleSelectionChange}
              onToggleSelectAll={this.handleSelectAllChange}
              style={styles.tableContainer}
            />
            <div style={styles.buttonContainer}>
              <Button onClick={this.props.onClose} style={styles.button}>
                Indietro
              </Button>
              <Button
                variant="contained"
                onClick={this.saveEserciziSelection}
                style={styles.confirmButton(isButtonDisabled)}
              >
                Conferma
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ModalSelezioneEserciziGruppoPersonalizzato.propTypes = {
  selectedEserciziForPersonalizzato: PropTypes.arrayOf(PropTypes.number),
  selectedGroups: PropTypes.arrayOf(PropTypes.number),
  handleInvalidToken: PropTypes.func,
  handleError: PropTypes.func,
  onSelectedEserciziChange: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
