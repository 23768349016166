import React, { Component, Fragment } from "react";
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";

import PropTypes from 'prop-types';
//import theme from '../../../../theme';

const styles = {
    container: {
        paddingBottom: '10px',
        //paddingTop: '4px',
    },
    label: {
        textAlign: 'left',
    },
    formcontrol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
    },
    containerInnerForm: {
        textAlign: 'left'
    },
    formOption: {
        paddingTop: '4px',
        paddingBottom: '4px',
        textAlign: 'left'
    },
    textOption: {
        textAlign: 'left'
    },
    textfield: {
        marginBottom: '12px',
        //width: '360px'
    }
}

export default class FormSecondPartSezioneC extends Component {

    onVoceSelezionataSelected = (e) => {
        this.props.onOptionSelected("voceSelezionata", e)
    }

    onSospensioneAttivitaSelected = (e) => {
        this.props.onOptionSelected("sospensioneAttivitaOptScelta", e);
    }

    buildRadioButtonWithInputFields = (option, index) => {
        if (option.value === "4") {
            return this.buildInnerForm(option, index);
        } else {
            if (option.extraInputs === null) {
                return (
                    <FormControlLabel 
                        key={index} 
                        disabled={this.props.disabled} 
                        value={option.value} 
                        control={<Radio />} 
                        label={option.label} 
                        style={styles.formOption}
                    /> 
                ); 
            } else {
                return (
                    <Fragment key={index} >
                        <FormControlLabel 
                            disabled={this.props.disabled}
                            value={option.value} 
                            control={<Radio />} 
                            label={option.label} 
                            style={styles.formOption}
                        />
                        <Container style={styles.containerInnerForm}>
                            { option.extraInputs.map((extrainputfield, ind) => ( 
                                extrainputfield.type === "text" && 
                                <TextField
                                    key={ind}
                                    placeholder=""
                                    value={this.props.record[extrainputfield.field]}
                                    name={extrainputfield.field}
                                    onChange={this.props.onTextChanged}
                                    variant="outlined"
                                    style={styles.textfield}
                                    fullWidth
                                    disabled={this.props.disabled || option.value !== this.props.voceSelezionata}
                                />
                            ))
                            }
                        </Container> 
                    </Fragment>
                );
            }
        }
   }

    buildInnerForm = (option, index) => {
        return (
            <Fragment key={index} >
                <FormControlLabel 
                    disabled={this.props.disabled} 
                    value={option.value} 
                    control={<Radio />} 
                    label={option.label} 
                    style={styles.formOption}
                />
                <Container style={styles.containerInnerForm}>
                    <TextField
                        placeholder=""
                        value={this.props.sospensioneAttivitaDi}
                        name="sospensioneAttivitaDi"
                        onChange={this.props.onTextChanged}
                        variant="outlined"
                        style={styles.textfield}
                        fullWidth
                        disabled={this.props.disabled || option.value !== this.props.voceSelezionata}
                    />
                    <FormControl style={styles.formcontrol}>
                        <RadioGroup
                            aria-labelledby="sospensioneattivita-buttons-group"
                            name="sospensioneattivita-buttons-group"
                            value={this.props.sospensioneAttivitaOptScelta}
                            onChange={this.onSospensioneAttivitaSelected}
                        >
                            { this.props.optionsSospensioneAttivita.map((opt, ind) => ( 
                                opt.value !== "0" ? 
                                <FormControlLabel 
                                    key={ind} 
                                    disabled={this.props.disabled || option.value !== this.props.voceSelezionata}
                                    value={opt.value} 
                                    control={<Radio />} 
                                    label={opt.label} 
                                    style={styles.formOption}
                                />
                                :
                                <Fragment key={ind} >
                                    <FormControlLabel 
                                        disabled={this.props.disable || option.value !== this.props.voceSelezionata}
                                        value={opt.value} 
                                        control={<Radio />}
                                        label={opt.label} 
                                        style={styles.formOption}
                                    />
                                    <Container key={ind} style={styles.containerInnerForm}>
                                        <TextField
                                            placeholder=""
                                            value={this.props.attoSeparatoNumero}
                                            name="attoSeparatoNumero"
                                            onChange={this.props.onTextChanged}
                                            variant="outlined"
                                            style={styles.textfield}
                                            fullWidth
                                            disabled={this.props.disabled || option.value !== this.props.voceSelezionata || opt.value !== this.props.sospensioneAttivitaOptScelta}
                                        />
                                        <Typography style={styles.textOption}>del</Typography>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                            <DatePicker
                                                value={this.props.attoSeparatoDel}
                                                onChange={(e) => this.props.onDateChanged("attoSeparatoDel", e)}
                                                format="dd-MM-yyyy"
                                                name='attoSeparatoDel'
                                                style={styles.textfield}
                                                disabled={this.props.disabled || option.value !== this.props.voceSelezionata || opt.value !== this.props.sospensioneAttivitaOptScelta}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <Typography style={styles.textOption}>{opt.extraText}</Typography>
                                    </Container>
                                </Fragment>  
                                )) }
                            </RadioGroup>
                        </FormControl>
                        <Typography style={styles.textOption}>{option.extraText}</Typography>
                    </Container> 
                </Fragment>
        );
    }

    render () {
        return (
            <Container style={styles.container} key={3}>
                <Typography style={styles.label} variant='body1'>{this.props.label}</Typography>
                <FormControl style={styles.formcontrol}>
                    <RadioGroup
                        aria-labelledby="sezionec-part2-buttons-group"
                        name={this.props.name}
                        value={this.props.voceSelezionata}
                        onChange={this.onVoceSelezionataSelected}
                    >
                        { this.props.options.map((option, index) => ( 
                            this.buildRadioButtonWithInputFields(option, index)
                        ))
                        }
                    </RadioGroup>
                </FormControl>
            </Container>
        );
    }
}

FormSecondPartSezioneC.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    voceSelezionata: PropTypes.string.isRequired,
    sospensioneAttivitaDi: PropTypes.string.isRequired,
    optionsSospensioneAttivita: PropTypes.array.isRequired,
    sospensioneAttivitaOptScelta:PropTypes.string.isRequired,
    attoSeparatoNumero:  PropTypes.string.isRequired,
    attoSeparatoDel: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    onOptionSelected: PropTypes.func.isRequired,
    onTextChanged: PropTypes.func.isRequired,
    onDateChanged: PropTypes.func.isRequired,
}