import React from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import theme from "../../theme.js";

export default function GoToScuButton(props) {
  let history = useHistory();
  function routeChange() {
    let path = `/amministrazione/scu/` + props.ispezioneId;
    history.push(path);
  }

  return (
    <Button
      type="button"
      onClick={() => {
        routeChange();
      }}
      variant="contained"
      size="medium"
      disabled={props.disabled}
      style={{
        color: theme.palette.secondary.main,
        margin: "10px",
        backgroundColor: props.disabled
          ? theme.palette.disabled.main
          : props.grayversion
          ? theme.palette.secondary.other
          : theme.palette.primary.main,
      }}
    >
      Compila SCU
    </Button>
  );
}

GoToScuButton.propTypes = {
  ispezioneId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
