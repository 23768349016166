import Button from "@material-ui/core/Button";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import React, { Fragment } from "react";
import { Document, Page } from 'react-pdf';
import ErrorDialog from '../../components/ErrorDialog';
import IndietroButton from "../../components/IndietroButton";
import SpinnerComponent from '../../components/SpinnerComponent';
import theme from '../../../theme';
import { getChecklistIspezione } from "../../../utils/api/checklist_api";
import { getIspezioneRevisioni } from "../../../utils/api/ispezione_api";


const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;


const styles = {
    mainContainer: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    paper: {
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '350px'
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    buttoncontainer: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    overflowContainer: {
        overflowY: 'scroll',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: 600

    },
}

export default class ManualiPDF extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
            manualeId: null,
            manuali: [],
            records: [],
            pdf: false,
            record: {
                modeltext: 'Testo del modello ',
                inputtext: '',
            },
            edited: false,
            manual: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            model: null,
            errorDialogVisible: false,
            errorDialogMessage: '',
            loading: false
        };
    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    changePage = (offset) => {
        let pageNumber = this.state.pageNumber
        pageNumber = pageNumber + offset
        this.setState({ pageNumber });
    }

    previousPage = () => {
        this.changePage(-1);
    }

    nextPage = () => {
        this.changePage(1);
    }
    test = () => {
        getChecklistIspezione()
            .then(result => {
                this.setState({
                    test: result,

                });
            })
    }
    fetchRevisioni = () => {
        this.setState({
            loading: true,
        });
        let ispezioneId = this.props.ispezioneId;

        getIspezioneRevisioni(ispezioneId)
            .then(result => {
                this.setState({
                    records: result,
                    manualeId: result[0].id,
                    pdf: true,
                    loading: false
                });
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message,
                        notfoundRecord: true
                    });
                }
            });
    }
    componentDidMount() {
        this.fetchRevisioni()
    }
    handleChange = (event) => {
        this.setState({
            manualeId: event.target.value,
            pdf: true
        })

    };

    render() {
        return (
            <Fragment>
                <div style={styles.mainContainer} >
                    <Paper style={styles.paper} >
                        <Container id="paperContainer" style={{ paddingLeft: '4px', paddingRight: '4px', minHeight: '480px' }} >
                            {this.state.loading ?
                                <SpinnerComponent size={24} />
                                :
                                <Fragment>
                                    <div style={styles.rowcontainer}>

                                        <div>
                                            <Select
                                                value={this.state.manualeId}
                                                name="orarioNotifica"
                                                onChange={this.handleChange}
                                            >

                                                {this.state.records.map((a) => (
                                                    <MenuItem key={a.manuale.id} value={a.id}>
                                                        {moment(a.dataRevisione).format("YYYY-MM-DD") + " " + a.manuale.titolo}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <form autoComplete="off" onSubmit={this.handleSubmit}>
                                            <Button
href={BASE_URL + "/api/revisioni/pdf/" + this.state.manualeId}
color="transparent"
      target="_blank"
      download>FULLSCREEN
 </Button>
                                                <Grid container justify="center" alignItems="center">

                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                                        <Grid container style={styles.textfieldscontainer}>
                                                            <div style={styles.overflowContainer}>
                                                                {this.state.pdf ?
                                                                    <div>
 
                                                                        <Document
                                                                            loading={<div style={styles.mainContainer}>Caricamento pdf in corso, attendere!</div>
                                                                            } file={BASE_URL + "/api/revisioni/pdf/" + this.state.manualeId} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                                            {Array.from(
                                                                                new Array(this.state.numPages),
                                                                                (el, index) => (
                                                                                    <Page
                                                                                        key={`page_${index + 1}`}
                                                                                        pageNumber={index + 1}
                                                                                    />
                                                                                ),
                                                                            )}
                                                                        </Document>
                                                                    </div>
                                                                    : <p>selezionare un manuale</p>}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </form>
                                        </div>

                                    </div>
                                </Fragment>

                            }
                        </Container>
                        <div style={styles.buttoncontainer}>


                            <IndietroButton alert={this.state.edited} isDisabled={this.state.loading} />



                        </div>
                    </Paper>
                </div>
                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>
            </Fragment>
        );
    }

}

ManualiPDF.propTypes = {


}