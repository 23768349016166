import React from "react";
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import PropTypes from 'prop-types';
//import theme from '../../../../theme';

const styles = {
    container: {
        paddingBottom: '10px',
        //paddingTop: '4px',
        textAlign: 'left'
    },
    formcontrol: {
        marginBottom: '8px',
        width: '460px',
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '460px',
        },
    },
};

export default function SelectComponent(props) {
    return (
        <Container style={styles.container}>
            <FormControl style={styles.formcontrol}>
                <InputLabel id={props.id}>
                    {props.label}
                </InputLabel>
                <Select
                    value={props.value}
                    name={props.name}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    MenuProps={MenuProps}
                >
                    { props.options.map((option, index) => 
                    (<MenuItem key={index} value={option.value}>{option.label}</MenuItem>))
                    }
                </Select>
            </FormControl>
        </Container>
    );
}

SelectComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}
