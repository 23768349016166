import React from "react";
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from "@material-ui/core/Typography";

import { withLastLocation } from 'react-router-last-location';
import ErrorDialog from '../components/ErrorDialog';
import SpinnerComponent from '../components/SpinnerComponent';
import GoToScuButton from '../components/GoToScuButton';
import CompleteIspezioneButton from "../components/CompleteIspezioneButton.js";
import ManualiPDF from "./ispezione_manuale/ManualiPDF.js";
import RegistrazioniPDF from "./ispezione_manuale/RegistrazioniPDF.js";
import SchedePDF from "./ispezione_manuale/SchedePDF.js";
//import ModalChecklist from "./ModalChecklist.js";
import DocumentiPDF from "./ispezione_manuale/DocumentiPDF.js";

import PropTypes from 'prop-types';
import theme from '../../theme.js';
import { completaIspezione } from "../../utils/api/ispezione_api.js";
import { clearToken } from '../../utils/storage.js';

const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  title: {
    textAlign: 'center',
  },
  inlinediv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainContainer: {
    textAlign: 'center',
    color: 'black',
    paddingTop: '26px',
    paddingBottom: '26px',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  paper: {
    marginTop: '26px',
    marginBottom: '26px',
    boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px' + theme.palette.primary.main,
  },
  tabsbar: {
    color: 'black',
    boxShadow: '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)'
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class NuovaIspezioneManualeSicurezza extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    //  tabValue: this.initialTab(),
    tabValue:0,
      loading: false,
      edit: false,
      manual: [],
      errorDialogVisible: false,
      errorDialogMessage: '',
      errorDialogTitle: null,
    };
    this.updateEdit = this.updateEdit.bind(this);
  }



  componentDidUpdate(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({ prevPath: this.props.location })
    }
  }

  initialTab = () => {
    if (this.props.lastLocation === null) {
      return 0
    }
    else if (this.props.lastLocation.pathname.startsWith('/amministrazione/modifica_schede_haccp/')) {
      return 8
    }
    else if (this.props.lastLocation.pathname.startsWith('/amministrazione/attrezzature/') || this.props.lastLocation.pathname.startsWith('/amministrazione/modifica_contenutop5/')) {
      return 4
    }
    else if (this.props.lastLocation.pathname.search('procedure') !== -1 
    || this.props.lastLocation.pathname.search('schede') !== -1) {
      return 9
    }
    else {
      return 0
    }
  }

  handleTabChange = (event, newValue) => {
    if (this.state.edit === false) {
      this.setState({
        tabValue: newValue
      });
    }
    else {
      if (window.confirm("Modifiche non salvate, cambiare paragrafo?")) {
        this.setState({
          edit: false,
          tabValue: newValue
        });
      }
    }
  };

  updateEdit(data) {
    this.setState({ edit: data });
  }
  
  handleInvalidToken = () => {
    const errorMessage = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    this.showErrorDialog(null, errorMessage);
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  }

  showErrorDialog = (title, message) => {
    this.setState({
      errorDialogVisible: true,
      errorDialogTitle: title,
      errorDialogMessage: message
    });
  }

  closeErrorDialog = () => {
    this.setState({
        errorDialogVisible: false
    });
  }

 

  getParagrafoManuale = (numeroParagrafo) => {
    const paragrafiManuale = this.state.manual;
    let paragrafo = {};
    for(let i=0; i<paragrafiManuale.length; i++) {
      if (paragrafiManuale[i].numeroParagrafo === numeroParagrafo) {
        paragrafo = paragrafiManuale[i];
        break;
      }
    }
    return paragrafo;
  }

  handleComplete = () => {
    let ispezioneId = this.props.ispezioneId;
    completaIspezione(ispezioneId)
    .then(result => {
      this.setState({
        saveButtonEnabled: false,
        disableForm: true
      });
      alert('ispezione completata')
      //      this.props.onFormSubmitted();
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        let enableSave = true;
        if (error.status === 409) {
          enableSave = false;
        }
        this.setState({
          loading: false,
          saveButtonEnabled: enableSave,
        });
        this.showErrorDialog(null, error.message);
      }
    });
  }

  render() {
    return (
      <div style={styles.mainContainer}>
        { this.state.loading ?
          <SpinnerComponent size={32} />
          :
          <div>
            <Typography style={styles.title} variant="h4" gutterBottom >Ispezione manuale</Typography>
            <div style={styles.inlinediv}>
              <GoToScuButton ispezioneId={this.props.ispezioneId} disabled={this.state.loading} />
              {/* <ModalChecklist ispezioneId={this.props.ispezioneId}></ModalChecklist> */}
              <CompleteIspezioneButton
                disabled={this.state.canComplete}
                onComplete={() => this.handleComplete()}
              />
            </div>
            <div style={styles.root} >
              <Tabs
                variant="scrollable"
                orientation="vertical"
                value={this.state.tabValue}
                onChange={this.handleTabChange}
              >
                <Tab label="Manuale" value={0} />
             {/* <Tab label="Paragrafo 2" value={1} />
                <Tab label="Paragrafo 3" value={2} />
                <Tab label="Paragrafo 4" value={3} />
                <Tab label="Paragrafo 5" value={4} />
               <Tab label="Paragrafo 6" value={5} />
                <Tab label="Paragrafo 7" value={6} />
                 <Tab label="Paragrafo 8" value={7} />
                <Tab label="Paragrafo 9" value={8} />
                <Tab label="Paragrafo 10" value={9} />
               <Tab label="Paragrafo 11" value={10} /> */}  
               <Tab label="Registrazioni" value={11} /> 
               <Tab label="Documenti" value={12} /> 
               <Tab label="Schede" value={13} /> 

              </Tabs>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 0}>
                <Box style={{ paddingTop: '20px', justifyContent: 'center', alignItems: 'center' }} >
                  {this.state.tabValue === 0 && <ManualiPDF ispezioneId={this.props.ispezioneId} />}
                </Box>
              </div>
           {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 1}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 1 && <Paragraph2 manual={this.getParagrafoManuale(2)} edit={this.updateEdit} />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 2}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 2 && <Paragraph3 manual={this.getParagrafoManuale(3)} edit={this.updateEdit}  />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 3}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 3 && <Paragraph4 manual={this.getParagrafoManuale(4)} edit={this.updateEdit} />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 4}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 4 && <Paragraph5 manual={this.getParagrafoManuale(5)} edit={this.updateEdit} />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 5}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 5 && <Paragraph6  manual={this.getParagrafoManuale(6)} edit={this.updateEdit}  />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 6}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 6 && <Paragraph7 manual={this.getParagrafoManuale(7)} edit={this.updateEdit}  />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 7}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 7 && <Paragraph8 manual={this.getParagrafoManuale(8)} edit={this.updateEdit}  />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 8}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 8 && <Paragrafo9 manual={this.getParagrafoManuale(9)} edit={this.updateEdit}  />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 9}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 9 && <Paragrafo10 manual={this.getParagrafoManuale(10)} edit={this.updateEdit}  />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 10}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 10 && <Paragraph11 manual={this.getParagrafoManuale(11)} edit={this.updateEdit}  />}
                </Box>
              </div>
               */}  
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 11}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 11 && <RegistrazioniPDF ispezioneId={this.props.ispezioneId} edit={this.updateEdit}  />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 12}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 12 && <DocumentiPDF ispezioneId={this.props.ispezioneId} edit={this.updateEdit}  />}
                </Box>
              </div>
              <div style={{ flex: 1 }} hidden={this.state.tabValue !== 13}>
                <Box style={{ paddingTop: '20px' }} >
                  {this.state.tabValue === 13 && <SchedePDF ispezioneId={this.props.ispezioneId} edit={this.updateEdit}  />}
                </Box>
              </div>

            </div>
          </div>
          
        }
        <ErrorDialog
          open={this.state.errorDialogVisible} 
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage} 
          onCloseButtonClicked={this.closeErrorDialog} 
        />
      </div>
      );
    }

}
//export default withRouter(NuovoManualeSicurezza);
export default withLastLocation(NuovaIspezioneManualeSicurezza);

NuovaIspezioneManualeSicurezza.propTypes = {
  ispezioneId: PropTypes.string.isRequired
}