import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import theme from "../../theme.js";

export default function ActionButton(props) {
  return (
    <Button
      type="button"
      onClick={props.onClick}
      variant="contained"
      size="medium"
      disabled={props.disabled}
      style={{
        color: theme.palette.secondary.main,
        margin: "10px",
        borderRadius:30,
        backgroundColor: props.disabled
          ? theme.palette.disabled.main
          : props.grayversion
          ? theme.palette.secondary.other
          : theme.palette.primary.main,
      }}
    >
      {props.label}
    </Button>
  );
}

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  grayversion: PropTypes.bool.isRequired,
};
