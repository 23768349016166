import React, { Component } from "react";
import ReactTable from "react-table";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import HighlightOff from "@material-ui/icons/HighlightOff";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import ActionButton from "../../../components/ActionButton";

import PropTypes from "prop-types";
import moment from "moment";
import itLocale from "date-fns/locale/it";
import theme from "../../../../theme";

const styles = {
  mainContainer: {
    flexgrow: 1,
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  modalcontainer: {
    overflowY: "scroll",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "15%",
    marginRight: "15%",
    height: "80%",
  },
  paper: {
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
      theme.palette.primary.main,
  },
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfieldscontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  rowcontainer: {
    flexgrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  center: {
    flex: 1,
    alignSelf: "center",
    whiteSpace: "unset",
    justifyContent: "center",
    textAlign: "center",
  },
  buttoncontainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 0px",
  },
};

export default class SezioniAB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      // punti: [
      //     {
      //         id: 1,
      //         descrizione: 'punto 1',
      //     },
      //     {
      //         id: 2,
      //         descrizione: 'punto 2',
      //     },
      //     {
      //         id: 3,
      //         descrizione: 'punto 3',
      //     }
      // ],
      selected: false,
      edit: false,
      nonConformita: [],
      punti: [],
      ChecklistWithChecklistPunti: [],
      record: {},
      saveButtonEnabled: false,
    };
  }

  componentDidMount = () => {
    const nonConformita = this.props.nonConformitaList;
    const record = this.props.scu;
    const punti = this.props.punti;
    const ChecklistWithChecklistPunti = this.props.ChecklistWithChecklistPunti;
    this.setState({
      nonConformita,
      record,
      punti,
      ChecklistWithChecklistPunti,
    });
  };

  renderEditableDate = (cellInfo) => {
    if (this.state.edit === true) {
      return (
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <DatePicker
              value={cellInfo.value}
              onChange={(date) => {
                const nonConformita = [...this.state.nonConformita];
                nonConformita[cellInfo.index][cellInfo.column.id] = moment(date)
                  .format("yyyy-MM-DD")
                  .toString();
                this.setState({ nonConformita, saveButtonEnabled: true });
                this.props.onChangesNotSaved();
              }}
              format="yyyy/MM/dd"
              label="Data"
              name="data"
              style={styles.textfield}
            />
          </MuiPickersUtilsProvider>
        </div>
      );
    } else {
      return <div>{cellInfo.value}</div>;
    }
  };

  renderEditable = (cellInfo) => {
    if (this.state.edit === true) {
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) => {
            const nonConformita = [...this.state.nonConformita];
            nonConformita[cellInfo.index][cellInfo.column.id] =
              e.target.textContent;
            this.setState({ nonConformita, saveButtonEnabled: true });
            this.props.onChangesNotSaved();
          }}
          dangerouslySetInnerHTML={{
            __html:
              this.state.nonConformita[cellInfo.index][cellInfo.column.id],
          }}
        />
      );
    } else {
      return <div>{cellInfo.value}</div>;
    }
  };

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null) {
      return row[id] !== undefined
        ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
        : true;
    }
  };

  handleSubmit = () => {
    this.setState({ saveButtonEnabled: false });
    let record = this.state.record;
    record.extendedChecklist = this.state.ChecklistWithChecklistPunti;
    record.extendedChecklist.checklistPunti = this.state.punti;
    record.nonConformita = this.state.nonConformita;
    this.props.onSubmit(record);
    // putSCU(record)
    //     .then(result => {
    //         this.setState({
    //             saveButtonEnabled: false,
    //             disableForm: true,
    //             canComplete: false
    //         });
    //         alert('checklist salvata')
    //         //      this.props.onFormSubmitted();
    //     })
    //     .catch(error => {
    //         if (error.status === 403) {
    //             this.handleInvalidToken();
    //         } else {
    //             let enableSave = true;
    //             if (error.status === 409) {
    //                 enableSave = false;
    //             }
    //             this.setState({
    //                 loading: false,
    //                 saveButtonEnabled: enableSave,
    //             });
    //             this.handleError(true, error.message);
    //         }
    //     });
  };

  updateParentState(data) {
    this.props.updateParentState(data[0]);
    this.setState({ modal: false, selected: true });
  }

  deleteelement = (cellInfo) => {
    let array = [...this.state.nonConformita];
    array.splice(cellInfo.index, 1);
    this.setState({
      nonConformita: array,
      edit: false,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  addelement = () => {
    const record = {
      id: null,
      requisito: "-",
      ambitoEvidenza: "-",
      riferimentoNormativo: "-",
      prescrizioneOsservazione: "-",
      tempiAdeguamento: moment().format("yyyy-MM-DD"),
    };
    this.setState({
      nonConformita: [record, ...this.state.nonConformita],
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  checkbox = (cellInfo) => {
    return (
      <Checkbox
        style={{ backgroundColor: "#fafafa" }}
        checked={cellInfo.value}
        onChange={(e) => {
          const punti = [...this.state.punti];
          punti[cellInfo.index].ispezioneCheklist[cellInfo.column.id] =
            !cellInfo.value;
          this.setState({ punti, saveButtonEnabled: true });
          this.props.onChangesNotSaved();
        }}
      />
    );
  };

  checkboxCU = (cellInfo, tipo) => {
    return (
      <Checkbox
        style={{ backgroundColor: "#fafafa" }}
        checked={cellInfo.value === tipo ? true : false}
        onChange={(e) => {
          const punti = [...this.state.punti];
          punti[cellInfo.index].ispezioneCheklist[cellInfo.column.id] = tipo;
          this.setState({ punti, saveButtonEnabled: true });
          this.props.onChangesNotSaved();
        }}
      />
    );
  };

  getColumnsNonConformita = () => {
    return [
      {
        Header: "Requisiti(N° riferimento)",
        id: "requisito",
        accessor: (d) => d.requisito,
        minWidth: 100,
        style: styles.center,
        Cell: this.renderEditable,
      },
      {
        Header: "Ambiti controllate ed evidenze",
        id: "ambitoEvidenza",
        accessor: (d) => d.ambitoEvidenza,
        minWidth: 180,
        style: styles.center,
        Cell: this.renderEditable,
      },
      {
        Header: "Riferimento Normativo",
        id: "riferimentoNormativo",
        accessor: (d) => d.riferimentoNormativo,

        minWidth: 180,
        style: styles.center,
        Cell: this.renderEditable,
      },
      {
        Header: "Prescrizioni/osservazioni",
        id: "prescrizioneOsservazione",
        accessor: (d) => d.prescrizioneOsservazione,
        minWidth: 180,
        style: styles.center,
        Cell: this.renderEditable,
      },
      {
        Header: "Tempi di adeguamento",
        id: "tempiAdeguamento",
        accessor: (d) => (d.tempiAdeguamento ? d.tempiAdeguamento : "-"),
        minWidth: 180,
        style: styles.center,
        Cell: this.renderEditableDate,
      },
      {
        id: "deleteBtn",
        Header: "Elimina",
        accessor: (row) => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: (props) => (
          <IconButton
            color="primary"
            size="small"
            onClick={() => this.deleteelement(props)}
          >
            <HighlightOff style={{ color: theme.palette.primary.main }} />
          </IconButton>
        ),
      },
    ];
  };

  getColumnsPunti = () => {
    return [
      {
        Header: "Indice",
        id: "indice",
        accessor: (d) => d.id,
        minWidth: 50,
        style: styles.center,
        Cell: (row) => row.index + 1,
      },
      {
        Header: "Ambiti",
        id: "ambiti",
        accessor: (d) => d.ambito,
        minWidth: 180,
        style: styles.center,
      },
      {
        Header: "Verifiche",
        id: "verifiche",
        accessor: (d) =>
          d.verifiche.map((value, index) => {
            return (
              <div key={index} style={styles.sectioncontainer}>
                <p>{value.descrizione}</p>
              </div>
            );
          }),
        minWidth: 180,
        style: styles.center,
      },
      {
        Header: "Controllato",
        id: "controllato",
        accessor: (d) => d.ispezioneCheklist.controllato,
        minWidth: 50,
        style: styles.center,
        Cell: this.checkbox,
      },
      {
        Header: "SI",
        id: "risultanzeCU",
        accessor: (d) => d.ispezioneCheklist.risultanzeCU,
        minWidth: 40,
        style: styles.center,
        Cell: (row) => this.checkboxCU(row, "SI"),
      },
      {
        Header: "si",
        id: "risultanzeCU",
        accessor: (d) => d.ispezioneCheklist.risultanzeCU,
        minWidth: 40,
        style: styles.center,
        Cell: (row) => this.checkboxCU(row, "si"),
      },
      {
        Header: "nc",
        id: "risultanzeCU",
        accessor: (d) => d.ispezioneCheklist.risultanzeCU,
        minWidth: 40,
        style: styles.center,
        Cell: (row) => this.checkboxCU(row, "nc"),
      },
      {
        Header: "NC",
        id: "risultanzeCU",
        accessor: (d) => d.ispezioneCheklist.risultanzeCU,
        minWidth: 40,
        style: styles.center,
        Cell: (row) => this.checkboxCU(row, "NC"),
      },
    ];
  };

  render() {
    const columnsPunti = this.getColumnsPunti();
    const recordsPunti = this.state.punti;
    const columnsNonConformita = this.getColumnsNonConformita();
    const recordsNonConformita = this.state.nonConformita;
    return (
      <div>
        <Typography style={{ margin: 10 }} variant="h5">
          Sezione A
        </Typography>
        <ReactTable
          filterable={true}
          resizable={true}
          showPageSizeOptions={true}
          showPageJump={true}
          defaultPageSize={10}
          data={recordsPunti}
          columns={columnsPunti}
          //manual // informs React Table that you'll be handling sorting and pagination server-side
          //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
          previousText="Precedente"
          nextText="Successivo"
          noDataText="Nessun record"
          pageText="Pagina"
          ofText="di"
          rowsText="righe"
          pageJumpText="Vai a pagina"
          rowsSelectorText="righe per pagina"
        />
        <Typography style={{ margin: 10 }} variant="h5">
          Sezione B
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <IconButton
            size="small"
            style={{ color: theme.palette.primary.main }}
            onClick={() => this.addelement()}
          >
            <Add />
          </IconButton>
          <Button
            type="button"
            onClick={() => this.setState({ edit: !this.state.edit })}
            variant="contained"
            size="medium"
            style={{
              color: theme.palette.secondary.main,
              margin: "10px",
              backgroundColor: this.state.edit
                ? "green"
                : theme.palette.primary.main,
            }}
          >
            {this.state.edit ? "edit on" : "edit off"}
          </Button>
        </div>
        <ReactTable
          filterable={true}
          resizable={true}
          showPageSizeOptions={true}
          showPageJump={true}
          defaultPageSize={10}
          data={recordsNonConformita}
          columns={columnsNonConformita}
          //manual // informs React Table that you'll be handling sorting and pagination server-side
          //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
          previousText="Precedente"
          nextText="Successivo"
          noDataText="Nessun record"
          pageText="Pagina"
          ofText="di"
          rowsText="righe"
          pageJumpText="Vai a pagina"
          rowsSelectorText="righe per pagina"
        />
        <div style={styles.buttoncontainer}>
          <ActionButton
            label="Salva sezioni A e B"
            disabled={!this.state.saveButtonEnabled}
            grayversion={"false"}
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

SezioniAB.propTypes = {
  scu: PropTypes.object.isRequired,
  nonConformitaList: PropTypes.array.isRequired,
  punti: PropTypes.array.isRequired,
  ChecklistWithChecklistPunti: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangesNotSaved: PropTypes.func.isRequired,
};
