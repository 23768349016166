import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import BasicTable from "../../components/BasicTable";
import { List, Search, Sync } from "@material-ui/icons";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import theme from "../../../theme";
import {
  getAllPagingGruppiAuditing,
  getSyncEserciziGruppiRapidi,
  updateGruppo,
  updateGruppoPersonalizzato,
} from "../../../utils/api/gruppi-auditing_api";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  getEserciziCommercialiCount,
  getEserciziCommerciali,
} from "../../../utils/api/esercizi_commerciali_api";
import ModalVisualizzaGruppiBSFood from "./ModalVisualizzaGruppiBSFood";
import ModalModificaEserciziGruppoPersonalizzato from "./ModalModificaEserciziGruppoPersonalizzato";
import BasicTableForModifica from "../../components/BasicTableForModifica";
import PropTypes from "prop-types";
import { clearToken } from "../../../utils/storage";
import ErrorDialog from "../../components/ErrorDialog";
import SpinnerComponent from "../../components/SpinnerComponent";

class GruppoVisualizza extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      columns: [],
      loading: true,
      isGruppoRapido: false,
      idEsercizio: "",
      data: {
        name: "",
        id: "",
        bsfoodNames: "",
        bsfoodIds: "",
        tipoGruppo: "",
      },

      id: "",
      nome: "",
      ragione: "",
      provincia: "",
      citta: "",
      indirizzo: "",

      syncOra: "",
      syncData: "",

      inputNameGruppo: "",

      loadedPage: 0,
      totalPages: 1,
      pageSize: 10,
      totalCount: 0,

      isModalVisualizzaGruppiBSFoodOpen: false,

      isModalModificaEserciziOpen: false,
      selectedGroupsLoaded: false,
      newSelectedEserciziForPersonalizzato: [],
      displayRecords: [],

      apiCallCompleted: false,

      errorDialogVisible: false,
      errorDialogMessage: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.setState((prevState) => ({
      displayRecords: prevState.records,
    }));
    this.fetchGruppoId();
    this.fetchSyncEsercizi();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  fetchSyncEsercizi = () => {
    getSyncEserciziGruppiRapidi()
      .then((result) => {
        if (this._isMounted) {
          const dataOrario = new Date(result.dataTermine);
          const dataFormattata = dataOrario.toLocaleDateString("it-IT");
          const orarioFormattato = dataOrario.toLocaleTimeString("it-IT", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          });
          this.setState({
            syncData: dataFormattata,
            syncOra: orarioFormattato,
          });
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        }
      });
  };

  fetchGruppoId = () => {
    getAllPagingGruppiAuditing(0, 10000, "id", this.props.gruppoId)
      .then((result) => {
        this.setState(
          {
            data: {
              name: result[0].name,
              id: result[0].id,
              tipoGruppo: result[0].tipoGruppo,
              bsfoodIds: Object.keys(result[0].idNomeGruppoFoodMap).map(Number), // per la modale di visualizzazione
              bsfoodNames: Object.values(result[0].idNomeGruppoFoodMap),
            },
            inputNameGruppo: result[0].name,
            isGruppoRapido: result[0].tipoGruppo === "GRUPPO_RAPIDO",

            selectedGroupsLoaded: true,
          },
          () => {
            this.fetchCount();
          }
        );
        this.setTableColumns();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  setTableColumns = () => {
    this.setState((prevState) => ({
      columns: prevState.isGruppoRapido
        ? this.getColumns()
        : this.getColumnsForPersonalizzato(),
    }));
  };

  fetchCount = () => {
    getEserciziCommercialiCount(this.state.data.id)
      .then((result) => {
        this.setState(
          {
            totalCount: result,
          },
          () => this.fetchEserciziByBsFood(this.state.loadedPage)
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  fetchEserciziByBsFood = (pageIndex) => {
    const {
      data,
      id,
      nome,
      ragione,
      provincia,
      citta,
      indirizzo,
      pageSize,
      isGruppoRapido,
    } = this.state;

    const effectivePageSize = isGruppoRapido ? pageSize : 10000;

    getEserciziCommercialiCount(data.id)
      .then((resultCount) => {
        return getEserciziCommerciali(
          this.props.ispettoreId,
          pageIndex,
          effectivePageSize,
          "id",
          data.id,
          id,
          nome,
          ragione,
          provincia,
          citta,
          indirizzo
        ).then((resultRecords) => {
          this.setState((prevState) => ({
            totalCount: resultCount, // Set totalCount obtained from the first call
            records: resultRecords,
            displayRecords: resultRecords,
            newSelectedEserciziForPersonalizzato: resultRecords,
            totalPages: Math.ceil(resultCount / prevState.pageSize), // Calculate totalPages using totalCount
            loading: false,
          }));
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      })
      .finally(() => {
        this.setState({ apiCallCompleted: true });
      });
  };

  getColumns = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.id}
            onChange={(e) => {
              let inputValue = e.target.value;
              let regex = /^\d+$/;

              if (regex.test(inputValue) || inputValue === "") {
                this.setState({
                  id: inputValue,
                });
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Nome Esercizio",
        id: "nome",
        accessor: (d) => d.nome,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.nome}
            onChange={(e) => {
              this.setState({
                nome: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Ragione Sociale",
        id: "ragione",
        accessor: (d) => d.ragioneSociale,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.ragione}
            onChange={(e) => {
              this.setState({
                ragione: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Provincia",
        id: "provincia",
        accessor: (d) => d.provinciaSede,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.provincia}
            onChange={(e) => {
              this.setState({
                provincia: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Città",
        id: "cittaSede",
        accessor: (d) => d.cittaSede,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.citta}
            onChange={(e) => {
              this.setState({
                citta: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Indirizzo",
        id: "indirizzoSede",
        accessor: (d) => d.indirizzoSede,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.indirizzo}
            onChange={(e) => {
              this.setState({
                indirizzo: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
    ];
  };

  getColumnsForPersonalizzato = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        filterable: true,
      },
      {
        Header: "Nome Esercizio",
        id: "nome",
        accessor: (d) => d.nome,
        filterable: true,
      },
      {
        Header: "Ragione Sociale",
        id: "ragione",
        accessor: (d) => d.ragioneSociale,
        filterable: true,
      },
      {
        Header: "Provincia",
        id: "provincia",
        accessor: (d) => d.provinciaSede,
        filterable: true,
      },
      {
        Header: "Città",
        id: "cittaSede",
        accessor: (d) => d.cittaSede,
        filterable: true,
      },
      {
        Header: "Indirizzo",
        id: "indirizzoSede",
        accessor: (d) => d.indirizzoSede,
        filterable: true,
      },
    ];
  };

  handlePageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () => this.fetchEserciziByBsFood(pageIndex)
    );
  };

  handleUpdateGruppo = () => {
    const oldListIDs = this.state.records.map((record) => record.id);
    const newListIDs = this.state.newSelectedEserciziForPersonalizzato.map(
      (record) => record.id
    );

    if (this.state.isGruppoRapido) {
      const requestBody = {
        oldName: this.state.data.name,
        newName: this.state.inputNameGruppo,
        nuoviEserciziList: [],
        eserciziOfGruppoList: [],
        gruppoAuditId: this.state.data.id,
      };
      updateGruppo(requestBody)
        .then((response) => {
          const { history } = this.props;
          history.push("/amministrazione/gruppi_di_auditing");
        })
        .catch((error) => {
          console.error("Update failed", error);
        });
    } else {
      const requestBody = {
        oldName: this.state.data.name,
        newName: this.state.inputNameGruppo,
        nuoviEserciziList: newListIDs,
        eserciziOfGruppoList: oldListIDs,
        gruppoAuditId: this.state.data.id,
      };
      updateGruppoPersonalizzato(requestBody)
        .then((response) => {
          const { history } = this.props;
          history.push("/amministrazione/gruppi_di_auditing");
        })
        .catch((error) => {
          console.error("Update failed", error);
        });
    }
  };

  handleChangeNameGruppo = (e) => {
    this.setState({
      inputNameGruppo: e.target.value,
    });
  };

  searchEsercizi = () => {
    this.setState({
      loadedPage: 0,
    });
    this.fetchEserciziByBsFood(0);
  };

  openModalVisualizzaGruppiBSFoodOpen = () => {
    this.setState({ isModalVisualizzaGruppiBSFoodOpen: true });
  };

  closeModalVisualizzaGruppiBSFoodOpen = () => {
    this.setState({ isModalVisualizzaGruppiBSFoodOpen: false });
  };

  openModalModificaEsercizi = () => {
    this.setState({ isModalModificaEserciziOpen: true });
  };

  closeModalModificaEsercizi = () => {
    this.setState({ isModalModificaEserciziOpen: false });
  };

  handleSelectedEserciziForPersonalizzato = (
    newSelectedEserciziForPersonalizzato
  ) => {
    // console.log(
    //   "New selected esercizi: ",
    //   newSelectedEserciziForPersonalizzato
    // );
    this.setState({
      newSelectedEserciziForPersonalizzato,
      displayRecords: newSelectedEserciziForPersonalizzato,
    });
  };

  render() {
    const {
      totalPages,
      pageSize,
      columns,
      loadedPage,
      isGruppoRapido,
      data,
      inputNameGruppo,
      syncData,
      syncOra,
    } = this.state;

    const { handlePageChange, handleUpdateGruppo, handleChangeNameGruppo } =
      this;

    const isAuditor =
      this.props.superAuditorView === false && this.props.ispettoreId !== null;

    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        sx={{ gap: 15 }}
        paddingTop={3}
        marginBottom={10}
        marginX={1}
        flex={1}
      >
        <Typography variant="h4"> Dettagli di gruppo auditing </Typography>

        <Box
          border={1}
          borderColor={"#C5C5C5"}
          borderRadius={15}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingY={3}
          paddingX={10}
          sx={{ gap: 20 }}
          boxshadow={5}
        >
          <Typography variant="h5"> Dati gruppo di auditing </Typography>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            flexWrap={"wrap"}
            sx={{ gap: 30 }}
          >
            <Box textAlign={"left"}>
              <InputLabel>Nome gruppo di auditing</InputLabel>
              <TextField
                style={{ width: 300 }}
                onChange={handleChangeNameGruppo}
                value={inputNameGruppo}
                disabled={isAuditor}
              />
            </Box>

            <Box textAlign={"left"}>
              <InputLabel>Gruppo BS-FOOD</InputLabel>
              {isGruppoRapido ? (
                <TextField
                  style={{ width: 300 }}
                  value={data.bsfoodNames}
                  disabled
                />
              ) : (
                <TextField
                  fullWidth
                  disabled
                  value={"Visualizza"}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          onClick={this.openModalVisualizzaGruppiBSFoodOpen}
                        >
                          <List size="small" style={{ color: "#40d6d6" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>

        <Box
          width={"98%"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ gap: 10 }}
        >
          <Box
            alignSelf={"center"}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            sx={{ gap: 20 }}
            paddingY={5}
            paddingX={1}
            border={1}
            borderRadius={15}
            borderColor={"#C5C5C5"}
            boxshadow={5}
            minHeight="587px"
          >
            <Typography variant="h5">
              {" "}
              Riepilogo degli esercizi selezionati{" "}
            </Typography>
            {this.state.loading ? (
              <SpinnerComponent size={24} />
            ) : (
              <Box display={"flex"} flexDirection={"column"} sx={{ gap: 10 }}>
                {" "}
                <Box alignSelf={"flex-end"} marginRight="20px">
                  {isGruppoRapido ? (
                    <Typography
                      variant="subtitle2"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Sync style={{ color: theme.palette.primary.main }} />
                      {`ULTIMA SINCRONIZZAZIONE ALLE ${syncOra} DEL ${syncData}`}
                    </Typography>
                  ) : (
                    !isAuditor && (
                      <IconButton onClick={this.openModalModificaEsercizi}>
                        <SettingsOutlinedIcon style={{ color: "#40d6d6" }} />
                      </IconButton>
                    )
                  )}
                </Box>
                {isGruppoRapido ? (
                  <BasicTable
                    columns={columns}
                    data={this.state.displayRecords}
                    page={loadedPage}
                    pages={totalPages}
                    defaultPageSize={pageSize}
                    onPageChange={handlePageChange}
                    style={{ overflowY: "hidden" }}
                  />
                ) : (
                  <BasicTableForModifica
                    columns={columns}
                    data={this.state.displayRecords}
                    style={{ overflowY: "hidden" }}
                  />
                )}
              </Box>
            )}
          </Box>

          <Box
            marginBottom={2}
            flex={1}
            alignSelf={"flex-end"}
            display={"flex"}
            sx={{ gap: 10 }}
          >
            <Button
              boxshadow={5}
              onClick={() => {
                const { history } = this.props;
                history.push("/amministrazione/gruppi_di_auditing");
              }}
              style={{
                color: theme.palette.secondary.main,
                borderRadius: 20,
                padding: "5px 30px",
                background:
                  "linear-gradient(to right, #00b1b7,#0d5fab,#00b1b7)",
              }}
            >
              Indietro
            </Button>
            {!isAuditor && (
              <Button
                onClick={handleUpdateGruppo}
                boxshadow={5}
                style={{
                  color: theme.palette.secondary.main,
                  borderRadius: 20,
                  padding: "5px 30px",
                  background:
                    "linear-gradient(to right, #00b1b7,#0d5fab,#00b1b7)",
                }}
              >
                Salva
              </Button>
            )}
          </Box>
        </Box>
        {this.state.selectedGroupsLoaded && (
          <ModalVisualizzaGruppiBSFood
            open={this.state.isModalVisualizzaGruppiBSFoodOpen}
            onClose={this.closeModalVisualizzaGruppiBSFoodOpen}
            selectedGroups={this.state.data.bsfoodIds}
            handleInvalidToken={this.handleInvalidToken}
            handleError={this.handleError}
          />
        )}
        {!isAuditor &&
          this.state.apiCallCompleted && ( // la modal viene aperta solo dopo che la chiamata API per popolare this.state.records è stata completata
            <ModalModificaEserciziGruppoPersonalizzato
              open={this.state.isModalModificaEserciziOpen}
              onClose={this.closeModalModificaEsercizi}
              selectedGroups={this.state.data.bsfoodIds}
              onSelectedEserciziChange={
                this.handleSelectedEserciziForPersonalizzato
              }
              originalEserciziGruppoPersonalizzato={this.state.records}
              // dataId={this.state.data.id}
              newSelectionForPersonalizzato={
                this.state.newSelectedEserciziForPersonalizzato
              }
              handleInvalidToken={this.handleInvalidToken}
              handleError={this.handleError}
            />
          )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </Box>
    );
  }
}

GruppoVisualizza.propTypes = {
  gruppoId: PropTypes.number,
  ispettoreId: PropTypes.number,
  superAuditorView: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(GruppoVisualizza);
