import React from "react";
import Modal from "@material-ui/core/Modal";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import BasicTable from "../../components/BasicTable";
import {
  getEserciziBSFoodCount,
  getEserciziCommercialiFiltered,
  getEserciziCommercialiTestBSFood,
} from "../../../utils/api/esercizi_commerciali_api";
import PropTypes from "prop-types";

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px grey",
    borderRadius: 20,
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //height: '85%'
    minHeight: "600px",
    display: "flex",
    width: "75%",
  },
  miniModalContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #00b1b7",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    height: "400px",
    display: "flex",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    fled: "wrap",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  buttoncontainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "space-evenly",
    padding: "10px 0px",
  },
  modalTitle: {
    fontSize: 20,
    paddingTop: 20,
    paddingBottom: 10,
    alignSelf: "center",
  },
};

export default class ModalVisualizzazioneEserciziPersonalizzato extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      esercizi: [],
      loadedPage: 0,
      tablePageNumber: 0,
      id: "",
      nome: "",
      ragione: "",
      provincia: "",
      citta: "",
      indirizzo: "",
    };
  }

  componentDidMount() {
    let column = this.getColumns();
    this.setState(
      {
        columns: column,
      },
      () => {
        this.setPageNumber();
        this.getEserciziAfterLoading();
      }
    );
  }

  setPageNumber = () => {
    getEserciziBSFoodCount(this.props.selectedGruppo)
      .then((result) => {
        this.setState({
          tablePageNumber:
            Math.ceil(result / 10) <= 0 ? 1 : Math.ceil(result / 10),
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  componentDidUpdate = (_prevProps, prevState) => {
    if (this.state.loadedPage !== prevState.loadedPage) {
      this.getEserciziWithPage(this.state.loadedPage);
    }
  };

  getEserciziWithPage = (page) => {
    if (
      this.state.id.length > 0 ||
      this.state.nome.length > 0 ||
      this.state.ragione.length > 0 ||
      this.state.provincia.length > 0 ||
      this.state.citta.length > 0 ||
      this.state.indirizzo.length > 0
    ) {
      getEserciziCommercialiFiltered(
        this.props.selectedGruppo,
        page,
        10,
        "id",
        this.state.nome,
        this.state.ragione,
        this.state.provincia,
        this.state.citta,
        this.state.indirizzo,
        this.state.id
      )
        .then((result) => {
          this.setState({
            esercizi: result,
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
    } else {
      getEserciziCommercialiTestBSFood(
        this.props.selectedGruppo,
        page,
        10,
        "id"
      )
        .then((result) => {
          this.setState({
            esercizi: result,
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
    }
  };
  searchEsercizi = () => {
    if (
      this.state.id.length > 0 ||
      this.state.nome.length > 0 ||
      this.state.ragione.length > 0 ||
      this.state.provincia.length > 0 ||
      this.state.citta.length > 0 ||
      this.state.indirizzo.length > 0
    ) {
      getEserciziCommercialiFiltered(
        this.props.selectedGruppo,
        0,
        10,
        "id",
        this.state.nome,
        this.state.ragione,
        this.state.provincia,
        this.state.citta,
        this.state.indirizzo,
        this.state.id
      )
        .then((result) => {
          this.setState({
            esercizi: result,
            loadedPage: 0,
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
    } else {
      getEserciziBSFoodCount(this.props.selectedGruppo)
        .then((result) => {
          this.setState(
            {
              tablePageNumber: Math.ceil(result / 10),
            },
            () => {
              this.getEserciziWithPage(this.state.loadedPage);
            }
          );
        })
        .catch((error) => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
    }
  };

  getEserciziAfterLoading = () => {
    getEserciziCommercialiTestBSFood(this.props.selectedGruppo, 0, 10, "id")
      .then((result) => {
        this.setState({
          esercizi: result,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  getColumns = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            value={this.state.id}
            onChange={(e) => {
              this.setState({
                id: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Nome Esercizio",
        id: "nome",
        accessor: (d) => d.nome,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            value={this.state.nome}
            onChange={(e) => {
              this.setState({
                nome: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Ragione Sociale",
        id: "ragione",
        accessor: (d) => d.ragioneSociale,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            value={this.state.ragione}
            onChange={(e) => {
              this.setState({
                ragione: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Provincia",
        id: "provincia",
        accessor: (d) => d.provinciaSede,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            value={this.state.provincia}
            onChange={(e) => {
              this.setState({
                provincia: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Città",
        id: "citta",
        accessor: (d) => d.cittaSede,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            value={this.state.citta}
            onChange={(e) => {
              this.setState({
                citta: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Indirizzo",
        id: "indirizzo",
        accessor: (d) => d.indirizzoSede,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            value={this.state.indirizzo}
            onChange={(e) => {
              this.setState({
                indirizzo: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchEsercizi}>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
    ];
  };

  handlePageChange = (pageIndex) => {
    this.setState({
      loadedPage: pageIndex,
    });
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={styles.modalcontainer}>
          <div style={styles.modalsectioncontainer}>
            <Typography style={styles.modalTitle}>
              Lista degli esercizi presenti nel gruppo
            </Typography>
            <BasicTable
              data={this.state.esercizi}
              columns={this.state.columns}
              page={this.state.loadedPage}
              defaultPageSize={10}
              onPageChange={this.handlePageChange}
              pages={this.state.tablePageNumber}
              style={{ minHeight: 500, minWidth: "70vw" }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

ModalVisualizzazioneEserciziPersonalizzato.propTypes = {
  selectedGruppo: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleInvalidToken: PropTypes.func,
  handleError: PropTypes.func,
};
