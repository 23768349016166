import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Background from "../../../background.png";
import theme from "../../../theme.js";
import DettaglioEsercizioCommerciale from "./DettaglioEsercizioCommerciale.js";
import EsercizixIspezione from "./EsercizixIspezione.js";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";

const themeTab = createMuiTheme({
  overrides: {
    MuiTabs: {
      root: {
        paddingTop: 20,
      },
      indicator: {
        display: "none",
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          border: "1px solid #0d5fab",
        },
        marginRight: 15,
        marginLeft: 15,
        borderRadius: "50px",
        backgroundColor: "transparent",
      },
    },
  },
});

export default class DettaglioEsercizio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      secondTabEnabled: true,
      showRegistrationForm: true,
      showRegistrationMessage: false,
      showChangesSavedMessage: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.setState({
      secondTabEnabled: true,
      showRegistrationForm: false,
    });
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  onChangesSaved = () => {
    //this.props.onClose();
    this.setState({
      showChangesSavedMessage: true,
    });
  };

  onRecordSaved = () => {
    this.setState({
      secondTabEnabled: false,
      showRegistrationMessage: true,
    });
  };

  onFormModified = () => {
    this.setState({
      showRegistrationMessage: false,
      showChangesSavedMessage: false,
    });
  };

  render() {
    const showRegistrationForm = this.state.showRegistrationForm;

    const title = showRegistrationForm
      ? "Nuovo esercizio commerciale"
      : "Dettagli esercizio commerciale";
    return (
      <div
        style={{
          textAlign: "center",
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "70px",
          paddingBottom: "26px",
          paddingLeft: "10px",
          paddingRight: "10px",
          backgroundImage:
            this.state.width > 1160
              ? `url("${Background}"),url("${Background}")`
              : `url("")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "",
          backgroundAttachment: "fixed",
          backgroundPositionY: "50%",
          backgroundPositionX:
            this.state.width > 1899
              ? "130%, -30%"
              : this.state.width > 1799
              ? "140%, -40%"
              : this.state.width > 1599
              ? "150%, -50%"
              : this.state.width > 1399
              ? "170%, -70%"
              : this.state.width > 1299
              ? "190%, -90%"
              : this.state.width > 1250
              ? "210%, -110%"
              : this.state.width > 1200
              ? "230%, -130%"
              : "250%, -150%",
        }}
      >
        <Typography variant="h4">{title}</Typography>
        {this.props.consulenteView && !this.props.superconsulenteView ? (
          <>
            <Box style={{ paddingTop: "20px" }}>
              <Typography
                variant="body1"
                style={{
                  color: theme.palette.primary.main,
                  visibility: this.state.showChangesSavedMessage
                    ? "inherit"
                    : "hidden",
                }}
              >
                Dati aggiornati con successo.
              </Typography>
            </Box>
            <DettaglioEsercizioCommerciale
              esercizioId={this.props.esercizioId}
              onFormModified={this.onFormModified}
              onFormSubmitted={this.onChangesSaved}
              gruppoId={this.props.gruppoId}
            />
          </>
        ) : (
          <>
            <ThemeProvider theme={themeTab}>
              <div hidden={this.state.tabValue !== 0}>
                <Box>
                  {this.state.tabValue === 0 && showRegistrationForm ? (
                    <Fragment>
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.primary.main,
                          visibility: this.state.showRegistrationMessage
                            ? "inherit"
                            : "hidden",
                        }}
                      >
                        Registrazione effettuata con successo.
                      </Typography>
                      <DettaglioEsercizioCommerciale
                        esercizioId={this.props.esercizioId}
                        onFormModified={this.onFormModified}
                        onFormSubmitted={this.onChangesSaved}
                        gruppoId={this.props.gruppoId}
                      />
                    </Fragment>
                  ) : null}
                  {this.state.tabValue === 0 && !showRegistrationForm ? (
                    <Fragment>
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.primary.main,
                          visibility: this.state.showChangesSavedMessage
                            ? "inherit"
                            : "hidden",
                        }}
                      >
                        Dati aggiornati con successo.
                      </Typography>
                      <DettaglioEsercizioCommerciale
                        esercizioId={this.props.esercizioId}
                        onFormModified={this.onFormModified}
                        onFormSubmitted={this.onChangesSaved}
                        gruppoId={this.props.gruppoId}
                      />
                    </Fragment>
                  ) : null}
                </Box>
              </div>
              <div hidden={this.state.tabValue !== 1}>
                <Box style={{ paddingTop: "44px" }}>
                  {this.state.tabValue === 1 && (
                    <EsercizixIspezione
                      esercizioId={this.props.esercizioId}
                      ispettoreId={this.props.ispettoreId}
                    />
                  )}
                </Box>
              </div>
            </ThemeProvider>
          </>
        )}
      </div>
    );
  }
}

DettaglioEsercizio.propTypes = {
  esercizioId: PropTypes.string.isRequired,
  // onClose: PropTypes.func.isRequired,
};
