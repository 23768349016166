import React, { Component, Fragment } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from '../../../components/IndietroButton';
import SezioniAB from './SezioniAB';
import SezioneC from './SezioneC';
import SezioneD from './SezioneD';
import SezioneE from './SezioneE';

import PropTypes from 'prop-types';
import { getIspezione } from "../../../../utils/api/ispezione_api.js";
import { getSCU, putSCU, getSezioneC, updateSezioneC, getSezioneD, updateSezioneD, getSezioneE, updateSezioneE } from "../../../../utils/api/scu_api.js";
import { clearToken } from '../../../../utils/storage';
import theme from '../../../../theme';

const styles = {
    root: {
        height: '100%',
        padding: '26px 10px',
    },
    papercontainer: {
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    container: {
        paddingLeft: '4px',
        paddingRight: '4px',
        minHeight: '480px'
    },
    containerSezione: {
        padding: '6px 0px',
    },
    title: {
        padding: '10px 0px',
        textAlign: 'center',
    },
    errormessage: {
        padding: '20px 0px',
        textAlign: 'center',
    },
    buttoncontainer: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'center',
    }
}

export default class ScuPage extends Component {

    constructor(props) {
        super(props);
        const ispId = parseInt(this.props.ispezioneId);
        this.state = {
            loading: true,
            loadingSezioneC: true,
            loadingSezioneD: true,
            loadingSezioneE: false,
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
            notSaved: false,
            notfoundRecord: false,
            ispezione: {},
            scu: {},
            ChecklistWithChecklistPunti: {},
            punti: [],
            nonConformita: [],
            sezioneC: {
                id: null,
                ispezioneId: ispId,
                dataUltimaModifica: null,
                nonConformitaPresenti: null,
                voceSelezionata: null,
                sospensioneAttivitaDi: null,
                sospensioneAttivitaOptScelta: null,
                attoSeparatoNumero:  null,
                attoSeparatoDel: null,
                verbaleSequestroNumero: null,
                contestazioneInRifA: null,
                autorita: null,
                altro: null,
                documentiEInfo: null,
                signor: null,
                dichiarazioni: null,
                noteOsservazioni: null,
                importoDaPagare: 0.0,
                numeroFaxPagamento: null,
                numeroPagine: 0,
                sezioniSelezionate: null,
                luogoCompilazione: null,
                dataCompilazione: null,
                orarioCompilazione: null
            },
            sezioneD: {
                id: null,
                ispezioneId: ispId,
                dataUltimaModifica: null,
                dataVerifica: null,
                orarioVerificaDa: null,
                orarioVerificaA: null,
                sottoscritto: null,
                nomeCognomeSoggetto: null,
                dataNascitaSoggetto: null,
                luogoNascitaSoggetto: null,
                residenteInSoggetto: null,
                viaPiazzaSoggetto: null,
                numeroCivicoSoggetto: null,
                inQualitaSoggetto: null,
                verifica: null,
                prescrizioneDescrittaIn: null,
                statoPrescrizione: null,
                modalitaEsecuzione: null,
                puntiPrescrizioneRimanenti: null,
                puntiPrescrizioneDaEseguire:null,
                ulterioreProvvedimento: null,
                contestazioniViolazioniInRif: null,
                dichiarazioniRilasciate: null,
                dichiarazioni: null,
                importoDaPagare: 0.0,
                numeroFaxPagamento: null,
                note: null,
                tipoSottoscritto:  null,
                piuDiUnaPrescrizione: null,
                piuDiUnPunto: null
            },
            sezioneE: {
                id: null,
                ispezioneId: ispId,
                dataUltimaModifica: null,
                dataNotificaAtto: null,
                orarioNotificaAtto: null,
                luogoNotificaAtto: null,
                modalitaConsegna: null,
                nomeDestinatario: null,
                cognomeDestinatario: null,
                nomeTramite: null,
                cognomeTramite: null,
                identificatoMezzoDi: null,
                qualificatosi: null,
                luogoConsegna: null,
                indirizzoUfficio: null,
                altro: null,
            }
        };
    }

    componentDidMount = () => {
        this.fetchContentSezioniAB();
    }

    fetchContentSezioniAB = () => {
        const ispezioneId = this.props.ispezioneId;
        getIspezione(ispezioneId, null, 0, 1000, "id")
        .then(result => {
            if (result.length > 0) {
                this.setState({
                    ispezione: result[0],
                }, () => this.getPunti(result[0].checklistId, ispezioneId));
            } else {
                this.setState({
                    loading: false,
                    loadingSezioneC: false,
                    loadingSezioneD: false,
                    loadingSezioneE: false,
                    notfoundRecord: true
                });
            }
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    loadingSezioneC: false,
                    loadingSezioneD: false,
                    loadingSezioneE: false,
                    notfoundRecord: true
                });
                this.showErrorDialog("Errore", error.message);
            }
        });
    }

    getPunti = (checklistId, ispezioneId) => {
        getSCU(checklistId, ispezioneId)
        .then(result => {
            this.setState({
                scu:result,
                ChecklistWithChecklistPunti: result.extendedChecklist,
                punti: result.extendedChecklist.checklistPunti,
                nonConformita: result.nonConformita,
                notfoundRecord: false,
            });
            this.fetchSezioneC();
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    loadingSezioneC: false,
                    loadingSezioneD: false,
                    loadingSezioneE: false,
                    notfoundRecord: true,
                });
                this.showErrorDialog("Errore", error.message);
            }
        });
    }

    fetchSezioneC = () => {
        const ispezioneId = this.props.ispezioneId;
        getSezioneC(ispezioneId)
        .then(result => {
            if (result.status === 204) {
                this.setState({
                    notSaved: false,
                });
            } else {
                this.setState({
                    sezioneC: result,
                    notSaved: false,
                });
            }
            this.fetchSezioneD();
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    loadingSezioneC: false,
                    loadingSezioneD: false,
                    loadingSezioneE: false,
                });
                this.showErrorDialog("Errore", error.message);
            }
        });
    }

    fetchSezioneD = () => {
        const ispezioneId = this.props.ispezioneId;
        getSezioneD(ispezioneId)
        .then(result => {
            if (result.status === 204) {
                this.setState({
                    notSaved: false,
                });
            } else {
                this.setState({
                    sezioneD: result,
                    notSaved: false,
                });
            }
            this.fetchSezioneE();
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    loadingSezioneC: false,
                    loadingSezioneD: false,
                    loadingSezioneE: false,
                });
                this.showErrorDialog("Errore", error.message);
            }
        });
    }

    fetchSezioneE = () => {
        const ispezioneId = this.props.ispezioneId;
        getSezioneE(ispezioneId)
        .then(result => {
            if (result.status === 204) {
                this.setState({
                    notSaved: false,
                    loading: false,
                    loadingSezioneC: false,
                    loadingSezioneD: false,
                    loadingSezioneE: false,
                });
            } else {
                this.setState({
                    sezioneE: result,
                    notSaved: false,
                    loading: false,
                    loadingSezioneC: false,
                    loadingSezioneD: false,
                    loadingSezioneE: false,
                });
            }
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    loadingSezioneC: false,
                    loadingSezioneD: false,
                    loadingSezioneE: false,
                });
                this.showErrorDialog("Errore", error.message);
            }
        });
    }

    updateContentSezioniAB = (record) => {
        this.setState({
            loading: true,
        });
        putSCU(record)
        .then(result => {
            alert('Modifiche alle sezioni A e B salvate correttamente.');
            this.fetchContentSezioniAB();
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                });
                this.showErrorDialog("Errore", "Salvataggio delle sezioni A e B non riuscito, per favore riprovare.");
            }
        });
    }

    updateContentSezioneC = (data) => {
        this.setState({
            loadingSezioneC: true,
        });
        updateSezioneC(data)
        .then(result => {
            this.fetchSezioneC();
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loadingSezioneC: false,
                });
                this.showErrorDialog("Errore", error.message);
            }
        });
    }

    updateContentSezioneD = (data) => {
        this.setState({
            loadingSezioneD: true,
        });
        updateSezioneD(data)
        .then(result => {
            this.fetchSezioneD();
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loadingSezioneD: false,
                });
                this.showErrorDialog("Errore", error.message);
            }
        });
    }

    updateContentSezioneE = (data) => {
        this.setState({
            loadingSezioneE: true,
        });
        updateSezioneE(data)
        .then(result => {
            this.fetchSezioneE();
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loadingSezioneE: false,
                });
                this.showErrorDialog("Errore", error.message);
            }
        });
    }

    onChangesNotSaved = () => {
        // Update state only if the value has changed
        if (!this.state.notSaved) {
            this.setState({ notSaved: true });
        }
    }

    handleInvalidToken = () => {
        const errorMessage = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        this.showErrorDialog("Avviso", errorMessage);
        clearToken();
        window.setTimeout(function () {
          window.location.href = "/";
        }, 4000);
    }
    
    showErrorDialog = (title, message) => {
        this.setState({
          errorDialogVisible: true,
          errorDialogTitle: title,
          errorDialogMessage: message
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }
    
    render() {
        return (
            <Fragment>
                <CssBaseline />
                <Container style={styles.root}>
                    <Paper style={styles.papercontainer} >
                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            :
                            <Container style={styles.container}>
                            { this.state.notfoundRecord ? 
                                <Typography style={styles.errormessage} variant='h5' >Record non trovato</Typography>
                                :
                                <Fragment>
                                    <Typography style={styles.title} variant='h4' >Compila SCU</Typography>
                                    <SezioniAB 
                                        scu={this.state.scu}
                                        nonConformitaList={this.state.nonConformita}
                                        punti={this.state.punti}
                                        ChecklistWithChecklistPunti={this.state.ChecklistWithChecklistPunti}
                                        onSubmit={this.updateContentSezioniAB}
                                        onChangesNotSaved={this.onChangesNotSaved}
                                    />
                                    <Container style={styles.containerSezione}>
                                        { this.state.loadingSezioneC ?
                                            <SpinnerComponent size={24} />
                                            :
                                            <SezioneC
                                                sezioneC={this.state.sezioneC}
                                                onChangesNotSaved={this.onChangesNotSaved}
                                                onSubmit={this.updateContentSezioneC}
                                            />
                                        }
                                    </Container>
                                    <Container style={styles.containerSezione}>
                                        { this.state.loadingSezioneD ?
                                            <SpinnerComponent size={24} />
                                            :
                                            <SezioneD
                                                sezioneD={this.state.sezioneD}
                                                onChangesNotSaved={this.onChangesNotSaved}
                                                onSubmit={this.updateContentSezioneD}
                                            />
                                        }
                                    </Container>
                                    <Container style={styles.containerSezione}>
                                        { this.state.loadingSezioneE ?
                                            <SpinnerComponent size={24} />
                                            :
                                            <SezioneE
                                                sezioneE={this.state.sezioneE}
                                                onChangesNotSaved={this.onChangesNotSaved}
                                                onSubmit={this.updateContentSezioneE}
                                            />
                                        }
                                    </Container>
                                </Fragment>
                            }
                            <div style={styles.buttoncontainer}>
                                <IndietroButton
                                    alert={this.state.notSaved}
                                    isDisabled={this.state.loading}
                                />
                            </div>
                        </Container>
                        }
                    </Paper>
                    <ErrorDialog
                        open={this.state.errorDialogVisible}
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage}
                        onCloseButtonClicked={this.closeErrorDialog}
                    />
                </Container>
            </Fragment>
        );
    }
}

ScuPage.propTypes = {
    ispezioneId: PropTypes.string.isRequired
}