import React, { Component, Fragment } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import ActionButton from "../../../components/ActionButton";
import TextAreaComponent from "./TextAreaComponent";
import NumericFieldComponent from "./NumericFieldComponent";
import TimePickerComponent from "./TimePickerComponent";
import DatePickerComponent from "./DatePickerComponent";
import FormWithRadioButtons from "./FormWithRadioButtons";
import SelectComponent from "./SelectComponent";

import moment from "moment";
import PropTypes from "prop-types";
//import theme from '../../../../theme';

const styles = {
  root: {
    padding: "0px",
    margin: "0px",
  },
  title: {
    padding: "10px 0px",
    textAlign: "center",
  },
  radioGroupContainer: {
    paddingBottom: "10px",
    paddingTop: "4px",
  },
  radioGroupLabel: {
    textAlign: "left",
  },
  radioGroupFormControl: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
  },
  radioGroupOption: {
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "left",
  },
  innerContainer: {
    textAlign: "left",
  },
  formcontrol: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
  },
  formOption: {
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "left",
  },
  buttoncontainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 0px",
  },
};

export default class SezioneD extends Component {
  constructor(props) {
    super(props);
    const sezioneD = this.props.sezioneD;
    this.state = {
      record: {
        id: sezioneD.id,
        ispezioneId: sezioneD.ispezioneId,
        dataUltimaModifica: sezioneD.dataUltimaModifica,
        dataVerifica:
          sezioneD.dataVerifica === null
            ? moment()
            : moment(sezioneD.dataVerifica),
        orarioVerificaDa:
          sezioneD.orarioVerificaDa === null
            ? moment()
            : moment(sezioneD.orarioVerificaDa, "H:mm:ss"),
        orarioVerificaA:
          sezioneD.orarioVerificaA === null
            ? moment()
            : moment(sezioneD.orarioVerificaA, "H:mm:ss"),
        sottoscritto:
          sezioneD.sottoscritto === null ? "" : sezioneD.sottoscritto,
        nomeCognomeSoggetto:
          sezioneD.nomeCognomeSoggetto === null
            ? ""
            : sezioneD.nomeCognomeSoggetto,
        dataNascitaSoggetto:
          sezioneD.dataNascitaSoggetto === null
            ? moment()
            : moment(sezioneD.dataNascitaSoggetto),
        luogoNascitaSoggetto:
          sezioneD.luogoNascitaSoggetto === null
            ? ""
            : sezioneD.luogoNascitaSoggetto,
        residenteInSoggetto:
          sezioneD.residenteInSoggetto === null
            ? ""
            : sezioneD.residenteInSoggetto,
        viaPiazzaSoggetto:
          sezioneD.viaPiazzaSoggetto === null ? "" : sezioneD.viaPiazzaSoggetto,
        numeroCivicoSoggetto:
          sezioneD.numeroCivicoSoggetto === null
            ? ""
            : sezioneD.numeroCivicoSoggetto,
        inQualitaSoggetto:
          sezioneD.inQualitaSoggetto === null ? "" : sezioneD.inQualitaSoggetto,
        verifica: sezioneD.verifica === null ? "" : sezioneD.verifica,
        prescrizioneDescrittaIn:
          sezioneD.prescrizioneDescrittaIn === null
            ? ""
            : sezioneD.prescrizioneDescrittaIn,
        statoPrescrizione:
          sezioneD.statoPrescrizione === null ? "" : sezioneD.statoPrescrizione,
        modalitaEsecuzione:
          sezioneD.modalitaEsecuzione === null
            ? ""
            : sezioneD.modalitaEsecuzione,
        puntiPrescrizioneRimanenti:
          sezioneD.puntiPrescrizioneRimanenti === null
            ? ""
            : sezioneD.puntiPrescrizioneRimanenti.toString(),
        puntiPrescrizioneDaEseguire:
          sezioneD.puntiPrescrizioneDaEseguire === null
            ? ""
            : sezioneD.puntiPrescrizioneDaEseguire,
        ulterioreProvvedimento:
          sezioneD.ulterioreProvvedimento === null
            ? ""
            : sezioneD.ulterioreProvvedimento.toString(),
        contestazioniViolazioniInRif:
          sezioneD.contestazioniViolazioniInRif === null
            ? ""
            : sezioneD.contestazioniViolazioniInRif,
        dichiarazioniRilasciate:
          sezioneD.dichiarazioniRilasciate === null
            ? ""
            : sezioneD.dichiarazioniRilasciate.toString(),
        dichiarazioni:
          sezioneD.dichiarazioni === null ? "" : sezioneD.dichiarazioni,
        importoDaPagare:
          sezioneD.importoDaPagare === null
            ? 0.0
            : parseFloat(sezioneD.importoDaPagare),
        numeroFaxPagamento:
          sezioneD.numeroFaxPagamento === null
            ? ""
            : sezioneD.numeroFaxPagamento,
        note: sezioneD.note === null ? "" : sezioneD.note,
        tipoSottoscritto:
          sezioneD.tipoSottoscritto === null
            ? "SOTTOSCRITTO"
            : sezioneD.tipoSottoscritto,
        piuDiUnaPrescrizione:
          sezioneD.piuDiUnaPrescrizione === null
            ? "false"
            : sezioneD.piuDiUnaPrescrizione.toString(),
        piuDiUnPunto:
          sezioneD.piuDiUnPunto === null
            ? "false"
            : sezioneD.piuDiUnPunto.toString(),
      },
      saveButtonEnabled: false,
      opzioniSottoscritto: [
        {
          label: "Il sottoscritto",
          value: "SOTTOSCRITTO",
        },
        {
          label: "La sottoscritta",
          value: "SOTTOSCRITTA",
        },
        {
          label: "I sottoscritti",
          value: "SOTTOSCRITTI",
        },
      ],
      opzioniTotPrescrizioni: [
        {
          label: "della prescrizione descritta al punto della sezione B:",
          value: "false",
        },
        {
          label: "delle prescrizioni descritte ai punti della sezione B:",
          value: "true",
        },
      ],
      opzioniTotPuntiRimanenti: [
        {
          label: "Punto rimanente da eseguire della prescrizione:",
          value: "false",
        },
        {
          label: "Punti rimanenti da eseguire della prescrizione:",
          value: "true",
        },
      ],
      opzioniTipoVerifica: [
        {
          label: "INTERMEDIA",
          value: "INTERMEDIA",
        },
        {
          label: "CONCLUSIVA",
          value: "CONCLUSIVA",
        },
      ],
      statiPrescrizione: [
        {
          label: "OTTEMPERATO",
          value: "OTTEMPERATO",
        },
        {
          label: "PARZIALMENTE OTTEMPERATO",
          value: "PARZIALMENTE_OTTEMPERATO",
        },
        {
          label: "NON OTTEMPERATO",
          value: "NON_OTTEMPERATO",
        },
      ],
      puntiDaEseguire: [
        {
          label: "SI",
          value: "true",
        },
        {
          label: "NO",
          value: "false",
        },
      ],
      ulterioriProvvedimenti: [
        {
          value: "0",
          label: "reiterazione della prescrizione",
        },
        {
          value: "1",
          label: "nuovo provvedimento prescrittivo",
        },
        {
          value: "2",
          label: "contestazione di violazioni amministrative in riferimento",
        },
      ],
      dichiarazioniRilasciate: [
        {
          label: "SI",
          value: "true",
        },
        {
          label: "NO",
          value: "false",
        },
      ],
    };
  }

  onUpdatedDate = (fieldName, date) => {
    let record = this.state.record;
    record[fieldName] = moment(date);
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  onUpdatedTime = (fieldName, date) => {
    this.onUpdatedDate(fieldName, date);
  };

  onChangedText = (event) => {
    let record = this.state.record;
    record[event.target.name] = event.target.value;
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  onSelectionChanged = (event) => {
    let record = this.state.record;
    record[event.target.name] = event.target.value;
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  onRadioButtonSelected = (fieldName, event) => {
    let record = this.state.record;
    record[fieldName] = event.target.value;
    if (
      fieldName === "puntiPrescrizioneRimanenti" &&
      event.target.value !== "true"
    ) {
      record.puntiPrescrizioneDaEseguire = "";
      record.ulterioreProvvedimento = "";
      record.contestazioniViolazioniInRif = "";
      record.piuDiUnPunto = "false";
    }
    if (
      fieldName === "dichiarazioniRilasciate" &&
      event.target.value !== "true"
    ) {
      record.dichiarazioni = "";
    }
    if (fieldName === "ulterioreProvvedimento" && event.target.value !== "2") {
      record.contestazioniViolazioniInRif = "";
    }
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  handleProvvedimemtiSelection = (e) => {
    let record = this.state.record;
    let value = null;
    for (let i = 0; i < this.state.ulterioriProvvedimenti.length; i++) {
      if (e.target.name === this.state.ulterioriProvvedimenti[i].name) {
        value = this.state.ulterioriProvvedimenti[i].value;
        break;
      }
    }
    if (e.target.checked) {
      record.ulterioreProvvedimento.push(value);
    } else {
      let newarray = record.ulterioreProvvedimento.filter(function (el) {
        return el !== value;
      });
      record.ulterioreProvvedimento = newarray;
    }
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let data = Object.assign({}, this.state.record);
    data.dataVerifica = data.dataVerifica.format("YYYY-MM-DD");
    data.orarioVerificaDa = data.orarioVerificaDa.format("HH:mm:ss");
    data.orarioVerificaA = data.orarioVerificaA.format("HH:mm:ss");
    data.sottoscritto = data.sottoscritto === null ? "" : data.sottoscritto;
    data.nomeCognomeSoggetto =
      data.nomeCognomeSoggetto === "" ? null : data.nomeCognomeSoggetto;
    data.dataNascitaSoggetto = data.dataNascitaSoggetto.format("YYYY-MM-DD");
    data.luogoNascitaSoggetto =
      data.luogoNascitaSoggetto === "" ? null : data.luogoNascitaSoggetto;
    data.residenteInSoggetto =
      data.residenteInSoggetto === "" ? null : data.residenteInSoggetto;
    data.viaPiazzaSoggetto =
      data.viaPiazzaSoggetto === "" ? null : data.viaPiazzaSoggetto;
    data.numeroCivicoSoggetto =
      data.numeroCivicoSoggetto === "" ? null : data.numeroCivicoSoggetto;
    data.inQualitaSoggetto =
      data.inQualitaSoggetto === "" ? null : data.inQualitaSoggetto;
    data.verifica = data.verifica === "" ? null : data.verifica;
    data.prescrizioneDescrittaIn =
      data.prescrizioneDescrittaIn === "" ? null : data.prescrizioneDescrittaIn;
    data.statoPrescrizione =
      data.statoPrescrizione === "" ? null : data.statoPrescrizione;
    data.modalitaEsecuzione =
      data.modalitaEsecuzione === "" ? null : data.modalitaEsecuzione;
    data.puntiPrescrizioneRimanenti =
      data.puntiPrescrizioneRimanenti === ""
        ? null
        : this.state.record.puntiPrescrizioneRimanenti === "true";
    data.puntiPrescrizioneDaEseguire =
      data.puntiPrescrizioneDaEseguire === ""
        ? null
        : data.puntiPrescrizioneDaEseguire;
    data.ulterioreProvvedimento =
      data.ulterioreProvvedimento === ""
        ? null
        : parseInt(data.ulterioreProvvedimento);
    data.contestazioniViolazioniInRif =
      data.contestazioniViolazioniInRif === ""
        ? null
        : data.contestazioniViolazioniInRif;
    data.dichiarazioniRilasciate =
      data.dichiarazioniRilasciate === ""
        ? null
        : this.state.record.dichiarazioniRilasciate === "true";
    data.dichiarazioni = data.dichiarazioni === "" ? null : data.dichiarazioni;
    data.importoDaPagare =
      data.importoDaPagare === "" ? null : parseFloat(data.importoDaPagare);
    data.numeroFaxPagamento =
      data.numeroFaxPagamento === "" ? null : data.numeroFaxPagamento;
    data.note = data.note === "" ? null : data.note;
    data.tipoSottoscritto =
      data.tipoSottoscritto === "" ? "SOTTOSCRITTO" : data.tipoSottoscritto;
    data.piuDiUnaPrescrizione =
      data.piuDiUnaPrescrizione === ""
        ? null
        : this.state.record.piuDiUnaPrescrizione === "true";
    data.puntiPrescrizioneRimanenti =
      data.piuDiUnPunto === ""
        ? null
        : this.state.record.piuDiUnPunto === "true";
    this.props.onSubmit(data);
  };

  render() {
    return (
      <Container style={styles.root}>
        <Typography style={styles.title} variant="h5">
          Sezione D
        </Typography>
        <Typography style={styles.title} variant="h6">
          SEZIONE VERIFICA NON CONFORMITÀ / INADEGUATEZZE
        </Typography>
        <DatePickerComponent
          label="In data:"
          name="dataVerifica"
          value={this.state.record.dataVerifica}
          disabled={false}
          onChange={this.onUpdatedDate}
        />
        <TimePickerComponent
          label="dalle ore:"
          name="orarioVerificaDa"
          value={this.state.record.orarioVerificaDa}
          disabled={false}
          onChange={this.onUpdatedTime}
        />
        <TimePickerComponent
          label="alle ore:"
          name="orarioVerificaA"
          value={this.state.record.orarioVerificaA}
          disabled={false}
          onChange={this.onUpdatedTime}
          multiline={false}
          numRows={1}
        />
        <SelectComponent
          id={"select-tipo-sottoscritto"}
          label="Seleziona un'opzione"
          name="tipoSottoscritto"
          value={this.state.record.tipoSottoscritto}
          options={this.state.opzioniSottoscritto}
          onChange={this.onSelectionChanged}
          disabled={false}
        />
        <TextAreaComponent
          placeholder="Inserire nome e cognome"
          label=""
          name="sottoscritto"
          value={this.state.record.sottoscritto}
          disabled={false}
          onChange={this.onChangedText}
          multiline={false}
          numRows={1}
        />
        <TextAreaComponent
          label="alla presenza di"
          name="nomeCognomeSoggetto"
          value={this.state.record.nomeCognomeSoggetto}
          disabled={false}
          onChange={this.onChangedText}
          multiline={false}
          numRows={1}
        />
        <TextAreaComponent
          label="nato a"
          name="luogoNascitaSoggetto"
          value={this.state.record.luogoNascitaSoggetto}
          disabled={false}
          onChange={this.onChangedText}
          multiline={false}
          numRows={1}
        />
        <DatePickerComponent
          label="in data"
          name="dataNascitaSoggetto"
          value={this.state.record.dataNascitaSoggetto}
          disabled={false}
          onChange={this.onUpdatedDate}
          multiline={false}
          numRows={1}
        />
        <TextAreaComponent
          label="residente in"
          name="residenteInSoggetto"
          value={this.state.record.residenteInSoggetto}
          disabled={false}
          onChange={this.onChangedText}
          multiline={false}
          numRows={1}
        />
        <TextAreaComponent
          label="via/piazza"
          name="viaPiazzaSoggetto"
          value={this.state.record.viaPiazzaSoggetto}
          disabled={false}
          onChange={this.onChangedText}
          multiline={false}
          numRows={1}
        />
        <TextAreaComponent
          label="al numero civico"
          name="numeroCivicoSoggetto"
          value={this.state.record.numeroCivicoSoggetto}
          disabled={false}
          onChange={this.onChangedText}
          multiline={false}
          numRows={1}
        />
        <TextAreaComponent
          label="in qualità di"
          name="inQualitaSoggetto"
          value={this.state.record.inQualitaSoggetto}
          disabled={false}
          onChange={this.onChangedText}
          multiline={false}
          numRows={1}
        />
        <FormWithRadioButtons
          row={true}
          name="verifica-radio-buttons-group"
          label="ha proceduto alla verifica"
          options={this.state.opzioniTipoVerifica}
          value={this.state.record.verifica}
          fieldName={"verifica"}
          disabled={false}
          onChange={this.onRadioButtonSelected}
        />
        <SelectComponent
          id={"select-quantita-prescrizioni"}
          label="Seleziona un'opzione"
          name="piuDiUnaPrescrizione"
          value={this.state.record.piuDiUnaPrescrizione}
          options={this.state.opzioniTotPrescrizioni}
          onChange={this.onSelectionChanged}
          disabled={false}
        />
        <TextAreaComponent
          placeholder="Indicare punto/punti"
          label=""
          name="prescrizioneDescrittaIn"
          value={this.state.record.prescrizioneDescrittaIn}
          disabled={false}
          onChange={this.onChangedText}
          multiline={false}
          numRows={1}
        />
        <FormWithRadioButtons
          row={true}
          name="stato-prescrizione-radio-buttons-group"
          label="La prescrizione è stata "
          options={this.state.statiPrescrizione}
          value={this.state.record.statoPrescrizione}
          fieldName="statoPrescrizione"
          disabled={false}
          onChange={this.onRadioButtonSelected}
        />
        <TextAreaComponent
          label="Modalità di esecuzione:"
          name="modalitaEsecuzione"
          value={this.state.record.modalitaEsecuzione}
          disabled={false}
          onChange={this.onChangedText}
          multiline={true}
          numRows={4}
        />
        <FormWithRadioButtons
          row={true}
          name="punti-rimanenti-radio-buttons-group"
          label="Rimangono punti da eseguire?"
          options={this.state.puntiDaEseguire}
          value={this.state.record.puntiPrescrizioneRimanenti}
          fieldName="puntiPrescrizioneRimanenti"
          disabled={false}
          onChange={this.onRadioButtonSelected}
        />
        <Container style={styles.innerContainer}>
          <SelectComponent
            id={"select-quantita-punti"}
            label="Seleziona un'opzione"
            name="piuDiUnPunto"
            value={this.state.record.piuDiUnPunto}
            options={this.state.opzioniTotPuntiRimanenti}
            onChange={this.onSelectionChanged}
            disabled={
              this.state.record.puntiPrescrizioneRimanenti === null ||
              this.state.record.puntiPrescrizioneRimanenti !== "true"
            }
          />
          <TextAreaComponent
            placeholder=""
            label=""
            name="puntiPrescrizioneDaEseguire"
            value={this.state.record.puntiPrescrizioneDaEseguire}
            disabled={
              this.state.record.puntiPrescrizioneRimanenti === null ||
              this.state.record.puntiPrescrizioneRimanenti !== "true"
            }
            onChange={this.onChangedText}
            multiline={false}
            numRows={1}
          />
          <Container style={styles.innerContainer}>
            <Typography style={styles.paragraph}>
              {"Ulteriore provvedimento che sarà adottato:"}
            </Typography>
            <FormControl style={styles.formcontrol}>
              <RadioGroup
                aria-labelledby="ulteriore-provvedimento-radio-buttons-group"
                name="ulteriore-provvedimento"
                value={this.state.record.ulterioreProvvedimento}
                onChange={(e) => {
                  this.onRadioButtonSelected("ulterioreProvvedimento", e);
                }}
              >
                {this.state.ulterioriProvvedimenti.map((provvedimento, index) =>
                  provvedimento.value !== "2" ? (
                    <FormControlLabel
                      key={index}
                      disabled={
                        this.state.record.puntiPrescrizioneRimanenti === null ||
                        this.state.record.puntiPrescrizioneRimanenti !== "true"
                      }
                      value={provvedimento.value}
                      control={<Radio />}
                      label={provvedimento.label}
                      style={styles.formOption}
                    />
                  ) : (
                    <Fragment key={index}>
                      <FormControlLabel
                        key={index}
                        disabled={
                          this.state.record.puntiPrescrizioneRimanenti ===
                            null ||
                          this.state.record.puntiPrescrizioneRimanenti !==
                            "true"
                        }
                        value={provvedimento.value}
                        control={<Radio />}
                        label={provvedimento.label}
                        style={styles.formOption}
                      />
                      <TextAreaComponent
                        label=""
                        name="contestazioniViolazioniInRif"
                        value={this.state.record.contestazioniViolazioniInRif}
                        disabled={
                          this.state.record.puntiPrescrizioneRimanenti ===
                            null ||
                          this.state.record.puntiPrescrizioneRimanenti !==
                            "true" ||
                          this.state.record.ulterioreProvvedimento !== "2"
                        }
                        onChange={this.onChangedText}
                        multiline={false}
                        numRows={1}
                      />
                    </Fragment>
                  )
                )}
              </RadioGroup>
            </FormControl>
          </Container>
        </Container>
        <FormWithRadioButtons
          row={true}
          name="dichiarazionirilasciate-radio-buttons-group"
          label="Sono state rilasciate dichiarazioni?"
          options={this.state.dichiarazioniRilasciate}
          value={this.state.record.dichiarazioniRilasciate}
          fieldName="dichiarazioniRilasciate"
          disabled={false}
          onChange={this.onRadioButtonSelected}
        />
        <TextAreaComponent
          label="Dichiarazioni rilasciate:"
          name="dichiarazioni"
          value={this.state.record.dichiarazioni}
          disabled={
            this.state.record.dichiarazioniRilasciate === null ||
            this.state.record.dichiarazioniRilasciate !== "true"
          }
          onChange={this.onChangedText}
          multiline={true}
          numRows={4}
        />
        <NumericFieldComponent
          label="L'importo da pagare ai sensi art.4 del D.Lgs. 19/11/08 n.194 è di € "
          name="importoDaPagare"
          value={this.state.record.importoDaPagare}
          disabled={false}
          onChange={this.onChangedText}
        />
        <TextAreaComponent
          label="Copia dell'avvenuto pagamento dovrà essere trasmesso, entro 30 giorni, anche via fax, al numero"
          name="numeroFaxPagamento"
          value={this.state.record.numeroFaxPagamento}
          disabled={false}
          onChange={this.onChangedText}
          multiline={false}
          numRows={1}
        />
        <TextAreaComponent
          label="Note"
          name="note"
          value={this.state.record.note}
          disabled={false}
          onChange={this.onChangedText}
          multiline={true}
          numRows={4}
        />
        <div style={styles.buttoncontainer}>
          <ActionButton
            label="Salva sezione D"
            disabled={!this.state.saveButtonEnabled}
            grayversion={"false"}
            onClick={this.handleSubmit}
          />
        </div>
      </Container>
    );
  }
}

SezioneD.propTypes = {
  sezioneD: PropTypes.object.isRequired,
  onChangesNotSaved: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
