import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import theme from "../../theme.js";
import Background from "../../background.png";
import image from "../../BS_AUDIT_logo.jpg";
import { retrieveToken } from "../../utils/storage.js";

const styles = {
  loginButton: {
    backgroundColor: theme.palette.auditPalette.secondary,
    borderRadius: "30px",
    border: 0,
    color: "white",
    height: 54,
    width: 104,
    fontSize: 16,
    fontWeight: "bold",
  },
  controlpanelButton: {
    backgroundColor: theme.palette.auditPalette.secondary,
    borderRadius: "30px",
    border: 0,
    color: "white",
    height: 68,
    width: 234,
    fontSize: 16,
    fontWeight: "bold",
  },
};

export default class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: false,
      message: "Effettua il Login per gestire la piattaforma.",
      img: image,
      titleImg: "BS_AUDIT_logo",
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    const token = retrieveToken();
    window.addEventListener("resize", this.updateDimensions);
    if (token !== null) {
      this.setState({
        isAuthenticated: true,
        message: "Clicca sul pulsante per procedere",
      });
    } else {
      this.setState({
        isAuthenticated: false,
        message: "Effettua il Login per gestire la piattaforma.",
      });
    }
  }

  render() {
    var accessButton = this.state.isAuthenticated ? (
      <NavLink
        to="/amministrazione/ispettori"
        style={{ textDecoration: "none" }}
      >
        <Button variant="contained" style={styles.controlpanelButton}>
          Pannello Controllo
        </Button>
      </NavLink>
    ) : (
      <NavLink to="/login" style={{ textDecoration: "none" }}>
        <Button variant="contained" style={styles.loginButton}>
          Login
        </Button>
      </NavLink>
    );
    var msg = this.state.message;

    return (
      <React.Fragment>
        <CssBaseline />
        <Container
          id="home_container"
          fixed
          style={{
            minWidth: "100%",
            minHeight: "85vh",
            backgroundImage:
              this.state.width > 1160
                ? `url("${Background}"),url("${Background}")`
                : `url("")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "",
            backgroundAttachment: "fixed",
            backgroundPositionY: "50%",
            backgroundPositionX:
              this.state.width > 1899
                ? "130%, -30%"
                : this.state.width > 1799
                ? "140%, -40%"
                : this.state.width > 1599
                ? "150%, -50%"
                : this.state.width > 1399
                ? "170%, -70%"
                : this.state.width > 1299
                ? "190%, -90%"
                : this.state.width > 1250
                ? "210%, -110%"
                : this.state.width > 1200
                ? "230%, -130%"
                : "250%, -150%",
          }}
        >
          <Typography
            component="div"
            style={{ display: "flex", flexDirection: "column", flex: 1 }}
          >
            <Typography
              component="div"
              style={{ paddingTop: "10px", paddingBottom: "10px", flex: 1 }}
            >
              <Typography
                variant="h4"
                style={{
                  padding: "20px",
                  fontSize: 40,
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: 50,
                }}
              >
                Pannello di controllo di BS-AUDIT
              </Typography>
              <div style={{ flex: 2 }}>
                <img
                  src={this.state.img}
                  alt={this.state.titleImg}
                  style={{ width: "400px", marginBottom: 50 }}
                />
              </div>
            </Typography>
            <Typography
              component="div"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Typography
                variant="h6"
                style={{ padding: "10px", color: theme.palette.primary.main }}
              >
                {msg}
              </Typography>
              <Typography component="div" style={{ padding: "10px" }}>
                {accessButton}
              </Typography>
            </Typography>
          </Typography>
        </Container>
      </React.Fragment>
    );
  }
}
