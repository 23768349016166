import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PropTypes from "prop-types";
import React, { Component } from "react";
// React-table library requires to import css as well:
import "react-table/react-table.css"; // eslint-disable-next-line
import ErrorDialog from "../../components/ErrorDialog";
import ModalResetPsw from "../../components/ModalResetPsw";
import SpinnerComponent from "../../components/SpinnerComponent";
import theme from "../../../theme.js";
import { getAuditors, getCountAuditor } from "../../../utils/api/ispettore_api";
import { clearToken, retrieveToken } from "../../../utils/storage.js";
import EditRecordButton from "../../components/EditRecordButton";
import NewRecordButton from "../../components/NewRecordButton";
import UserActivationDialog from "../../components/UserActivationDialog";
import BasicTable from "../../components/BasicTable";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
};

class IspettoriTable extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      columns: [],
      gruppi: [],
      loading: true,
      errorDialogVisible: false,
      errorDialogMessage: "",
      isUserActivationDialogOpen: false,
      isActivatingUser: false,
      selectedRecord: null,
      selectedEmail: "",
      openResetPswModal: false,
      tokenAdminIspector: null,

      idAuditor: "",
      email: "",
      nome: "",
      cognome: "",
      telefono: "",
      nomeGruppo: "",

      loadedPage: 0,
      pageSize: 10,
      totalPages: 1,
      totalCount: 200,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let columns;
    if (this.props.superAuditorView || !this.props.ispettoreId) {
      columns = this.getColumns();
    } else {
      columns = this.getColumnsForIspettre();
    }

    this.setState({
      columns: columns,
    });

    this.fetchRecords(this.state.loadedPage);
  }

  componentWillUnmount() {
    this._isMounted = false;
    // Qui dovresti annullare qualsiasi sottoscrizione o richiesta asincrona
  }

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  fetchRecords = (pageIndex) => {
    getCountAuditor(this.props.gruppoId)
      .then((result) => {
        if (this._isMounted) {
          this.setState({
            totalCount: result,
          });
        }
        return getAuditors(
          pageIndex,
          this.state.pageSize,
          "id",
          this.state.idAuditor,
          this.state.email,
          this.state.nome,
          this.state.cognome,
          this.state.telefono,
          this.props.gruppoId,
          this.state.nomeGruppo
        );
      })
      .then((result) => {
        if (this._isMounted) {
          this.setState({
            records: result,
            totalPages: Math.ceil(this.state.totalCount / this.state.pageSize),
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  updateUserStatus = () => {
    this.setState({
      loading: true,
    });
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();
    } else {
      let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      let authtoken = "Bearer ".concat(token);
      let userid = this.state.selectedRecord["userId"];
      let path = "/api/utenti/" + userid + "/attiva";
      if (!this.state.isActivatingUser) {
        path = "/api/utenti/" + userid + "/disattiva";
      }
      fetch(ENDPOINT + path, {
        method: "POST",
        headers: {
          Authorization: authtoken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        credentials: "include",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            return response;
          } else {
            if (status === 401 || status === 403) {
              let statusToString = "" + status;
              throw new Error(statusToString);
            } else {
              throw new Error(response.message);
            }
          }
        })
        .then((result) => {
          this.setState(
            {
              loadedPage: 0,
            },
            () => this.fetchRecords(this.state.loadedPage)
          );
        })
        .catch((error) => {
          //Reset activated flag to origina value:
          this.resetUserActivatedFlag();
          //Display error:
          const msg = error.message;
          if (msg === "401" || msg === "403") {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(
              true,
              "Si è verificato un errore. Operazione non riuscita."
            );
          }
        });
    }
  };

  onResetPswButtonClicked = (email) => {
    this.setState({
      selectedEmail: email,
      openResetPswModal: true,
    });
  };

  onResetPswModalClosed = (isOperationCancelled) => {
    if (!isOperationCancelled) {
      this.setState(
        {
          openResetPswModal: false,
          loading: true,
          loadedPage: 0,
        },
        () => {
          this.fetchRecords(this.state.loadedPage);
        }
      );
    } else {
      this.setState({
        openResetPswModal: false,
      });
    }
  };

  onResetPswFailedForAuthError = () => {
    this.setState({
      openResetPswModal: false,
    });
    this.handleInvalidToken();
  };

  onStatusChanged = (record, event) => {
    let flag = event.target.value;
    const elementIndex = this.state.records.findIndex(
      (element) => element.id === record.id
    );
    let newArray = [...this.state.records];
    newArray[elementIndex] = {
      ...newArray[elementIndex],
      activated: !newArray[elementIndex].activated,
    };
    this.setState({
      records: newArray,
      selectedRecord: newArray[elementIndex],
      isUserActivationDialogOpen: true,
      isActivatingUser: flag,
    });
  };

  onStatusChangeConfirmed = (hasConfirmed) => {
    this.closeUserActivationDialog();
    // Proceed with POST only if user confirmed the operation:
    if (hasConfirmed) {
      this.updateUserStatus();
    } else {
      //otherwise reset activated flag to the original value:
      this.resetUserActivatedFlag();
    }
  };

  resetUserActivatedFlag = () => {
    const elementIndex = this.state.records.findIndex(
      (element) => element.id === this.state.selectedRecord.id
    );
    let newArray = [...this.state.records];
    newArray[elementIndex] = {
      ...newArray[elementIndex],
      activated: !newArray[elementIndex].activated,
    };
    this.setState({
      records: newArray,
    });
  };

  closeUserActivationDialog = () => {
    this.setState({
      isUserActivationDialogOpen: false,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  getColumns = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        minWidth: 140,
        filterable: true,

        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.idAuditor}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                this.setState({
                  idAuditor: e.target.value,
                });
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Email",
        id: "email",
        accessor: (d) => d.email,
        minWidth: 160,

        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.email}
            onChange={(e) => {
              this.setState({
                email: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Nome",
        id: "nome",
        accessor: (d) => d.nome,
        minWidth: 180,
        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.nome}
            onChange={(e) => {
              this.setState({
                nome: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Cognome",
        id: "cognome",
        accessor: (d) => d.cognome,
        minWidth: 180,
        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.cognome}
            onChange={(e) => {
              this.setState({
                cognome: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Telefono",
        id: "telefono",
        accessor: (d) => d.telefono,
        width: 185,
        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.telefono}
            onChange={(e) => {
              this.setState({
                telefono: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Gruppo di auditing",
        id: "nomeGruppo",
        accessor: (d) => d.nomeGruppo,
        width: 205,
        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.nomeGruppo}
            onChange={(e) => {
              this.setState({
                nomeGruppo: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Stato",
        id: "activated",
        accessor: (row) => row,
        sortable: true,
        filterable: false,
        resizable: false,
        width: 120,
        Cell: (props) => (
          <FormControl>
            <Select
              value={props.value.activated}
              name="activated"
              onChange={(event) => {
                this.onStatusChanged(props.value, event);
              }}
              defaultValue={false}
            >
              <MenuItem value={true}>Attivo</MenuItem>
              <MenuItem value={false}>Non attivo</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        id: "editBtn",
        Header: "Modifica",
        accessor: (row) => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: (props) => (
          <EditRecordButton
            onClick={() => this.handleRedirect(props.value.id)}
            disabled={false}
          />
        ),
      },
      {
        id: "resetPswBtn",
        Header: "Reset password",
        accessor: (row) => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: (props) => (
          <IconButton
            style={{ color: theme.palette.primary.main }}
            aria-label="reset psw"
            size="small"
            onClick={() => {
              this.onResetPswButtonClicked(props.value.email);
            }}
          >
            <VpnKeyIcon />
          </IconButton>
        ),
      },
    ];
  };

  getColumnsForIspettre = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        minWidth: 140,
        filterable: true,

        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.idAuditor}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                this.setState({
                  idAuditor: e.target.value,
                });
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Email",
        id: "email",
        accessor: (d) => d.email,
        minWidth: 160,

        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.email}
            onChange={(e) => {
              this.setState({
                email: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Nome",
        id: "nome",
        accessor: (d) => d.nome,
        minWidth: 180,
        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.nome}
            onChange={(e) => {
              this.setState({
                nome: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Cognome",
        id: "cognome",
        accessor: (d) => d.cognome,
        minWidth: 180,
        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.cognome}
            onChange={(e) => {
              this.setState({
                cognome: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Telefono",
        id: "telefono",
        accessor: (d) => d.telefono,
        width: 185,
        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.telefono}
            onChange={(e) => {
              this.setState({
                telefono: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Gruppo di auditing",
        id: "nomeGruppo",
        accessor: (d) => d.nomeGruppo,
        width: 205,
        Filter: (cellInfo) => (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.nomeGruppo}
            onChange={(e) => {
              this.setState({
                nomeGruppo: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        loadedPage: 0,
                      });
                      this.fetchRecords(0);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Stato",
        id: "activated",
        accessor: (row) => row,
        sortable: true,
        filterable: false,
        resizable: false,
        width: 120,
        Cell: (props) => (props.value.activated ? "ATTIVO" : "NON ATTIVO"),
      },
      {
        id: "editBtn",
        Header: "Modifica",
        accessor: (row) => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: (props) => (
          <EditRecordButton
            onClick={() => this.handleRedirect(props.value.id)}
            disabled={false}
          />
        ),
      },
    ];
  };

  handleRedirect = (recordId) => {
    this.props.history.push(`/amministrazione/ispettori/${recordId}`);
  };

  handlePageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () => this.fetchRecords(pageIndex)
    );
  };

  render() {
    //const isUserIspettore = this.props.ispettoreId !== null;
    const { records, loadedPage, pageSize, totalPages } = this.state;
    const isAdmin = this.props.ispettoreId === null;
    const isSuperAuditor = this.props.superAuditorView;
    const { handlePageChange } = this;

    return (
      <div>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <div style={styles.mainContainer}>
            {isAdmin || isSuperAuditor ? (
              <div
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "right",
                }}
              >
                <NewRecordButton disabled={false} />
              </div>
            ) : null}
            <BasicTable
              data={records}
              columns={this.state.columns}
              page={loadedPage}
              defaultPageSize={pageSize}
              pages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        <UserActivationDialog
          isOpen={this.state.isUserActivationDialogOpen}
          isActivatingUser={this.state.isActivatingUser}
          onButtonClicked={this.onStatusChangeConfirmed}
        />
        {this.state.openResetPswModal ? (
          <ModalResetPsw
            email={this.state.selectedEmail}
            open={this.state.openResetPswModal}
            onClose={this.onResetPswModalClosed}
            onAuthError={this.onResetPswFailedForAuthError}
          />
        ) : null}
      </div>
    );
  }
}

IspettoriTable.propTypes = {
  gruppoId: PropTypes.number,
};

export default withRouter(IspettoriTable);
