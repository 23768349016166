import {
  Button,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@material-ui/core";
import React, { Component } from "react";
import SelectTable from "../../components/SelectTable";
import {
  getCountGruppiAuditingFromBSFood,
  getGruppiFromBSFOODFiltered,
} from "../../../utils/api/gruppi-auditing_api";
import { List, Search } from "@material-ui/icons";
import ModalEserciziGruppo from "./ModalEserciziGruppo";
import PropTypes from "prop-types";

const buttonStyles = {
  background: "linear-gradient(to right,#0d5fab,#00b1b7,#0d5fab)",
  color: "white",
  borderRadius: 30,
};

const styles = {
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    color: "black",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px grey",
    borderRadius: 20,
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "600px",
    width: "75%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evely",
    gap: "20px",
  },
  table: {
    height: "100%", // Imposta l'altezza massima desiderata
    overflow: "auto",
    width: "70vw",
  },
  button: {
    ...buttonStyles,
    paddingLeft: "20px",
    paddingRight: "20px",
    marginRight: "20px",
  },
  confirmButton: (disabled) => ({
    ...buttonStyles,
    background: disabled ? "" : buttonStyles.background,
  }),
};

export class ModalSelezioneGruppoCustom extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      gruppi: [],
      columns: [],
      pageSize: 10,
      loadedPage: 0,
      totalPages: 0,
      selectedGruppoId: null,
      selectedGroups: [],
      filter: {
        nome: "",
        id: "",
      },
      isModalEserciziGruppoOpen: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      columns: this.getColumns(),
    });
    this.getGruppi(this.state.loadedPage);
    this.setPageNumber();
  }

  componentWillUnmount() {
    this._isMounted = false;
    // Qui annulla qualsiasi sottoscrizione o attività asincrona se necessario
  }

  getGruppi = (pageIndex) => {
    getGruppiFromBSFOODFiltered(
      pageIndex,
      this.state.pageSize,
      "id",
      this.state.filter.id,
      this.state.filter.nome
    )
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            gruppi: data,
          });
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  handleApplyFilterClick = () => {
    this.setState({
      loadedPage: 0,
    });
    this.getGruppi(0);
  };

  handleSelectionChange = (selectedGroups) => {
    this.setState({ selectedGroups });
  };

  handleSelectAllChange = (selectedAll) => {
    if (selectedAll) {
      const allGroupIds = this.state.gruppi.map((gruppo) => gruppo.id);
      this.setState((prevState) => ({
        selectedGroups: Array.from(
          new Set([...prevState.selectedGroups, ...allGroupIds])
        ),
      }));
    } else {
      const currentPageGroupIds = new Set(
        this.state.gruppi.map((gruppo) => gruppo.id)
      );
      this.setState((prevState) => ({
        selectedGroups: prevState.selectedGroups.filter(
          (id) => !currentPageGroupIds.has(id)
        ),
      }));
    }
  };

  saveGroupSelection = () => {
    if (typeof this.props.onSelectedGroupsChange === "function") {
      this.props.onSelectedGroupsChange(this.state.selectedGroups);
    }

    this.setState({ selectedGroups: [] });

    if (this.props.onClose) this.props.onClose();
  };

  setPageNumber = () => {
    getCountGruppiAuditingFromBSFood()
      .then((data) => {
        if (this._isMounted) {
          this.setState((prevState) => ({
            totalPages: Math.ceil(data / prevState.pageSize),
          }));
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  openModalEserciziGruppo = (gruppoId) => {
    this.setState({
      selectedGruppoId: gruppoId,
      isModalEserciziGruppoOpen: true,
    });
  };

  closeModalEserciziGruppo = () => {
    this.setState({ isModalEserciziGruppoOpen: false });
  };

  getColumns = () => {
    return [
      {
        Header: "ID Gruppo BS-FOOD",
        id: "id",
        accessor: (d) => d.id,
        style: { textAlign: "center", height: "44px" },
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            value={this.state.id}
            fullWidth
            onChange={(e) => {
              const value = e.target.value;
              if (!isNaN(value)) {
                this.setState((prevState) => ({
                  filter: { ...prevState.filter, id: value },
                }));
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={this.handleApplyFilterClick}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Nome Gruppo BS-FOOD",
        id: "nome",
        accessor: (d) => d.nome,
        style: { textAlign: "center" },
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.nome}
            onChange={(e) => {
              const value = e.target.value;
              this.setState((prevState) => ({
                filter: { ...prevState.filter, nome: value },
              }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={this.handleApplyFilterClick}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Visualizza",
        id: "visualizza",
        accessor: (row) => row,
        style: { textAlign: "center" },
        filterable: false,
        Cell: (props) => {
          return (
            <IconButton
              size="small"
              onClick={() => this.openModalEserciziGruppo(props.value.id)}
            >
              <List height={30} width={30} style={{ color: "#00b1b7" }}></List>
            </IconButton>
          );
        },
      },
    ];
  };

  handlePageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () => this.getGruppi(pageIndex)
    );
  };

  render() {
    const isButtonDisabled = this.state.selectedGroups.length === 0;

    return (
      <div>
        <Modal open={this.props.open} onClose={this.props.onClose}>
          <div style={styles.modalContainer}>
            <div style={styles.content}>
              <SelectTable
                selectType={"checkbox"}
                data={this.state.gruppi}
                columns={this.state.columns}
                defaultPageSize={this.state.pageSize}
                page={this.state.loadedPage}
                pages={this.state.totalPages}
                onPageChange={this.handlePageChange}
                passSelectionToParent={this.handleSelectionChange}
                onToggleSelectAll={this.handleSelectAllChange}
                showPageSizeOptions={false}
                style={styles.table}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button onClick={this.props.onClose} style={styles.button}>
                  Indietro
                </Button>
                <Button
                  variant="contained"
                  onClick={this.saveGroupSelection}
                  disabled={isButtonDisabled}
                  style={styles.confirmButton(isButtonDisabled)}
                >
                  Conferma
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        {this.state.isModalEserciziGruppoOpen && (
          <ModalEserciziGruppo
            open={this.state.isModalEserciziGruppoOpen}
            onClose={this.closeModalEserciziGruppo}
            selectedGruppo={this.state.selectedGruppoId}
          />
        )}
      </div>
    );
  }
}

ModalSelezioneGruppoCustom.propTypes = {
  handleInvalidToken: PropTypes.func,
  handleError: PropTypes.func,
  onSelectedGroupsChange: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalSelezioneGruppoCustom;
