export const sezione_c_part1 = {
    //correspondingField: 'nonConformitaPresenti',
    label: 'Sono state riscontrate inadeguatezze e/o non conformità:',
    type: 'radiobuttons',
    options: [
        {
            value: "true",
            label: "SI",
            extraInputs: null,
            extraText: null,
        },
        {
            value: "false",
            label: "NO",
            extraInputs: null,
            extraText: null,
        }
    ], 
};

export const sezione_c_part2 = {
    label: 'A seguito di quanto accertato-rilevato, si procede a:',
    type: 'radiobuttons',
    options: [
        {
            value: "0",
            label: "archiviazione per avvenuto riscontro della conformità agli elementi oggetto del controllo;",
            extraInputs: null,
            extraText: null,
        },
        {
            value: "1",
            label: "archiviazione per avvenuta risoluzione di tutte le non conformità riscontrate già nel corso del controllo;",
            extraInputs: null,
            extraText: null,
        },
        {
            value: "2",
            label: "richiesta di adeguamento alle prescrizioni nei tempi indicati nel presente atto;",
            extraInputs: null,
            extraText: null,
        },
        {
            value: "3",
            label: "rinviata la valutazione alla Struttura Organizzativa con atto che verrà successivamente notificato;",
            extraInputs: null,
            extraText: null,
        },
        {
            value: "4",
            label: "sospensione totale / parziale dell'attività di",
            extraInputs: null,
            extraText: "per la quale l'operatore del settore alimentare (OSA) dovrà dare comunicazione di avvenuta rimozione delle non conformità che hanno determinato il provvedimento di sospensione alla Struttura Organizzativa in intestazione;",
        },
        {
            value: "5",
            label: "sequestro , nelle modalità e per le fattispecie descritte nello specifico Verbale di Sequestro n°",
            extraInputs: [
                {
                    type: "text",
                    field: "verbaleSequestroNumero",
                    label: "",
                    value: "",
                }
            ],
            extraText: null,
        },
        {
            value: "6",
            label: "contestazione di violazioni amministrative, nelle modalità e per le fattispecie sono/saranno descritte nello specifico Verbale di accertamento e contestazione in riferimento a: ",
            extraInputs: [
                {
                    type: "text",
                    field: "contestazioneInRifA",
                    label: "",
                    value: "",
                }
            ],
            extraText: null,
        },
        {
            value: "7",
            label: "richiesta di chiusura dell'attività, all'Autorità",
            extraInputs: [
                {
                    type: "text",
                    field: "autorita",
                    label: "",
                    value: "",
                }
            ],
            extraText: null,
        },
        {
            value: "8",
            label: "attivazione procedure di polizia giudiziaria (comunicazione notizia di reato) per riscontro di fatti penalmente rilevanti;",
            extraInputs: null,
            extraText: null,
        },
        {
            value: "9",
            label: "altro: ",
            extraInputs: [
                {
                    type: "text",
                    field: "altro",
                    label: "",
                    value: "",
                }
            ],
            extraText: null,
        }
    ],
    optionsSospensioneAttivita: [
        {
            value: "0",
            label: "con atto separato n° ",
            extraInputs: null,
            extraText: "per motivi di urgenza;",
        },
        {
            value: "1",
            label: "con atto successivo",
            extraInputs: null,
            extraText: null,
        },
    ]
}