import React, { Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

import {
  Link,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import EserciziCommercialiTable from "./tabelle/EserciziCommercialiTable";
// import IspezioniTable from "./tabelle/IspezioniTable.js";
import IspettoriTable from "./tabelle/IspettoriTable";

import theme from "../../theme.js";
import {
  getGruppoId,
  getIspettoreId,
  isSuperAuditor,
} from "../../utils/storage";
import FormRicercaIspezione from "./dettaglio_ispezioni/FormRicercaIspezione";
import GruppiTable from "./tabelle/GruppiTable";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
  controlPanelTitle: {
    paddingTop: 30,
    paddingBottom: 20,
    margin: 0,
  },
  controlPanelContainer: {
    padding: "20px",
    overflow: "hidden",
  },
  tab: {
    wordBreak: "break-word",
    //boxShadow: '1px 0px 2px 0px rgba(0,0,0,1)',
    color: "white",
  },
  buttonGroup: {
    margin: 10,
  },
};
const useStyles = makeStyles((themes) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.auditPalette.primary,
  },
  tab: {
    borderRadius: 50,
    marginRight: 5,
    background: theme.palette.auditPalette.secondary,
    "&.Mui-selected": {
      background: theme.palette.auditPalette.primary,
    },
  },
}));

export default function ControlPanel() {
  const match = useRouteMatch();
  const location = useLocation();
  const classes = useStyles();
  const tabClasses = { root: classes.tab };
  return (
    <div id="main_component_container" style={styles.mainContainer}>
      <Typography variant="h4" style={styles.controlPanelTitle}>
        PANNELLO DI CONTROLLO
      </Typography>
      <div style={styles.controlPanelContainer}>
        <Grid container justify="space-evenly" style={{ marginBottom: "60px" }}>
          <Grid item>
            <AppBar
              position="static"
              style={{ backgroundColor: "white", boxShadow: "none" }}
            >
              <Tabs
                value={location.pathname}
                TabIndicatorProps={{
                  style: {
                    background: theme.palette.auditPalette.primary,
                    width: 0,
                  },
                }}
                indicatorColor="secondary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  classes={tabClasses}
                  style={styles.tab}
                  label="Gruppi di auditing"
                  component={Link}
                  to={`${match.url}/gruppi_di_auditing`}
                  value={`${match.url}/gruppi_di_auditing`}
                />

                <Tab
                  classes={tabClasses}
                  style={styles.tab}
                  label="Auditor"
                  component={Link}
                  to={`${match.url}/ispettori`}
                  value={`${match.url}/ispettori`}
                />
                <Tab
                  classes={tabClasses}
                  style={styles.tab}
                  label="Esercizi commerciali"
                  component={Link}
                  to={`${match.url}/esercizi_commerciali`}
                  value={`${match.url}/esercizi_commerciali`}
                />
                {/* <Tab
                  classes={tabClasses}
                  style={styles.tab}
                  label="Ispezioni"
                  component={Link}
                  to={`${match.url}/ispezioni`}
                  value={`${match.url}/ispezioni`}
                /> */}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        <Switch>
          <Route path={`${match.path}/:tablename`}>
            <TableComponent />
          </Route>
          <Route path={match.path}>
            <TextComponent />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

function TableComponent() {
  const ispettoreId =
    getIspettoreId() === null || getIspettoreId() === "null"
      ? null
      : Number.parseInt(getIspettoreId(), 10);

  const superAuditorView = isSuperAuditor() === "true";
  const gruppoId =
    getGruppoId() === "null" ? null : Number.parseInt(getGruppoId(), 10);

  let { tablename } = useParams();
  let table = null;
  if (tablename === "ispettori") {
    table = (
      <IspettoriTable
        ispettoreId={ispettoreId}
        superAuditorView={superAuditorView}
        gruppoId={gruppoId}
      />
    );
  } else if (tablename === "ispezioni") {
    table = <FormRicercaIspezione />;
  } else if (tablename === "esercizi_commerciali") {
    table = (
      <EserciziCommercialiTable
        ispettoreId={ispettoreId}
        superAuditorView={superAuditorView}
        gruppoId={gruppoId}
      />
    );
  } else if (tablename === "gruppi_di_auditing") {
    table = (
      <GruppiTable
        ispettoreId={ispettoreId}
        superAuditorView={superAuditorView}
        gruppoId={gruppoId}
      />
    );
  }

  return <Fragment>{table}</Fragment>;
}

function TextComponent() {
  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h5">
        Selezionare la tabella da visualizzare cliccando sul tab corrispondente
      </Typography>
    </div>
  );
}
