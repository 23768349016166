import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { clearToken } from "../../utils/storage.js";
import theme from "../../theme.js";
import Background from "../../background.png";

// const styles = {
//     mainContainer: {
//         textAlign: 'center',
//         color: 'black',
//         padding: '10px',
//         minHeight: '100vh'
//     }
// }

export default function LogoutPage() {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
    //this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  return (
    <div
      style={{
        textAlign: "center",
        color: "black",
        padding: "10px",
        minHeight: "100vh",
        backgroundImage:
          width > 1160
            ? `url("${Background}"),url("${Background}")`
            : `url("")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "",
        backgroundAttachment: "fixed",
        backgroundPositionX:
          width > 1799
            ? "140%, -40%"
            : width > 1599
            ? "150%, -50%"
            : width > 1399
            ? "170%, -70%"
            : width > 1299
            ? "190%, -90%"
            : width > 1250
            ? "210%, -110%"
            : width > 1200
            ? "230%, -130%"
            : "250%, -150%",
      }}
    >
      <Typography variant="h5" style={{ padding: "30px" }}>
        Effettua il log out dalla piattaforma.
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            clearToken();
            window.location.href = "/";
          }}
          style={{
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
            margin: 10,
            borderRadius: 30,
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
}
