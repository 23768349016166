import React from "react";
import Modal from "@material-ui/core/Modal";
import { Button, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { CancelRounded, Search } from "@material-ui/icons";
import SelectTable from "../../components/SelectTable";
import {
  getCountGruppiAuditingFromBSFood,
  getGruppiFromBSFOOD,
  getGruppiFromBSFOODFiltered,
} from "../../../utils/api/gruppi-auditing_api";
import PropTypes from "prop-types";

const buttonStyles = {
  background: "linear-gradient(to right,#0d5fab,#00b1b7,#0d5fab)",
  color: "white",
  borderRadius: 30,
};

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px grey",
    borderRadius: 10,
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //height: '85%'
    minHeight: "600px",
    display: "flex",
    width: "75%",
  },
  miniModalContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #00b1b7",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    height: "400px",
    display: "flex",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    fled: "wrap",
    marginTop: 10,
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  buttoncontainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "space-evenly",
    padding: "20px 0px",
    width:"100%",
  },
  modalTitle: {
    fontSize: 20,
    paddingTop: 20,
    paddingBottom: 10,
    alignSelf: "center",
  },
  confirmButton: (disabled) => ({
    ...buttonStyles,
    background: disabled ? "" : buttonStyles.background,
  }),
  buttonStyleGraphics:{
    background: "linear-gradient(to right,#0d5fab,#00b1b7,#0d5fab)",
    color: "white",
    borderRadius: 30,
  }
};

export default class ModalSelezioneGruppoRapido extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      gruppi: [],
      loadedPage: 0,
      tablePageNumber: 0,
      selectedGroup: this.props.selectedGroup ? this.props.selectedGroup : null,
      selectedGroupData: this.props.selectedGroupData ? this.props.selectedGroupData : null,
      filtered: false,
      isButtonDisabled:this.props.selectedGroup === null ? true : false,
      nome: "",
      id: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let column = this.getColumns();
    this.setState(
      {
        columns: column,
      },
      () => {
        this.setPageNumber();
        this.getGruppiAfterLoading();
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false; // Imposta a false quando il componente viene smontato
  }

  setPageNumber = () => {
    getCountGruppiAuditingFromBSFood()
      .then((result) => {
        if (this._isMounted) {
          this.setState({
            tablePageNumber: Math.ceil(result / 10),
          });
        }
      })
      .catch((error) => {
        this.props.handleError(true, error);
      });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.open !== this.props.open) {
      this.setState({
        loadedPage: 0,
      });
    }
    if (this.state.loadedPage !== prevState.loadedPage) {
      this.getGruppiWithPage(this.state.loadedPage);
    }
  };

  getGruppiWithPage = (page) => {
    if (this.state.nome.length > 0 || this.state.id.length > 0) {
      getGruppiFromBSFOODFiltered(0, 10, "id", this.state.id, this.state.nome)
        .then((result) => {
          this.setState({
            gruppi: result,
          });
        })
        .catch((error) => {
          this.props.handleError(true, error);
        });
    } else {
      getGruppiFromBSFOOD(page, 10, "id")
        .then((result) => {
          this.setState({
            gruppi: result,
          });
        })
        .catch((error) => {
          this.props.handleError(true, error);
        });
    }
  };

  searchGruppi = () => {
    if (this.state.nome.length > 0 || this.state.id.length > 0) {
      getGruppiFromBSFOODFiltered(0, 10, "id", this.state.id, this.state.nome)
        .then((result) => {
          this.setState({
            gruppi: result,
            loadedPage: 0,
          });
        })
        .catch((error) => {
          this.props.handleError(true, error);
        });
    } else {
      getCountGruppiAuditingFromBSFood()
        .then((result) => {
          this.setState(
            {
              tablePageNumber: Math.ceil(result / 10),
            },
            () => {
              this.getGruppiWithPage(this.state.loadedPage);
            }
          );
        })
        .catch((error) => {
          this.props.handleError(true, error);
        });
    }
  };

  getGruppiAfterLoading = () => {
    getGruppiFromBSFOOD(0, 10, "id")
      .then((result) => {
        if (this._isMounted) {
          this.setState({
            gruppi: result,
          });
        }
      })
      .catch((error) => {
        this.props.handleError(true, error);
      });
  };

  getColumns = () => {
    return [
      {
        Header: "ID Gruppo BS-FOOD",
        id: "id",
        accessor: (d) => d.id,
        filterable: true,
        Filter: (cellInfo) => (
          <TextField
            value={this.state.id}
            fullWidth
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                this.setState({
                  id: e.target.value,
                });
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchGruppi}>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Nome Gruppo BS-FOOD",
        id: "nome",
        accessor: (d) => d.nome,
        filterable: true,
        Filter: (cellInfo) => (
          <TextField
            fullWidth
            value={this.state.nome}
            onChange={(e) => {
              this.setState({
                nome: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={this.searchGruppi}>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
    ];
  };

  handlePageChange = (pageIndex) => {
    this.setState({
      loadedPage: pageIndex,
    });
  };

  passSelectionToParent = (selection) => {
    let gruppi = [...this.state.gruppi];
    let selectedElement = gruppi.find((gruppo) => gruppo.id === selection[0]);
    this.setState({
      selectedGroup: selection,
      selectedGroupData: selectedElement,
      isButtonDisabled:selection[0] === null || selection[0] === undefined ? true : false,
    });
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={() =>
            this.props.onClose()
        }
      >
        <div style={styles.modalcontainer}>
          <IconButton
            size="small"
            style={{ alignSelf: "flex-end", marginRight: "5px" }}
            onClick={() =>
                this.props.onClose()
            }
          >
            <CancelRounded style={{ color: "red" }}></CancelRounded>
          </IconButton>
          <div style={styles.modalsectioncontainer}>
            <SelectTable
              data={this.state.gruppi}
              selectType={"radio"}
              columns={this.state.columns}
              defaultPageSize={10}
              onPageChange={this.handlePageChange}
              page={this.state.loadedPage}
              passSelectionToParent={this.passSelectionToParent}
              pages={this.state.tablePageNumber}
              selectedElement={this.state.selectedGroup}
              showPageSizeOptions={false}
              style={{ minHeight: 500, minWidth: "70vw" }}
            ></SelectTable>
          </div>
          <div
                style={styles.buttoncontainer}
              >
               {/* <Button variant="contained" style={styles.buttonStyleGraphics} onClick={() => {
                  this.props.onClose();
                }}>Chiudi modal senza salvare</Button>
                 */}
                <Button
                  variant="contained"
                  onClick={() =>
              this.props.saveData(
                this.state.selectedGroup,
                this.state.selectedGroupData
              )}
                  disabled={this.state.isButtonDisabled}
                  style={styles.confirmButton(this.state.isButtonDisabled)}
                >
                  Conferma
                </Button>
              </div>
        </div>
      </Modal>
    );
  }
}

ModalSelezioneGruppoRapido.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleError: PropTypes.func,
};
