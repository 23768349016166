export const retrieveToken = () => {
  return localStorage.getItem("token");
};

export const saveToken = (token) => {
  localStorage.setItem("token", token);
};

export const clearToken = () => {
  localStorage.clear();
};

export const setIspettoreId = (ispettoreId) => {
  localStorage.setItem("ispettoreId", ispettoreId);
};

export const getIspettoreId = () => {
  return localStorage.getItem("ispettoreId");
};

export const setGruppoId = (gruppoId) => {
  localStorage.setItem("gruppoId", gruppoId);
};

export const getGruppoId = () => {
  return localStorage.getItem("gruppoId");
};

export const setSuperAuditor = (flag) => {
  localStorage.setItem("superAuditor", flag);
};

export const isSuperAuditor = () => {
  return localStorage.getItem("superAuditor");
};
