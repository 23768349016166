import { TextField } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionActions from "@material-ui/core/AccordionActions";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import { default as React } from "react";
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import theme from "../../theme";
import {
  getChecklists,
  registerChecklist,
  getPunti,
} from "../../utils/api/checklist_api";

const SelectTable = selectTableHOC(ReactTable);

const styles = {
  mainContainer: {
    flexgrow: 1,
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  center: {
    flex: 1,
    alignSelf: "center",
    whiteSpace: "unset",
    justifyContent: "center",
    textAlign: "center",
  },
  modalcontainer: {
    overflowY: "scroll",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "15%",
    marginRight: "15%",
    height: "80%",
  },
  paper: {
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
      theme.palette.primary.main,
  },
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfieldscontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  rowcontainer: {
    flexgrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  buttoncontainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
};

export default class SelectChecklist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      punti: [],
      record: {
        checklist: {
          id: null,
          nome: "",
        },
        checklistPunti: [
          {
            id: null,
            ambito: "",
            verifiche: [
              {
                id: null,
                descrizione: "",
              },
            ],
          },
        ],
      },

      validationErrors: {
        descrizione: "",
      },
      modalChecklist: true,
      loading: true,
      selected: false,
      records: [],
      modal: false,
      selectedIspettore: null,
    };
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null) {
      return row[id] !== undefined
        ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
        : true;
    }
  };

  toggleSelection = (key, shift, row) => {
    let selection = [...this.state.selection];

    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection = [];
      selection.push(row);
    }
    this.setState({ selection });
  };
  isSelected = (key) => {
    if (this.state.selection.some((e) => e.id === key)) {
      return true;
    }
    //  return this.state.selection.includes(key.id);
  };

  handleOpen = () => {
    this.setState({ modal: true });
    this.fetchRecords();
  };
  fetchRecords = () => {
    this.setState({
      loading: true,
    });
    getChecklists(null, 0, 1000, "id")
      .then((result) => {
        this.setState({
          records: result,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  addChecklist = () => {
    this.setState({
      loading: true,
    });
    registerChecklist(this.state.record)
      .then((result) => {
        this.setState(
          {
            loading: false,
            added: true,
          },
          () => this.fetchRecords()
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };
  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };
  handleClose = () => {
    let record = {
      checklist: {
        id: null,
        nome: "",
      },
      checklistPunti: [
        {
          id: null,
          ambito: "",
          verifiche: [
            {
              id: null,
              descrizione: "",
            },
          ],
        },
      ],
    };
    this.setState({ modal: false, manualeIdSelezionato: null, record });
  };

  updateParentState(data) {
    this.props.updateParentState(data[0]);
    this.setState({ modal: false, selected: true });
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  handleCheck = (data) => {
    this.setState({ selectedIspettore: data });
  };
  handleChangeAmbito = (event, index) => {
    let record = this.state.record;
    // let checklist = record.checklist
    let value = event.target.value;
    record.checklistPunti[index].ambito = value;
    this.setState({
      record,
    });
    this.validateAmbito();
  };
  handleChangePunto = (event, index, i) => {
    let record = this.state.record;

    let value = event.target.value;
    record.checklistPunti[index].verifiche[i].descrizione = value;
    this.setState({
      record,
    });
    this.validatePunto(index);
  };
  validatePunto = (index) => {
    let isValid = true;
    let record = this.state.record;
    // let checklist = record.checklist;
    let checklistPunti = record.checklistPunti;
    let verifiche = checklistPunti[index].verifiche;

    for (let i = 0; i < verifiche.length; i++) {
      if (
        verifiche[i].descrizione === null ||
        verifiche[i].descrizione === "" ||
        verifiche[i].descrizione.trim().length === 0
      ) {
        verifiche[i].descrizioneValidationError =
          "Inserire almeno un carattere";
        isValid = false;
      } else {
        verifiche[i].descrizioneValidationError = "";
      }
    }
    record.checklistPunti = checklistPunti;
    this.setState({
      record,
      disableButtonControlloCausa: !isValid,
    });
  };
  validateAmbito = () => {
    let isValid = true;
    let record = this.state.record;
    // let checklist = record.checklist;
    let checklistPunti = record.checklistPunti;
    for (let i = 0; i < checklistPunti.length; i++) {
      if (
        checklistPunti[i].ambito === null ||
        checklistPunti[i].ambito === "" ||
        checklistPunti[i].ambito.trim().length === 0
      ) {
        checklistPunti[i].descrizioneValidationError =
          "Inserire almeno un carattere";
        isValid = false;
      } else {
        checklistPunti[i].descrizioneValidationError = "";
      }
    }
    record.checklistPunti = checklistPunti;
    this.setState({
      record,
      disableButtonControlloCausa: !isValid,
    });
  };
  openPunti = (id) => {
    this.setState({
      loading: true,
    });
    getPunti(id, 0, 1000, "id")
      .then((result) => {
        this.setState({
          punti: result.checklistPunti,
          modalChecklist: false,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };
  getColumns = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        minWidth: 80,
        style: styles.center,
      },
      {
        Header: "Nome",
        id: "nome",
        accessor: (d) => d.nome,
        minWidth: 120,
        style: styles.center,
      },
      {
        Header: "Visualizza Punti",
        id: "punti",
        style: styles.center,
        accessor: (d) => {
          return (
            <Button
              type="button"
              onClick={() => this.openPunti(d.id)}
              variant="contained"
              size="medium"
              style={{
                color: theme.palette.secondary.main,
                margin: "10px",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              VISUALIZZA
            </Button>
          );
        },
        minWidth: 180,
      },
    ];
  };
  getColumnsReduced = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        minWidth: 80,
        style: styles.center,
      },
      {
        Header: "Nome",
        id: "nome",
        accessor: (d) => d.nome,
        minWidth: 120,
        style: styles.center,
      },
    ];
  };
  getColumnsPunti = () => {
    return [
      {
        Header: "Indice",
        id: "indice",
        accessor: (d) => d.id,
        minWidth: 80,
        style: styles.center,
        Cell: (row) => row.index + 1,
      },
      {
        Header: "Ambiti",
        id: "ambiti",
        accessor: (d) => d.ambito,
        minWidth: 80,
        style: styles.center,
      },
      {
        Header: "Verifiche",
        id: "verifiche",
        accessor: (d) =>
          d.verifiche.map((value, index) => {
            return (
              <div style={styles.sectioncontainer}>
                <p>{value.descrizione}</p>
              </div>
            );
          }),
        minWidth: 80,
        style: styles.center,
      },
    ];
  };
  addPunto = (index) => {
    let record = this.state.record;
    let checklistPunti = record.checklistPunti;
    let verifiche = checklistPunti[index].verifiche;

    const nuovoPunto = {
      id: null,
      descrizione: "",
    };
    verifiche.push(nuovoPunto);

    checklistPunti[index].verifiche = verifiche;
    record.checklistPunti = checklistPunti;

    this.setState({
      record,
    });
  };
  addAmbito = (event) => {
    let record = this.state.record;
    let checklistPunti = record.checklistPunti;
    const nuovoPunto = {
      ambito: "",
      verifiche: [
        {
          id: null,
          descrizione: "",
        },
      ],
    };
    checklistPunti.push(nuovoPunto);

    record.checklistPunti = checklistPunti;
    this.setState({
      record,
    });
  };
  render() {
    const columns = this.getColumns();
    const columnsPunti = this.getColumnsPunti();
    const recordsPunti = this.state.punti;
    const ColumnsReduced = this.getColumnsReduced();

    return (
      <div>
        <Modal
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {this.state.modalChecklist ? (
            <div style={styles.modalcontainer}>
              <div style={styles.modalsectioncontainer}>
                <Typography variant="body1">
                  GESTIONE DELLE CHECKLIST
                </Typography>
              </div>

              <Accordion
                expanded={this.state.expandedAccordion}
                onChange={this.onChangeAccordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="accordion-header"
                >
                  <Typography variant="body1">
                    AGGIUNGI NUOVA CHECKLIST
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        label="Nome checklist"
                        value={this.state.record.checklist.nome}
                        name="nome"
                        onChange={(e) => {
                          let record = this.state.record;
                          record.checklist.nome = e.target.value;
                          this.setState({ record });
                        }}
                        helperText={this.state.validationErrors.descrizione}
                        style={styles.textfield}
                      />
                    </Grid>
                    <Button
                      variant="contained"
                      size="small"
                      disabled={this.state.disableButtonAzioneCorrettiva}
                      style={{
                        color: this.state.disableButtonAzioneCorrettiva
                          ? "#e0e0e0"
                          : "#e17414",
                        margin: "10px",
                        backgroundColor: "white",
                      }}
                      onClick={(event) => this.addAmbito(event)}
                    >
                      Aggiungi ambito
                    </Button>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {this.state.record.checklistPunti.map((value, index) => (
                        <div style={styles.sectioncontainer}>
                          <div>
                            <TextField
                              label={"Ambito"}
                              value={value.ambito === null ? "" : value.ambito}
                              name="limite"
                              //variant="outlined"
                              style={styles.textfield}
                              onChange={(event) => {
                                this.handleChangeAmbito(event, index);
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              disabled={
                                this.state.disableButtonAzioneCorrettiva
                              }
                              style={{
                                color: this.state.disableButtonAzioneCorrettiva
                                  ? "#e0e0e0"
                                  : "#e17414",
                                margin: "10px",
                                backgroundColor: "white",
                              }}
                              onClick={(event) => this.addPunto(index)}
                            >
                              Aggiungi verifica
                            </Button>
                          </div>

                          {value.verifiche.map((value, i) => (
                            <TextField
                              label={"verifica"}
                              value={
                                value.descrizione === null
                                  ? ""
                                  : value.descrizione
                              }
                              name="limite"
                              //variant="outlined"
                              style={styles.textfield}
                              onChange={(event) => {
                                this.handleChangePunto(event, index, i);
                              }}
                            />
                          ))}
                        </div>
                      ))}
                      {this.state.added ? (
                        <p>checklist aggiunta con successo</p>
                      ) : null}
                    </Grid>
                  </Grid>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button
                    size="small"
                    onClick={this.addChecklist}
                    variant="contained"
                    disabled={false}
                    style={{
                      color: theme.palette.secondary.main,
                      margin: "10px",
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    Aggiungi
                  </Button>
                </AccordionActions>
              </Accordion>
              <div style={styles.modalsectioncontainer}>
                <Typography variant="body1">SELEZIONA CHECKLIST</Typography>
              </div>
              <SelectTable
                onFilteredChange={this.props.onFilteredChange}
                keyField={"id"}
                ref={(r) => (this.checkboxTable = r)}
                toggleSelection={this.toggleSelection}
                selectType="radio"
                isSelected={this.isSelected}
                filterable={true}
                resizable={true}
                showPageSizeOptions={true}
                showPageJump={true}
                defaultPageSize={5}
                data={this.state.records}
                columns={columns}
                previousText="Precedente"
                nextText="Successivo"
                noDataText="Nessun record"
                pageText="Pagina"
                ofText="di"
                rowsText="righe"
                pageJumpText="Vai a pagina"
                rowsSelectorText="righe per pagina"
                defaultFilterMethod={this.filterCaseInsensitive}
              />

              <Button
                type="button"
                onClick={() => this.updateParentState(this.state.selection)}
                variant="contained"
                size="medium"
                //  disabled={disabledSubmitbutton}
                style={{
                  color: theme.palette.secondary.main,
                  margin: "10px",
                  backgroundColor: theme.palette.primary.main,
                }}
              >
                CONFERMA
              </Button>
            </div>
          ) : (
            <div style={styles.modalcontainer}>
              <div style={styles.modalsectioncontainer}>
                <Typography variant="body1">PUNTI CHECKLIST</Typography>

                <ReactTable
                  filterable={true}
                  resizable={true}
                  showPageSizeOptions={true}
                  showPageJump={true}
                  defaultPageSize={5}
                  data={recordsPunti}
                  columns={columnsPunti}
                  //manual // informs React Table that you'll be handling sorting and pagination server-side
                  //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                  previousText="Precedente"
                  nextText="Successivo"
                  noDataText="Nessun record"
                  pageText="Pagina"
                  ofText="di"
                  rowsText="righe"
                  pageJumpText="Vai a pagina"
                  rowsSelectorText="righe per pagina"
                />
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    color: this.state.disableButtonAzioneCorrettiva
                      ? "#e0e0e0"
                      : "#e17414",

                    margin: "10px",
                    backgroundColor: "white",
                  }}
                  onClick={() => this.setState({ modalChecklist: true })}
                >
                  indietro
                </Button>
              </div>
            </div>
          )}
        </Modal>

        <Button
          type="button"
          onClick={() => this.handleOpen()}
          variant="contained"
          size="medium"
          style={{
            color: theme.palette.secondary.main,
            margin: "10px",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          SELEZIONA CHECKLIST
        </Button>
        {this.state.selected ? (
          <ReactTable
            filterable={false}
            resizable={false}
            showPageSizeOptions={true}
            showPageJump={true}
            defaultPageSize={1}
            maxRow={1}
            //pages={this.state.pages}
            data={this.state.selection}
            columns={ColumnsReduced}
            //manual // informs React Table that you'll be handling sorting and pagination server-side
            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
            showPagination={false}
            showPaginationTop={false}
            showPaginationBottom={false}
          />
        ) : null}
      </div>
    );
  }
}

SelectChecklist.propTypes = {
  updateParentState: PropTypes.func.isRequired,
};
