import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { Fullscreen } from "@material-ui/icons";
import React, { Component } from "react";
import { Document, Page } from "react-pdf";
import "../../../App.css"

class IspezioneTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOpen: false,
      isDocumentFull: false,
      renderValue: 0.7,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    const { innerWidth } = window;
    let newRenderValue = innerWidth > 1920 ? 1 : 0.7;
    this.setState({
      renderValue: newRenderValue,
    });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    const { updateParentState } = this.props;
    if (updateParentState) {
      updateParentState({
        numPages: numPages,
        isDocumentFull: true,
      });
    }
  };
  goToPreviousPage = () => {
    this.setState((prevState) => ({
      pageNumber: Math.max(prevState.pageNumber - 1, 1),
    }));
  };

  goToNextPage = () => {
    this.setState((prevState) => ({
      pageNumber: Math.min(prevState.pageNumber + 1, prevState.numPages),
    }));
  };
  render() {
    const styles = {
      previousPageButton: {
        borderWidth: 0,
        marginRight: 5,
        borderRadius: 20,
        color: "white",
        background:
          this.props.pageNumber <= 1
            ? "#ccc"
            : "linear-gradient(to right, #0d5fab, #00b1b7,#0d5fab)",
        cursor: this.props.pageNumber <= 1 ? "not-allowed" : "pointer",
        boxShadow:
          this.props.pageNumber <= 1
            ? "none"
            : "0px 4px 6px rgba(0, 0, 0, 0.1)",
      },
      nextPageButton: {
        borderWidth: 0,
        marginRight: 5,
        borderRadius: 20,
        color: "white",
        background:
          this.props.pageNumber >= this.props.numPages
            ? "#ccc"
            : "linear-gradient(to right, #00b1b7, #0d5fab, #00b1b7)",
        cursor:
          this.props.pageNumber >= this.props.numPages
            ? "not-allowed"
            : "pointer",
        boxShadow:
          this.props.pageNumber >= this.props.numPages
            ? "none"
            : "0px 4px 6px rgba(0, 0, 0, 0.1)",
      },

    };

    return (
      <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignSelf={"center"}
      minHeight={"30vh"}
      height={"100%"}
      paddingX={10}
      paddingBottom={2}
    >
      {this.props.loading ? (
        <Box alignSelf={"center"}>
          <CircularProgress />
          <Typography variant="h4">Caricamento PDF...</Typography>
        </Box>
      ) : this.props.pdfResponse ? (
        <Document file={this.props.pdfResponse} onLoadSuccess={this.onDocumentLoadSuccess}>
          <Box marginY={2} display={"flex"} alignSelf={"flex-start"}>
            <Button
              onClick={() => window.open(this.props.pdfResponse)}
              startIcon={<Fullscreen />}
            >
              FullScreen
            </Button>
          </Box>
          <Box height={"100%"} boxShadow={5} marginBottom={2}>
            <Page
              scale={this.state.renderValue}
              loading={
                <Box>
                  <CircularProgress />
                </Box>
              }
              pageNumber={this.props.pageNumber}
            />
          </Box>
          <Button
            onClick={this.props.goToPreviousPage}
            disabled={this.props.numPages <= 1}
            variant="outlined"
            style={styles.previousPageButton}
          >
            Precedente
          </Button>
          <Button
            onClick={this.props.goToNextPage}
            disabled={this.props.pageNumber >= this.props.numPages}
            variant="outlined"
            style={styles.nextPageButton}
          >
            Successivo
          </Button>
        </Document>
      ) : (
        <Box>
          <Typography> { this.props.errorLoading ? "Errore caricamento PDF"  : "Nessun PDF Selezionato " } </Typography>
        </Box>
      )}
    </Box>
    );
  }
}
export default IspezioneTab;
