import { retrieveToken } from "../storage";
import { wrapFetch, ApiError } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getEserciziCommerciali = (
  ispettoreId,
  page,
  size,
  sortby,
  gruppoId,
  id,
  name,
  ragioneSociale,
  provinciaSede,
  cittaSede,
  indirizzoSede
) => {
  const token = retrieveToken();
  const PATH = "/api/esercizi_commerciali";
  let query = `?ispettoreId=${ispettoreId}&page=${page}&size=${size}&sort=${sortby}&disabled.equals=${false}&${sortby}.dir=ASC`;

  if (gruppoId !== null && gruppoId !== -1) {
    query += `&gruppoId.equals=${gruppoId}`;
  }
  if (id) {
    query += `&id.equals=${encodeURIComponent(id)}`;
  }
  if (name) {
    query += `&nome.contains=${encodeURIComponent(name)}`;
  }
  if (ragioneSociale) {
    query += `&ragioneSociale.contains=${encodeURIComponent(ragioneSociale)}`;
  }
  if (provinciaSede) {
    query += `&provinciaSede.contains=${encodeURIComponent(provinciaSede)}`;
  }
  if (cittaSede) {
    query += `&cittaSede.contains=${encodeURIComponent(cittaSede)}`;
  }
  if (indirizzoSede) {
    query += `&indirizzoSede.contains=${encodeURIComponent(indirizzoSede)}`;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
export const getEserciziCommercialiForGruppiPersonalizzati = (
  gruppiId,
) => {
  const token = retrieveToken();
  const PATH = "/api/esercizi_commerciali/get/esercizi_da_gruppo?";
  const query = `idGruppoBsfood=${gruppiId}`
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};



export const getEserciziCommercialiCount = (gruppoId) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = `/api/esercizi_commerciali/count`;
  let query = "";
  if (gruppoId) {
    query = `?gruppoId.equals=${gruppoId}`;
  }

  const URL = BASE_URL + PATH + query;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      credentials: "include",
    }),
    errorFunction
  );
};

export const getEserciziBSFoodCount = (gruppoId) => {
  const token = retrieveToken();
  const PATH =
    "/api/bsfood/esercizi_commerciali/count?gruppoId.equals=" + gruppoId;
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getEserciziCommercialiTestBSFood = (
  gruppoId,
  page,
  size,
  sortby
) => {
  const token = retrieveToken();
  const PATH = "/api/bsfood/esercizi_commerciali";
  let query =
    "?" +
    "page=" +
    page +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC";

  if (gruppoId !== undefined || gruppoId !== null) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getEsercizioCommerciale = (
  esercizioId,
  page,
  size,
  sortby,
  gruppoId
) => {
  const token = retrieveToken();
  const PATH = "/api/esercizi_commerciali";
  let query = `?page=${page}size=${size}&sort=${sortby}&${sortby}.dir=ASC`;

  if (esercizioId !== null) {
    query = query + "&id.equals=" + esercizioId;
  }

  if (gruppoId !== null) {
    query += `&gruppoId.equals=${gruppoId}`;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const syncEserciziCommerciali = () => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/sync/esercizio_commerciale";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message = "Errore durante la sincronizzazione dei dati";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getSyncEserciziCommerciali = () => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = "/api/sinc_esercizi/latest";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getEserciziCommercialiFiltered = (
  gruppoId,
  page,
  size,
  sortby,
  nome,
  ragione,
  provincia,
  citta,
  indirizzo,
  id
) => {
  const token = retrieveToken();
  const PATH = "/api/bsfood/esercizi_commerciali";
  let query = `?page=${page}&size=${size}&sort=${sortby}&${sortby}.dir=ASC`;

  if (gruppoId !== null && gruppoId !== -1) {
    // NB: -1 is a special value used for "all records" in dropdown menu.
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  if (ragione !== null && ragione !== "") {
    query = query + "&ragioneSociale.contains=" + ragione;
  }
  if (nome !== null && nome !== "") {
    query = query + "&nome.contains=" + nome;
  }
  if (provincia !== null && provincia !== "") {
    query = query + "&provincia.contains=" + provincia;
  }
  if (citta !== null && citta !== "") {
    query = query + "&citta.contains=" + citta;
  }
  if (indirizzo !== null && indirizzo !== "") {
    query = query + "&indirizzo.contains=" + indirizzo;
  }
  if (id !== null && id !== "") {
    query = query + "&id.equals=" + id;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getEserciziCommercialiDaGruppi = (
  gruppiBSFoodIds,
  page,
  size,
  sortby,
  id,
  nome,
  ragionaSociale,
  indirizzo,
  citta,
  provincia
) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = "/api/bsfood/esercizi_commerciali";
  let query = `?page=${page}&size=${size}&sort=${sortby}&${sortby}.dir=ASC&gruppiIds.in=${gruppiBSFoodIds}`;

  if (id) query += `&id.equals=${id}`;
  if (nome) query += `&nome.contains=${nome}`;
  if (ragionaSociale) query += `&ragioneSociale.contains=${ragionaSociale}`;
  if (indirizzo) query += `&indirizzo.contains=${indirizzo}`;
  if (citta) query += `&citta.contains=${citta}`;
  if (provincia) query += `&provincia.contains=${provincia}`;

  const URL = BASE_URL + PATH + query;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getEserciziCommercialiDaGruppiCount = (gruppiBSFoodIds) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = `/api/bsfood/esercizi_commerciali/count?gruppiIds.in=${gruppiBSFoodIds}`;
  const URL = BASE_URL + PATH;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
