import { retrieveToken } from "../storage";
import { wrapFetch, ApiError } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getZone = (id, nome, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = "/api/zone";
  let query =
    "?" +
    "page=" +
    page +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC";
  if (id !== null) {
    query = query + "&id.equals=" + id;
  }
  if (nome !== null) {
    query = query + "&nome.contains=" + nome;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const registerZona = (nome) => {
  const token = retrieveToken();
  const PATH = "/api/zone";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const requestBody = {
    nome: nome,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      //404 if the gruppoId does not correspond to a registered Gruppo
      message = "Registrazione non riuscita: dati non corretti.";
    } else if (status === 409) {
      message =
        "Registrazione non riuscita: esiste già una zona registrata con il nome indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const registerGruppoRapido = (gruppoRapido) => {
  const token = retrieveToken();
  const PATH = "/api/gruppi/gruppo_rapido";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Registrazione non riuscita: dati non corretti.";
    } else if (status === 409) {
      message =
        "Registrazione non riuscita: esiste già un gruppo registrato con il nome indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(gruppoRapido),
    }),
    errorFunction
  );
};

export const registerGruppoPersonalizzato = (gruppoPersonalizzato) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = "/api/gruppi/gruppo_personalizzato";
  const URL = BASE_URL + PATH;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Registrazione non riuscita: dati non corretti.";
    } else if (status === 409) {
      message =
        "Registrazione non riuscita: esiste già un gruppo registrato con il nome indicato.";
    }
    return new ApiError(status, message);
  };

  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(gruppoPersonalizzato),
    }),
    errorFunction
  );
};
