import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { ReactComponent as Search } from "../../../search.svg";
import theme from "../../../theme";
import IndietroButton from "../../components/IndietroButton";
import ErrorDialog from "../../components/ErrorDialog";
import { clearToken } from "../../../utils/storage";
import { List } from "@material-ui/icons";
import Background from "../../../background.png";
import ModalEserciziGruppo from "./ModalEserciziGruppo";
import ModalSelezioneGruppoRapido from "./ModalSelezioneGruppoRapido";
import {
  registerGruppoPersonalizzato,
  registerGruppoRapido,
} from "../../../utils/api/zone_api";
import ModalSelezioneGruppoCustom from "./ModalSelezioneGruppoCustom";
import ModalVisualizzazioneEserciziPersonalizzato from "./ModalVisualizzazioneEserciziPersonalizzato";
import ModalSelezioneEserciziGruppoPersonalizzato from "./ModalSelezioneEserciziGruppoPersonalizzato";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import ModalVisualizzaGruppiBSFood from "./ModalVisualizzaGruppiBSFood";
import PropTypes from "prop-types";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px",
  },
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "20px",
    marginTop: "30px",
    marginBottom: "30px",
    paddingTop: "30px",
    paddingBottom: "30px",
  },
};

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.auditPalette.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.auditPalette.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.auditPalette.primary,
      },
    },
  },
})(TextField);

class NuovoGruppo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gruppi: [],
      selectedGroup: null,
      selectedGroupData: null,
      selectedGroupForVisualization: null,
      selectedGroupsForPersonalizzato: [],
      selectedEserciziForPersonalizzato: [],
      nomeGruppoNuovo: "",
      modalSelezioneGruppoRapido: false,
      modalSelezioneGruppoCustom: false,
      modalVisualizzazioneListaEsercizi: false,
      modalSelezioneEserciziPersonalizzato: false,
      isModalVisualizzaGruppiBSFoodOpen: false,
      height: window.innerHeight,
      width: window.innerWidth,
      modalListaEsercizi: false,
      errorDialogVisible: false,
      registerSuccess: false,
      errorDialogMessage: "",
      errorDialogTitle: "Errore",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    // if (this.props.tipoGruppo === "rapido") {
    //    console.log("Welcome to rapido section");
    //   //Here i will insert syncing data logic
    // } else {
    //   //Here i will insert only a simple GET logic
    //   console.log("Welcome to personalizzato section");
    // }
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  handleModalEserciziOpen = () => {
    if (
      this.state.selectedGroup !== null &&
      this.state.selectedGroup !== undefined && this.state.selectedGroup[0] !== null && this.state.selectedGroup[0] !== undefined
    ) {
      this.setState({
        modalListaEsercizi: true,
      });
    } else {
      this.handleError(
        true,
        "Attenzione, occorre selezionare un gruppo per poter visualizzare la lista degli esercizi"
      );
    }
  };

  handleModalEserciziClose = () => {
    this.setState({
      modalListaEsercizi: false,
    });
  };

  handleModalSelezioneGruppoRapidoOpen = () => {
    this.setState({
      modalSelezioneGruppoRapido: true,
    });
  };

  handleModalSelezioneGruppoCustomOpen = () => {
    this.setState({
      modalSelezioneGruppoCustom: true,
    });
  };

  handleModalVisualizzaGruppiBSFoodOpen = () => {
    this.setState({
      isModalVisualizzaGruppiBSFoodOpen: true,
    });
  };

  handleModalSelezioneGruppoRapidoClose = () => {
    this.setState({
      modalSelezioneGruppoRapido: false,
    });
  };
  handleModalSelezioneGruppoRapidoCloseWithSave = (selection, data) => {
    this.setState({
      modalSelezioneGruppoRapido: false,
      selectedGroup: selection !== undefined ? selection : null,
      selectedGroupData: data !== undefined ? data : null,
    });
  }


  handleModalSelezioneGruppoCustomClose = (selection) => {
    this.setState({
      modalSelezioneGruppoCustom: false,
      selectedGroup: selection,
    });
  };

  handleModalVisualizzazioneEserciziPersonalizzatoClose = () => {
    this.setState({
      selectedGroupForVisualization: null,
      modalSelezioneGruppoCustom: true,
      modalVisualizzazioneListaEsercizi: false,
    });
  };

  handleModalVisualizzaGruppiBSFoodClose = () => {
    this.setState({
      isModalVisualizzaGruppiBSFoodOpen: false,
    });
  };

  handleModalSelezioneEserciziOpen = () => {
    if (this.state.selectedGroup === null) {
      this.handleError(
        true,
        "Attenzione, occorre scegliere almeno un gruppo per poterne associare gli esercizi"
      );
    } else {
      this.setState({
        modalSelezioneEserciziPersonalizzato: true,
      });
    }
  };

  handleModalSelezioneEserciziClose = (selection) => {
    this.setState({
      modalSelezioneEserciziPersonalizzato: false,
    });
  };

  saveNewGroup = () => {
    if (this.props.tipoGruppo === "rapido") {
      if (!this.state.selectedGroup) {
        this.handleError(
          true,
          "Attenzione, occorre scegliere un gruppo da BS-FOOD"
        );
      } 
      else if(this.state.selectedGroup && (this.state.selectedGroup[0] === null || this.state.selectedGroup[0] === undefined)){
        this.handleError(
          true,
          "Attenzione, occorre scegliere un gruppo da BS-FOOD"
        );
      }
      else if (!this.state.nomeGruppoNuovo) {
        this.handleError(
          true,
          "Attenzione, occorre inserire un nome per il nuovo gruppo"
        );
      } else {
        let gruppoRapidoToSave = {
          idFood: [this.state.selectedGroup[0]],
          nome: this.state.nomeGruppoNuovo,
        };

        registerGruppoRapido(gruppoRapidoToSave)
          .then((_response) => {
            this.setState(
              {
                registerSuccess: true,
              },
              () => {
                const { history } = this.props;
                history.push("/amministrazione/gruppi_di_auditing");
              }
            );
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          });
      }
    } else {
      if (this.state.selectedGroupsForPersonalizzato.length === 0) {
        this.handleError(
          true,
          "Attenzione, occorre scegliere almeno un gruppo da BS-FOOD"
        );
      } else if (this.state.nomeGruppoNuovo === "") {
        this.handleError(
          true,
          "Attenzione, occorre inserire un nome per il nuovo gruppo"
        );
      } else {
        const gruppoPersonalizzatoToSave = {
          idFood: this.state.selectedGroupsForPersonalizzato,
          nome: this.state.nomeGruppoNuovo,
          eserciziIds: this.state.selectedEserciziForPersonalizzato,
        };

        registerGruppoPersonalizzato(gruppoPersonalizzatoToSave)
          .then((_response) => {
            this.setState(
              {
                registerSuccess: true,
              },
              () => {
                const { history } = this.props;
                history.push("/amministrazione/gruppi_di_auditing");
              }
            );
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          });
      }
    }
  };

  handleSelectedGroupsChange = (selectedGroupsForPersonalizzato) => {
    // console.log("Selected groups: ", selectedGroupsForPersonalizzato);
    this.setState({ selectedGroupsForPersonalizzato });
  };

  handleSelectedEserciziForPersonalizzato = (
    selectedEserciziForPersonalizzato
  ) => {
    // console.log("Selected esercizi: ", selectedEserciziForPersonalizzato);
    this.setState({ selectedEserciziForPersonalizzato });
  };

  render() {
    const showSelectedGruppiBSFood =
      this.state.selectedGroupsForPersonalizzato.length > 0;

    return (
      <div
        style={{
          textAlign: "center",
          color: "black",
          padding: "26px",
          minHeight: "100vh",
          backgroundImage:
            this.state.width > 1160
              ? `url("${Background}"),url("${Background}")`
              : `url("")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "",
          backgroundAttachment: "fixed",
          backgroundPositionY: "50%",
          backgroundPositionX:
            this.state.width > 1899
              ? "130%, -30%"
              : this.state.width > 1799
              ? "140%, -40%"
              : this.state.width > 1599
              ? "150%, -50%"
              : this.state.width > 1399
              ? "170%, -70%"
              : this.state.width > 1299
              ? "190%, -90%"
              : this.state.width > 1250
              ? "210%, -110%"
              : this.state.width > 1200
              ? "230%, -130%"
              : "250%, -150%",
        }}
      >
        {this.props.tipoGruppo === "rapido" ? (
          <>
            <Typography variant="h4">{"Gruppo di auditing rapido"}</Typography>
            {this.state.registerSuccess ? (
              <Typography style={{ color: "green" }} variant="h6">
                {"Gruppo di auditing rapido creato con successo!"}
              </Typography>
            ) : null}
            <Container
              id="form_container"
              style={{ maxWidth: "700px", minHeight: "500px" }}
            >
              <Grid container justify="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={styles.sectioncontainer}
                >
                  <Typography
                    variant="h6"
                    style={{ paddingTop: "6px", paddingBottom: "6px" }}
                  >
                    Seleziona un gruppo BS-FOOD
                  </Typography>
                  <Grid container style={styles.userdatacontainer}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px", paddingTop: 10 }}
                    >
                      <CssTextField
                        variant="outlined"
                        value={
                          this.state.selectedGroupData === null ||
                          this.state.selectedGroupData.nome === undefined ||
                          this.state.selectedGroupData.nome === null
                            ? "SELEZIONA UN GRUPPO"
                            : this.state.selectedGroupData.nome
                        }
                        InputProps={{
                          readOnly: true,
                          style: {
                            borderRadius: 50,
                            borderColor: theme.palette.auditPalette.primary,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                onClick={
                                  this.handleModalSelezioneGruppoRapidoOpen
                                }
                              >
                                <List
                                  style={{ color: "#00b1b7" }}
                                  width={35}
                                  height={35}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        style={{ width: "50%" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={styles.sectioncontainer}
                >
                  <Typography
                    variant="h6"
                    style={{ paddingTop: "6px", paddingBottom: "6px" }}
                  >
                    Inserisci il nome del nuovo gruppo di auditing rapido
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      paddingTop: 10,
                      paddingBottom: 30,
                    }}
                  >
                    <TextField
                      value={this.state.nomeGruppoNuovo}
                      label={"Nome"}
                      style={{
                        width: "75%",
                      }}
                      onInput={(e) => {
                        this.setState({
                          nomeGruppoNuovo: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      onClick={this.handleModalEserciziOpen}
                      size="medium"
                      endIcon={
                        <Search width={35} height={35} fill="#FFFFFF"></Search>
                      }
                      style={{
                        color: theme.palette.secondary.main,
                        margin: "10px",
                        borderRadius: 50,
                        backgroundColor: theme.palette.auditPalette.secondary,
                      }}
                    >
                      VISUALIZZA LISTA ESERCIZI
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <IndietroButton
                  style={{
                    background:
                      "linear-gradient(to right,#0d5fab,#00b1b7,#0d5fab)",
                    color: "white",
                    borderRadius: 30,
                    marginRight: 20,
                  }}
                >
                  Indietro
                </IndietroButton>
                <Button
                  variant="contained"
                  onClick={this.saveNewGroup}
                  disabled={this.state.registerSuccess}
                  style={{
                    background: this.state.registerSuccess
                      ? ""
                      : "linear-gradient(to right,#0d5fab,#00b1b7,#0d5fab)",
                    color: "white",
                    borderRadius: 30,
                  }}
                >
                  Registra
                </Button>
              </div>
            </Container>
          </>
        ) : (
          <>
            <Typography variant="h4">
              Gruppo di auditing personalizzato
            </Typography>
            {this.state.registerSuccess ? (
              <Typography style={{ color: "green" }} variant="h6">
                Gruppo di auditing personalizzato creato con successo!
              </Typography>
            ) : null}
            <Container
              id="form_container"
              style={{
                paddingLeft: "4px",
                paddingRight: "4px",
                maxWidth: "700px",
                minHeight: "500px",
              }}
            >
              <Grid container justify="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={styles.sectioncontainer}
                >
                  <Typography
                    variant="h6"
                    style={{ paddingTop: "6px", paddingBottom: "6px" }}
                  >
                    Seleziona uno o più gruppi BS-FOOD
                  </Typography>

                  <Grid container style={styles.userdatacontainer}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px", paddingTop: 10 }}
                    >
                      {showSelectedGruppiBSFood ? (
                        <CssTextField
                          variant="outlined"
                          value="Visualizza gruppi selezionati"
                          InputProps={{
                            readOnly: true,
                            style: {
                              borderRadius: 50,
                              borderColor: theme.palette.auditPalette.primary,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  onClick={
                                    this.handleModalVisualizzaGruppiBSFoodOpen
                                  }
                                >
                                  <List
                                    style={{ color: "#00b1b7" }}
                                    width={35}
                                    height={35}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          style={{ width: "50%" }}
                        />
                      ) : (
                        <CssTextField
                          variant="outlined"
                          value={"SELEZIONA UNO O PIU GRUPPI"}
                          InputProps={{
                            readOnly: true,
                            style: {
                              borderRadius: 50,
                              borderColor: theme.palette.auditPalette.primary,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  onClick={
                                    this.handleModalSelezioneGruppoCustomOpen
                                  }
                                >
                                  <List
                                    style={{ color: "#00b1b7" }}
                                    width={35}
                                    height={35}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          style={{ width: "50%" }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={styles.sectioncontainer}
                >
                  <Typography
                    variant="h6"
                    style={{ paddingTop: "6px", paddingBottom: "6px" }}
                  >
                    Inserisci il nome del nuovo gruppo di auditing
                    personalizzato
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      paddingTop: 10,
                      paddingBottom: 30,
                    }}
                  >
                    <TextField
                      value={this.state.nomeGruppoNuovo}
                      label={"Nome"}
                      style={{
                        width: "75%",
                      }}
                      onInput={(e) => {
                        this.setState({
                          nomeGruppoNuovo: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      onClick={this.handleModalSelezioneEserciziOpen}
                      size="medium"
                      endIcon={<Search width={35} height={35} fill="#FFFFFF" />}
                      style={{
                        color: theme.palette.secondary.main,
                        margin: "10px",
                        borderRadius: 50,
                        backgroundColor: theme.palette.auditPalette.secondary,
                      }}
                    >
                      MODIFICA LISTA ESERCIZI
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <IndietroButton
                  variant="contained"
                  style={{
                    background:
                      "linear-gradient(to right,#0d5fab,#00b1b7,#0d5fab)",
                    color: "white",
                    borderRadius: 30,
                    marginRight: 20,
                  }}
                  isDisabled={false}
                >
                  Indietro
                </IndietroButton>
                <Button
                  onClick={this.saveNewGroup}
                  disabled={this.state.registerSuccess}
                  variant="contained"
                  style={{
                    background: this.state.registerSuccess
                      ? ""
                      : "linear-gradient(to right,#0d5fab,#00b1b7,#0d5fab)",
                    color: "white",
                    borderRadius: 30,
                  }}
                >
                  Registra
                </Button>
              </div>
            </Container>
          </>
        )}
        {this.state.modalListaEsercizi && (
          <ModalEserciziGruppo
            selectedGruppo={
              this.state.selectedGroup === null
                ? null
                : this.state.selectedGroup[0]
            }
            open={this.state.modalListaEsercizi}
            onClose={this.handleModalEserciziClose}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
          />
        )}
        {this.state.modalSelezioneEserciziPersonalizzato && (
          <ModalSelezioneEserciziGruppoPersonalizzato
            selectedEserciziForPersonalizzato={
              this.state.selectedEserciziForPersonalizzato
            }
            onSelectedEserciziChange={
              this.handleSelectedEserciziForPersonalizzato
            }
            selectedGroups={this.state.selectedGroupsForPersonalizzato}
            open={this.state.modalSelezioneEserciziPersonalizzato}
            onClose={this.handleModalSelezioneEserciziClose}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
          />
        )}
        {this.state.modalVisualizzazioneListaEsercizi && (
          <ModalVisualizzazioneEserciziPersonalizzato
            selectedGruppo={
              this.state.selectedGroupForVisualization === null
                ? null
                : this.state.selectedGroupForVisualization
            }
            open={this.state.modalVisualizzazioneListaEsercizi}
            onClose={this.handleModalVisualizzazioneEserciziPersonalizzatoClose}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
          />
        )}
        {this.state.modalSelezioneGruppoRapido && <ModalSelezioneGruppoRapido
          open={this.state.modalSelezioneGruppoRapido}
          onClose={this.handleModalSelezioneGruppoRapidoClose}
          saveData={this.handleModalSelezioneGruppoRapidoCloseWithSave}
          handleError={this.handleError}
          selectedGroup={this.state.selectedGroup}
          selectedGroupData={this.state.selectedGroupData}
          handleInvalidToken={this.handleInvalidToken}
        />}
        <ModalSelezioneGruppoCustom
          open={this.state.modalSelezioneGruppoCustom}
          onClose={this.handleModalSelezioneGruppoCustomClose}
          onSelectedGroupsChange={this.handleSelectedGroupsChange}
          selectedGroups={this.state.selectedGroupsForPersonalizzato}
          handleError={this.handleError}
          handleInvalidToken={this.handleInvalidToken}
        />
        {this.state.isModalVisualizzaGruppiBSFoodOpen && (
          <ModalVisualizzaGruppiBSFood
            open={this.state.isModalVisualizzaGruppiBSFoodOpen}
            onClose={this.handleModalVisualizzaGruppiBSFoodClose}
            selectedGroups={this.state.selectedGroupsForPersonalizzato}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
          />
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}

NuovoGruppo.propTypes = {
  tipoGruppo: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(NuovoGruppo);
