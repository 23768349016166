import React, { useState, useEffect, useRef } from "react";
import {
  useHistory,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
// import { getEserciziCommerciali } from "../../../utils/api/esercizi_commerciali_api";
import { getIspezioneRevisioni } from "../../../utils/api/ispezione_api";
import {
  Box,
  Typography,
  MenuItem,
  InputBase,
  Select,
  Button,
  withStyles,
  FormHelperText,
  FormControl,
  TextField,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";
import moment from "moment";
import SpinnerComponent from "../../components/SpinnerComponent";
import IndietroButton from "../../components/IndietroButton";
import DatePickerComponent from "../../components/DatePickerComponent";
import ErrorDialog from "../../components/ErrorDialog";
import { clearToken } from "../../../utils/storage";

const flexColumn = {
  display: "flex",
  flexDirection: "column",
};

const buttonStyle = (background) => ({
  width: "152px",
  borderRadius: "20px",
  paddingLeft: "40px",
  paddingRight: "40px",
  color: "white",
  background: `linear-gradient(to right,${background})`,
});

const styles = {
  container: {
    paddingTop: "30px",
    marginTop: "20px",
    minHeight: "400px",
  },
  wrapper: {
    ...flexColumn,
    gap: "60px",
  },
  form: {
    ...flexColumn,
    alignItems: "center",
    gap: "60px",
  },
  datiIspezioneBox: {
    ...flexColumn,
    width: "685px",
    gap: "30px",
    alignItems: "center",
    padding: "30px",
    border: "1px solid rgb(180, 180, 180)",
    borderRadius: "20px",
  },
  textFieldWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "60px",
  },
  textField: {
    borderBottom: "1px solid rgb(180, 180, 180)",
  },
  datePickerWrapper: flexColumn,
  buttonWrapper: {
    display: "flex",
    gap: "20px",
    marginBottom: "120px",
  },
  indietroButton: buttonStyle("#00b1b7,#0d5fab,#00b1b7"),
  cercaButton: buttonStyle("#0d5fab,#00b1b7,#0d5fab"),
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  background:
    "linear-gradient(white, white) padding-box, linear-gradient(to right, #0d5fab, #00b1b7,#0d5fab) border-box;",
  borderRadius: "50em",
  border: "1px solid transparent",
  padding: "5px",
}));

const ITEM_HEIGHT = "48px";
const ITEM_PADDING_TOP = "8px";
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "250px",
    },
  },
};

const FormRicercaIspezione = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const isMounted = useRef(true);

  const [revisioniList, setRevisioniList] = useState([]);
  const [formData, setFormData] = useState({
    esercizioSelected: location.state.id,
    revisioneSelected: "",
    dataInizio: null,
    dataFine: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ hasError: false, message: "" });

  const dataInizioDate = formData.dataInizio && new Date(formData.dataInizio);
  const dataFineDate = formData.dataFine && new Date(formData.dataFine);

  const indiceRevisioneSelezionata = revisioniList.findIndex(
    (rev) => rev.id === formData.revisioneSelected
  );


  const dataAggiornamentoCorrente =
    indiceRevisioneSelezionata >= 0
      ? new Date(
          revisioniList[
            indiceRevisioneSelezionata
          ].dataRevisione
        )
      : null;

  const dataAggiornamentoSuccessiva =
    indiceRevisioneSelezionata >= 0 &&
    indiceRevisioneSelezionata < revisioniList.length - 1
      ? new Date(
          revisioniList[
            indiceRevisioneSelezionata + 1
          ].dataRevisione
        )
      : new Date(2100, 0, 1);

  const minDateEffettiva =
    dataInizioDate &&
    (!dataAggiornamentoCorrente || dataInizioDate > dataAggiornamentoCorrente)
      ? dataInizioDate
      : dataAggiornamentoCorrente;

  const maxDateEffettiva =
    dataFineDate &&
    (!dataAggiornamentoSuccessiva || dataFineDate < dataAggiornamentoSuccessiva)
      ? dataFineDate
      : dataAggiornamentoSuccessiva;

  const minDateInizio = dataFineDate
    ? dataAggiornamentoCorrente
    : minDateEffettiva;
  const maxDateInizio = maxDateEffettiva;
  const minDateFine = minDateEffettiva;
  const maxDateFine = dataInizioDate
    ? dataAggiornamentoSuccessiva
    : maxDateEffettiva;

  const validateForm = () => {
    const errors = {};

    if (!formData.revisioneSelected) {
      errors.revisioneSelected = "Inserisci il numero di revisione";
    }
    if (!formData.dataInizio) {
      errors.dataInizio = "Inserisci la data di inizio";
    }
    if (!formData.dataFine) {
      errors.dataFine = "Inserisci la data di fine";
    }

    return errors;
  };

  const onRevisioneChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      revisioneSelected: event.target.value,
      dataInizio: null,
      dataFine: null,
    }));
  };

  const onDateChange = (event, nameDate) => {
    setFormData((prev) => ({
      ...prev,
      [nameDate]: event,
    }));
  };

  const onHandleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      history.push({
        pathname: `${match.url}/${formData.revisioneSelected}`,
        state: formData,
      });
      setFormErrors({});
    } else {
      setFormErrors(errors);
    }
  };

  const handleInvalidToken = () => {
    setError({
      hasError: true,
      message:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  const handleError = (error, errorMessage) => {
    setError({ hasError: error, message: errorMessage });
  };

  const handleCloseErrorDialog = () => {
    setError({ hasError: false, message: "" });
  };

  useEffect(() => {
    setLoading(true);
    getIspezioneRevisioni(formData.esercizioSelected)
      .then((data) => {
        if (isMounted.current) {
          const sortedData = data.sort((rev1, rev2) =>
            rev1.dataRevisione >
            rev2.dataRevisione
              ? 1
              : -1
          );
          setRevisioniList(sortedData);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          handleInvalidToken();
        } else {
          handleError(true, error.message);
        }
      });

    return () => {
      isMounted.current = false;
    };
  }, [formData.esercizioSelected]);

  return (
    <div style={styles.container}>
      {loading ? (
        <SpinnerComponent size={24} />
      ) : (
        <div style={styles.wrapper}>
          <Typography variant="h4">Ispezione</Typography>
          <form style={styles.form} onSubmit={onHandleSubmit}>
            <Box style={styles.datiIspezioneBox}>
              <Typography variant="h5">Dati di ispezione</Typography>
              <div style={styles.textFieldWrapper}>
                <TextField
                  label="Nome esercizio"
                  defaultValue={location.state.nome}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  style={styles.textField}
                  fullWidth
                />
                <TextField
                  label="Ragione sociale"
                  defaultValue={location.state.ragioneSociale}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  style={styles.textField}
                  fullWidth
                />
              </div>
            </Box>

            <FormControl>
              <Select
                name="revisione"
                value={formData.revisioneSelected}
                onChange={onRevisioneChange}
                input={<StyledInputBase />}
                MenuProps={MenuProps}
                displayEmpty
                style={{ width: "745px" }}
                error={!!formErrors.revisioneSelected}
              >
                <MenuItem value="" disabled>
                  REVISIONE DEL MANUALE
                </MenuItem>
                {revisioniList.map((rev) => (
                  <MenuItem style={{whiteSpace: 'normal'}} key={rev.id} value={rev.id}>
                    {rev.manuale.titolo} / Data Agg:{" "}
                    {moment(rev.dataRevisione).format(
                      "DD-MM-YYYY"
                    )}
                  </MenuItem>
                )).reverse()}
              </Select>
              <FormHelperText error>
                {formErrors.revisioneSelected}
              </FormHelperText>
            </FormControl>

            <Box style={styles.datePickerWrapper}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePickerComponent
                  name="Data inizio"
                  value={formData.dataInizio}
                  onChange={(event) => {
                    onDateChange(event, "dataInizio");
                  }}
                  minDate={minDateInizio}
                  maxDate={maxDateInizio}
                  label={formData.dataInizio ? "" : "Data inizio"}
                  error={!!formErrors.dataInizio}
                  helperText={formErrors.dataInizio}
                />

                <DatePickerComponent
                  name="Data fine"
                  value={formData.dataFine}
                  onChange={(event) => {
                    onDateChange(event, "dataFine");
                  }}
                  minDate={minDateFine}
                  maxDate={maxDateFine}
                  label={formData.dataFine ? "" : "Data fine"}
                  error={!!formErrors.dataFine}
                  helperText={formErrors.dataFine}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <div style={styles.buttonWrapper}>
              <IndietroButton
                isDisabled={false}
                style={styles.indietroButton}
              />
              <Button
                type="submit"
                variant="contained"
                style={styles.cercaButton}
              >
                Cerca
              </Button>
            </div>
          </form>
        </div>
      )}

      <ErrorDialog
        open={error.hasError}
        onCloseButtonClicked={handleCloseErrorDialog}
        message={error.message}
      />
    </div>
  );
};

export default withStyles(styles)(FormRicercaIspezione);
