import { retrieveToken } from "../storage";
import { wrapFetch, ApiError } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getIspettori = (ispettoreId, page, size, sortby, gruppoId) => {
  const token = retrieveToken();
  const PATH = "/api/auditors";
  let query = `?page=${page}&size=${size}&sort=${sortby}&${sortby}.dir=ASC`;

  if (ispettoreId !== null) {
    query = query + "&id.equals=" + ispettoreId;
  }

  if (gruppoId !== null) {
    query += `&gruppoId.equals=${gruppoId}`;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getCountAuditor = (gruppoId) => {
  const token = retrieveToken();
  let PATH = "/api/auditors/count";
  if (gruppoId !== null && gruppoId !== undefined) {
    PATH += `?gruppoId.equals=${gruppoId}`;
  }
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getAuditors = (
  page,
  size,
  sortby,
  auditorId,
  email,
  nome,
  cognome,
  telefono,
  gruppoId,
  nomeGruppo
) => {
  const token = retrieveToken();
  const PATH = "/api/auditors";
  let query = `?page=${page}&size=${size}&sort=${sortby}&${sortby}.dir=ASC`;

  if (auditorId) {
    query = query + "&id.equals=" + auditorId;
  }

  if (email) {
    query = query + "&email.contains=" + email;
  }

  if (nome) {
    query = query + "&nome.contains=" + nome;
  }

  if (cognome) {
    query = query + "&cognome.contains=" + cognome;
  }

  if (telefono) {
    query = query + "&telefono.contains=" + telefono;
  }
  if (gruppoId) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }

  if (nomeGruppo) {
    query = query + "&nomeGruppo.contains=" + nomeGruppo;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const registerIspettori = (formData, isSuperAuditor) => {
  return registerIspettoreWithRole(formData, isSuperAuditor);
};
const registerIspettoreWithRole = (formData, isSuperAuditor) => {
  const token = retrieveToken();
  let PATH = "/api/auditor";
  if (isSuperAuditor) {
    PATH = "/api/auditor/register";
  }
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const requestBody = {
    email: formData.email,
    password: formData.password,
    nome: formData.nome,
    cognome: formData.cognome,
    superauditor: formData.superauditor,
    telefono: formData.telefono,
    terminiUsoAccettati: true,
    privacyPolicyAccettata: true,
    gruppoId: formData.gruppoId,
  };

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      //404 if the gruppoId does not correspond to a registered Gruppo
      message = "Registrazione non riuscita: dati non corretti.";
    } else if (status === 409) {
      message =
        "Registrazione non riuscita: esiste già un utente registrato con lo stesso indirizzo email indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const updateIspettore = (isAdmin, requestBody) => {
  const token = retrieveToken();
  let PATH = "/api/auditor";
  if (!isAdmin) {
    PATH = "/api/auditor/update";
  }
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: ispettore non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};
