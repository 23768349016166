import React, { Fragment } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import "./App.css";
import CustomHeader from "./components/CustomHeader";
import withAuthentication from "./components/withAuthentication";
import ControlPanel from "./scenes/controlpanel/ControlPanel";
import DettaglioEsercizio from "./scenes/controlpanel/dettaglio_eserciziocommerciale/DettaglioEsercizio";
import DettaglioIspettore from "./scenes/controlpanel/dettaglio_ispettore/DettaglioIspettore";
import FormRegistraIspezione from "./scenes/controlpanel/dettaglio_ispezioni/FormRegistraIspezione";
import NuovaIspezioneManualeSicurezza from "./scenes/controlpanel/NuovaIspezioneManualeSicurezza";
import ScuPage from "./scenes/controlpanel/ispezione_manuale/scu/ScuPage";
import HomePage from "./scenes/home/HomePage";
import LoginPage from "./scenes/login/LoginPage";
import LogoutPage from "./scenes/logout/LogoutPage";
import RecoverPswPage from "./scenes/resetpsw/RecoverPswPage";
import ResetPswPage from "./scenes/resetpsw/ResetPswPage";
import { getIspettoreId, isSuperAuditor, getGruppoId } from "./utils/storage";
import DettaglioIspezione from "./scenes/controlpanel/interfacciaIspezioni/DettaglioIspezione";
import NuovoGruppo from "./scenes/controlpanel/dettaglio_gruppi/NuovoGruppo";
import FormRicercaIspezione from "./scenes/controlpanel/dettaglio_ispezioni/FormRicercaIspezione";
import GruppoVisualizza from "./scenes/controlpanel/dettaglio_gruppi/gruppo-visualizza";
// import DettaglioIspezioneProva from "./scenes/controlpanel/interfacciaIspezioni/DettaglioIspezioneProva";

export default function AppMainComponent() {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <div className="App" style={{ minHeight: "100vh" }}>
          <CustomHeader></CustomHeader>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/logout" component={LogoutPage} />
            <Route path="/recover_password" component={RecoverPswPage} />
            <Route path="/reset_password" component={ResetPswPage} />
            <Route
              path="/amministrazione/ispettori/:ispettoreId"
              component={withAuthentication(WrapperDettaglioIspettore)}
            />
            <Route
              path="/amministrazione/esercizi_commerciali/:esercizioId/ispezioni/:ispezioneId"
              component={withAuthentication(WrapperDettaglioIspezione)}
            />
            <Route
              path="/amministrazione/esercizi_commerciali/:esercizioId/ispezioni"
              component={withAuthentication(WrapperFormRicercaIspezioni)}
            />
            <Route
              path="/amministrazione/esercizi_commerciali/:esercizioId"
              component={withAuthentication(
                WrapperDettaglioEsercizioCommerciale
              )}
            />
            <Route
              path="/amministrazione/manuali/:ispezioneId"
              component={withAuthentication(WrapperDettaglioIspezioneManuale)}
            />
            <Route
              path="/amministrazione/revisioni/:revisioneId"
              component={withAuthentication(WrapperDettaglioRevisione)}
            />
            <Route
              path="/amministrazione/scu/:ispezioneId"
              component={withAuthentication(WrapperScuPage)}
            />
            <Route
              path="/amministrazione"
              component={withAuthentication(ControlPanel)}
            />
            <Route
              path="/gruppiDiAuditing/nuovo-gruppo/:tipoGruppo"
              component={withAuthentication(WrapperNuovoGruppo)}
            />

            <Route
              path="/gruppi_di_auditing/:gruppoId"
              component={withAuthentication(WrapperGruppoVisualizza)}
            />

            <Route path="/" render={() => <HomePage />} />
          </Switch>
        </div>
      </LastLocationProvider>
    </BrowserRouter>
  );
}

function WrapperGruppoVisualizza() {
  const { gruppoId } = useParams();
  const gruppoIdNum = parseInt(gruppoId, 10);

  const ispettoreId =
    getIspettoreId() === null || getIspettoreId() === "null"
      ? null
      : Number.parseInt(getIspettoreId(), 10);

  const superAuditorView = isSuperAuditor() === "true" ? true : false;

  return (
    <GruppoVisualizza
      gruppoId={gruppoIdNum}
      ispettoreId={ispettoreId}
      superAuditorView={superAuditorView}
    />
  );
}

function WrapperDettaglioIspezioneManuale() {
  const { ispezioneId } = useParams();
  return <NuovaIspezioneManualeSicurezza ispezioneId={ispezioneId} />;
}

function WrapperNuovoGruppo() {
  const { tipoGruppo } = useParams();
  return <NuovoGruppo tipoGruppo={tipoGruppo} />;
}

function WrapperDettaglioIspettore() {
  let history = useHistory();
  let { ispettoreId } = useParams();

  const ispettoreIdView =
    getIspettoreId() === "null" ? null : Number.parseInt(getIspettoreId(), 10);

  const ispettoreView = ispettoreIdView !== null ? true : false;

  const superAuditorView = isSuperAuditor() === "true" ? true : false;

  const gruppoId =
    getGruppoId() === "null" ? null : Number.parseInt(getGruppoId(), 10);

  return (
    <DettaglioIspettore
      ispettoreView={ispettoreView}
      ispettoreId={ispettoreId}
      superAuditorView={superAuditorView}
      gruppoId={gruppoId}
      onClose={() => {
        history.goBack();
      }}
    />
  );
}
function WrapperDettaglioRevisione() {
  // let history = useHistory();
  return <DettaglioIspezione></DettaglioIspezione>;
}

function WrapperDettaglioIspezione() {
  let history = useHistory();
  const ispettoreId =
    getIspettoreId() === null || getIspettoreId() === "null"
      ? null
      : Number.parseInt(getIspettoreId(), 10);

  let { ispezioneId } = useParams();
  if (ispezioneId === "nuovo") {
    return <FormRegistraIspezione />;
  } else {
    return (
      <DettaglioIspezione
        ispezioneId={ispezioneId}
        ispettoreId={ispettoreId}
        onClose={() => {
          history.goBack();
        }}
      />
    );
  }
}

function WrapperDettaglioEsercizioCommerciale() {
  const ispettoreId =
    getIspettoreId() === null || getIspettoreId() === "null"
      ? null
      : Number.parseInt(getIspettoreId(), 10);
  let { esercizioId } = useParams();
  let component = null;
  const gruppoId =
    getGruppoId() === "null" ? null : Number.parseInt(getGruppoId(), 10);

  component = (
    <DettaglioEsercizio
      esercizioId={esercizioId}
      ispettoreId={ispettoreId}
      gruppoId={gruppoId}
    />
  );

  return <Fragment>{component}</Fragment>;
}

function WrapperFormRicercaIspezioni() {
  let { esercizioId } = useParams();
  return <FormRicercaIspezione esercizioId={esercizioId} />;
}

function WrapperScuPage() {
  const { ispezioneId } = useParams();
  return <ScuPage ispezioneId={ispezioneId} />;
}
