import React, { Component, Fragment } from "react";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import SpinnerComponent from "./SpinnerComponent";
import ActionButton from "./ActionButton";
import PropTypes from "prop-types";

import { registerZona } from "../../utils/api/zone_api";

const styles = {
  modalcontainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 560,
    height: 280,
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    border: "2px solid #000",
    boxShadow: 24,
    padding: "20px",
  },
  text: {
    padding: "0px 0px 10px 0px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "360px",
  },
};

export default class ModalCreazioneGruppo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gruppo: {
        nome: "",
      },
      validationErrors: {
        nome: "",
      },
      loading: false,
      success: false,
      showMessage: false,
      error: false,
      message: "",
    };
  }

  validateForm = () => {
    let data = this.state.gruppo;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "nome":
        if (value.length === 0 || value.trim().length === 0) {
          validationErrors[key] =
            "Il nome della zona deve contenere almeno un carattere diverso da spazio.";
        } else {
          validationErrors[key] = "";
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let isFormValid = this.validateForm();
    if (isFormValid) {
      registerZona(this.state.gruppo.nome)
        .then((result) => {
          this.setState({
            loading: false,
            success: true,
            showMessage: true,
            error: false,
            message: "Operazione eseguita con successo.",
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.props.onAuthError();
          } else {
            this.setState({
              loading: false,
              success: false,
              showMessage: true,
              error: true,
              message: error.message,
            });
          }
        });
    }
  };

  handleClose = () => {
    const isOperationCancelled = !this.state.success;
    this.props.onClose(isOperationCancelled);
  };

  handleChange = (event) => {
    let gruppo = this.state.gruppo;
    gruppo.nome = event.target.value;
    let validationErrors = this.state.validationErrors;
    validationErrors.nome = "";
    this.setState({
      gruppo,
      validationErrors,
      showMessage: false,
    });
  };

  render() {
    let submitBtnDisabled =
      this.state.validationErrors.nome.length > 0 || this.state.success;
    return (
      <Modal
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={this.props.open}
        onClose={this.handleClose}
      >
        <div style={styles.modalcontainer}>
          <Fragment>
            <Typography variant="h6" style={styles.text}>
              Registra una nuova zona
            </Typography>
            <Typography variant="body1" style={styles.text}>
              Inserisci il nome della nuova zona:
            </Typography>
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <Grid
                container
                spacing={2}
                style={{ padding: "10px 0px 0px 0px" }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    label="Nome zona"
                    value={this.state.nome}
                    onChange={this.handleChange}
                    style={styles.textfield}
                    helperText={this.state.validationErrors.nome}
                    error={
                      this.state.validationErrors.nome.length > 0 ? true : false
                    }
                  />
                </Grid>
              </Grid>
              <Container style={{ textAlign: "center" }}>
                <ActionButton
                  label="Indietro"
                  onClick={this.handleClose}
                  disabled={false}
                  grayversion={"true"}
                />
                <ActionButton
                  label="Registra"
                  onClick={this.handleSubmit}
                  disabled={submitBtnDisabled}
                  grayversion={"false"}
                />
              </Container>
            </form>
            {this.state.loading ? <SpinnerComponent size={20} /> : null}
            <Typography
              variant="body1"
              style={{
                color: this.state.error ? "red" : "#ea781d",
                visibility: this.state.showMessage ? "visible" : "hidden",
                padding: "4px 0px 10px 0px",
              }}
            >
              {this.state.message}
            </Typography>
          </Fragment>
        </div>
      </Modal>
    );
  }
}

ModalCreazioneGruppo.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAuthError: PropTypes.func.isRequired,
};
