import React from 'react';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from "@material-ui/core/Typography";

import PropTypes from 'prop-types';
//import theme from '../../../../theme';

const styles = {
    container: {
        paddingBottom: '10px',
        //paddingTop: '4px',
    },
    label: {
        textAlign: 'left',
    },
    formcontrol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
    },
    formOption: {
        paddingTop: '4px',
        paddingBottom: '4px',
        textAlign: 'left'
    },
}

export default function FormWithRadioButtons(props) {
    return (
        <Container style={styles.container}>
            <Typography style={styles.label} variant='body1'>{props.label}</Typography>
            <FormControl style={styles.formcontrol}>
                <RadioGroup
                    row={props.row}
                    aria-labelledby={props.value}
                    name={props.name}
                    value={props.value}
                    onChange={(e) => {props.onChange(props.fieldName, e)}}
                >
                    { props.options.map((option, index) => ( 
                        <FormControlLabel 
                            key={index} 
                            disabled={props.disabled} 
                            value={option.value} 
                            control={<Radio />} 
                            label={option.label} 
                            style={styles.formOption}
                        />
                    )) }
                </RadioGroup>
            </FormControl>
        </Container>
    );
}

FormWithRadioButtons.propTypes = {
    row: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}
