import React from "react";
import { DatePicker } from "@material-ui/pickers";
import { CalendarTodayOutlined } from "@material-ui/icons";

const styles = {
  datePicker: {
    width: "360px",
    padding: "7px 0",
  },

  inputProps: {
    borderRadius: "40px",
    border: "solid 1px #80bdff",
    height: "42px",
  },
};

const DatePickerComponent = ({
  name,
  value,
  onChange,
  maxDate,
  minDate,
  label,
  error,
  helperText,
}) => {
  return (
    <DatePicker
      style={styles.datePicker}
      className="MyDatePicker"
      inputVariant="outlined"
      name={name}
      format="dd-MM-yyyy"
      value={value}
      onChange={onChange}
      maxDate={maxDate}
      minDate={minDate}
      initialFocusedDate={minDate}
      label={label}
      InputLabelProps={{ shrink: false }}
      InputProps={{
        style: styles.inputProps,
        endAdornment: <CalendarTodayOutlined />,
      }}
      autoComplete="off"
      error={error}
      helperText={helperText}
    />
  );
};

export default DatePickerComponent;
