import React, { Component, Fragment } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import ActionButton from "../../../components/ActionButton";
import TextAreaComponent from "./TextAreaComponent";
import TimePickerComponent from "./TimePickerComponent";
import DatePickerComponent from "./DatePickerComponent";

import moment from "moment";

import PropTypes from "prop-types";
//import theme from '../../../../theme';

const styles = {
  root: {
    padding: "0px",
    margin: "0px",
  },
  title: {
    padding: "10px 0px",
    textAlign: "center",
  },
  containerRadioButtons: {
    paddingBottom: "10px",
    //paddingTop: '4px',
    textAlign: "left",
  },
  labelRadioButtons: {
    textAlign: "left",
  },
  formcontrol: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
  },
  formOption: {
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "left",
  },
  containerInnerForm: {
    textAlign: "left",
  },
  textfield: {
    marginBottom: "12px",
    //width: '360px'
  },
  buttoncontainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 0px",
  },
};

export default class SezioneE extends Component {
  constructor(props) {
    super(props);
    const sezioneE = this.props.sezioneE;
    this.state = {
      record: {
        id: sezioneE.id,
        ispezioneId: sezioneE.ispezioneId,
        dataUltimaModifica: sezioneE.dataUltimaModifica,
        dataNotificaAtto:
          sezioneE.dataNotificaAtto === null
            ? moment()
            : moment(sezioneE.dataNotificaAtto),
        orarioNotificaAtto:
          sezioneE.orarioNotificaAtto === null
            ? moment()
            : moment(sezioneE.orarioNotificaAtto, "H:mm:ss"),
        luogoNotificaAtto:
          sezioneE.luogoNotificaAtto === null ? "" : sezioneE.luogoNotificaAtto,
        modalitaConsegna:
          sezioneE.modalitaConsegna === null ? "" : sezioneE.modalitaConsegna,
        nomeDestinatario:
          sezioneE.nomeDestinatario === null ? "" : sezioneE.nomeDestinatario,
        cognomeDestinatario:
          sezioneE.cognomeDestinatario === null
            ? ""
            : sezioneE.cognomeDestinatario,
        nomeTramite: sezioneE.nomeTramite === null ? "" : sezioneE.nomeTramite,
        cognomeTramite:
          sezioneE.cognomeTramite === null ? "" : sezioneE.cognomeTramite,
        identificatoMezzoDi:
          sezioneE.identificatoMezzoDi === null
            ? ""
            : sezioneE.identificatoMezzoDi,
        qualificatosi:
          sezioneE.qualificatosi === null ? "" : sezioneE.qualificatosi,
        luogoConsegna:
          sezioneE.luogoConsegna === null ? "" : sezioneE.luogoConsegna,
        indirizzoUfficio:
          sezioneE.indirizzoUfficio === null ? "" : sezioneE.indirizzoUfficio,
        altro: sezioneE.altro === null ? "" : sezioneE.altro,
      },
      saveButtonEnabled: false,
    };
  }

  onSelectionChanged = (event) => {
    let record = this.state.record;
    record[event.target.name] = event.target.value;
    if (event.target.name === "modalitaConsegna") {
      if (event.target.value === "A_MANO") {
        record.nomeTramite = "";
        record.cognomeTramite = "";
        record.identificatoMezzoDi = "";
        record.qualificatosi = "";
      } else {
        record.nomeDestinatario = "";
        record.cognomeDestinatario = "";
      }
    }
    if (event.target.name === "luogoConsegna") {
      if (event.target.value !== "UFFICIO") {
        record.indirizzoUfficio = "";
      } else if (event.target.value !== "ALTRO") {
        record.altro = "";
      }
    }
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  onTextChanged = (event) => {
    let record = this.state.record;
    record[event.target.name] = event.target.value;
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  onDateChanged = (fieldName, date) => {
    let record = this.state.record;
    record[fieldName] = moment(date);
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  onTimeChanged = (fieldName, date) => {
    this.onDateChanged(fieldName, date);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let data = Object.assign({}, this.state.record);
    data.dataNotificaAtto = data.dataNotificaAtto.format("YYYY-MM-DD");
    data.orarioNotificaAtto = data.orarioNotificaAtto.format("HH:mm:ss");
    data.luogoNotificaAtto =
      data.luogoNotificaAtto === "" ? null : data.luogoNotificaAtto;
    data.modalitaConsegna =
      data.modalitaConsegna === "" ? null : data.modalitaConsegna;
    data.nomeDestinatario =
      data.nomeDestinatario === "" ? null : data.nomeDestinatario;
    data.cognomeDestinatario =
      data.cognomeDestinatario === "" ? null : data.cognomeDestinatario;
    data.nomeTramite = data.nomeTramite === "" ? null : data.nomeTramite;
    data.cognomeTramite =
      data.cognomeTramite === "" ? null : data.cognomeTramite;
    data.identificatoMezzoDi =
      data.identificatoMezzoDi === "" ? null : data.identificatoMezzoDi;
    data.qualificatosi = data.qualificatosi === "" ? null : data.qualificatosi;
    data.luogoConsegna = data.luogoConsegna === "" ? null : data.luogoConsegna;
    data.indirizzoUfficio =
      data.indirizzoUfficio === "" ? null : data.indirizzoUfficio;
    data.altro = data.altro === "" ? null : data.altro;
    this.props.onSubmit(data);
  };

  render() {
    return (
      <Container style={styles.root}>
        <Typography style={styles.title} variant="h5">
          Sezione E
        </Typography>
        <Typography style={styles.title} variant="h6">
          Relata di notifica(ai sensi artt.li 138, 139, 141 e 145 C.P.C.)
        </Typography>
        <DatePickerComponent
          label="Il giorno:"
          name="dataNotificaAtto"
          value={this.state.record.dataNotificaAtto}
          disabled={false}
          onChange={this.onDateChanged}
        />
        <TimePickerComponent
          label="alle ore:"
          name="orarioNotificaAtto"
          value={this.state.record.orarioNotificaAtto}
          disabled={false}
          onChange={this.onTimeChanged}
        />
        <TextAreaComponent
          placeholder=""
          label="in"
          name="luogoNotificaAtto"
          value={this.state.record.luogoNotificaAtto}
          disabled={false}
          onChange={this.onTextChanged}
          multiline={false}
          numRows={1}
        />
        <Container style={styles.containerRadioButtons}>
          <Typography style={styles.labelRadioButtons} variant="body1">
            i sottoscritti incaricati del controllo ufficiale dichiarano di aver
            notificato il presente atto:
          </Typography>
          <FormControl style={styles.formcontrol}>
            <RadioGroup
              aria-labelledby="modalita-consegna-buttons-group"
              name="modalitaConsegna"
              value={this.state.record.modalitaConsegna}
              onChange={this.onSelectionChanged}
            >
              <Fragment>
                <FormControlLabel
                  disabled={false}
                  value="A_MANO"
                  control={<Radio />}
                  label="consegnandone copia conforme nelle mani del destinatario, in qualità di titolare/Leg.Rap.te/OSA"
                  style={styles.formOption}
                />
                <Container style={styles.containerInnerForm}>
                  <TextField
                    label="Inserire nome destinatario"
                    value={this.state.record.nomeDestinatario}
                    name="nomeDestinatario"
                    onChange={this.onTextChanged}
                    variant="outlined"
                    style={styles.textfield}
                    fullWidth
                    disabled={this.state.record.modalitaConsegna !== "A_MANO"}
                  />
                  <TextField
                    label="Inserire cognome destinatario"
                    value={this.state.record.cognomeDestinatario}
                    name="cognomeDestinatario"
                    onChange={this.onTextChanged}
                    variant="outlined"
                    style={styles.textfield}
                    fullWidth
                    disabled={this.state.record.modalitaConsegna !== "A_MANO"}
                  />
                </Container>
              </Fragment>
              <Fragment>
                <FormControlLabel
                  disabled={false}
                  value="CON_TRAMITE"
                  control={<Radio />}
                  label="consegnandone copia conforme al Sig."
                  style={styles.formOption}
                />
                <Container style={styles.containerInnerForm}>
                  <TextField
                    label="Inserire nome"
                    value={this.state.record.nomeTramite}
                    name="nomeTramite"
                    onChange={this.onTextChanged}
                    variant="outlined"
                    style={styles.textfield}
                    fullWidth
                    disabled={
                      this.state.record.modalitaConsegna !== "CON_TRAMITE"
                    }
                  />
                  <TextField
                    label="Inserire cognome"
                    value={this.state.record.cognomeTramite}
                    name="cognomeTramite"
                    onChange={this.onTextChanged}
                    variant="outlined"
                    style={styles.textfield}
                    fullWidth
                    disabled={
                      this.state.record.modalitaConsegna !== "CON_TRAMITE"
                    }
                  />
                  <Typography style={styles.labelRadioButtons}>
                    identificato a mezzo di
                  </Typography>
                  <TextField
                    value={this.state.record.identificatoMezzoDi}
                    name="identificatoMezzoDi"
                    onChange={this.onTextChanged}
                    variant="outlined"
                    style={styles.textfield}
                    fullWidth
                    disabled={
                      this.state.record.modalitaConsegna !== "CON_TRAMITE"
                    }
                  />
                  <Typography style={styles.labelRadioButtons}>
                    qualificatosi per
                  </Typography>
                  <TextField
                    value={this.state.record.qualificatosi}
                    name="qualificatosi"
                    onChange={this.onTextChanged}
                    variant="outlined"
                    style={styles.textfield}
                    fullWidth
                    disabled={
                      this.state.record.modalitaConsegna !== "CON_TRAMITE"
                    }
                  />
                  <Typography style={styles.labelRadioButtons}>
                    che si incarica della consegna.
                  </Typography>
                </Container>
              </Fragment>
            </RadioGroup>
          </FormControl>
        </Container>
        <Container style={styles.containerRadioButtons}>
          <Typography style={styles.labelRadioButtons} variant="body1">
            La consegna avviene presso:
          </Typography>
          <FormControl style={styles.formcontrol}>
            <RadioGroup
              aria-labelledby="luogo-consegna-buttons-group"
              name="luogoConsegna"
              value={this.state.record.luogoConsegna}
              onChange={this.onSelectionChanged}
            >
              <FormControlLabel
                disabled={false}
                value="CASA"
                control={<Radio />}
                label="Casa di abitazione"
                style={styles.formOption}
              />
              <Fragment>
                <FormControlLabel
                  disabled={false}
                  value="UFFICIO"
                  control={<Radio />}
                  label="Ufficio, luogo dove esercita attività di industria o commercio posta in"
                  style={styles.formOption}
                />
                <Container style={styles.containerInnerForm}>
                  <TextField
                    label="Inserire indirizzo"
                    value={this.state.record.indirizzoUfficio}
                    name="indirizzoUfficio"
                    onChange={this.onTextChanged}
                    variant="outlined"
                    style={styles.textfield}
                    fullWidth
                    multiline={true}
                    rows={4}
                    disabled={this.state.record.luogoConsegna !== "UFFICIO"}
                  />
                </Container>
              </Fragment>
              <Fragment>
                <FormControlLabel
                  disabled={false}
                  value="ALTRO"
                  control={<Radio />}
                  label="Altro (specificare):"
                  style={styles.formOption}
                />
                <Container style={styles.containerInnerForm}>
                  <TextField
                    label="Indicare altro luogo di consegna"
                    value={this.state.record.altro}
                    name="altro"
                    onChange={this.onTextChanged}
                    variant="outlined"
                    style={styles.textfield}
                    fullWidth
                    disabled={this.state.record.luogoConsegna !== "ALTRO"}
                  />
                </Container>
              </Fragment>
            </RadioGroup>
          </FormControl>
        </Container>
        <div style={styles.buttoncontainer}>
          <ActionButton
            label="Salva sezione E"
            disabled={!this.state.saveButtonEnabled}
            grayversion={"false"}
            onClick={this.handleSubmit}
          />
        </div>
      </Container>
    );
  }
}

SezioneE.propTypes = {
  sezioneE: PropTypes.object.isRequired,
  onChangesNotSaved: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
