import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getSCU = (checklistId,ispezioneId) => {
  const token = retrieveToken();
  const PATH = "/api/scu"; 
  let query = "?"
  + "checklistId=" + checklistId
  + "&ispezioneId=" + ispezioneId
;
  const URL = BASE_URL + PATH + query;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}

export const putSCU = (requestBody) => {
  const token = retrieveToken();
  const PATH = "/api/scu"; 
  const URL = BASE_URL + PATH ;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
      'Authorization' : authtoken,
      'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const getSezioneC = (ispezioneId) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = "/api/scu/sezionec";
  let query = "?"
  + "ispezioneId=" + ispezioneId;
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Operazione non riuscita: richiesta non corretta.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}

export const updateSezioneC = (requestBody) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = "/api/scu/sezionec"; 
  const URL = BASE_URL + PATH ;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404) {
      message = 'Aggiornamento della sezione C non riuscito: i dati non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
      'Authorization' : authtoken,
      'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const getSezioneD = (ispezioneId) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = "/api/scu/sezioned";
  let query = "?"
  + "ispezioneId=" + ispezioneId;
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Operazione non riuscita: richiesta non corretta.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}

export const updateSezioneD = (requestBody) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = "/api/scu/sezioned"; 
  const URL = BASE_URL + PATH ;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404) {
      message = 'Aggiornamento della sezione D non riuscito: i dati non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
      'Authorization' : authtoken,
      'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const getSezioneE = (ispezioneId) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = "/api/scu/sezionee";
  let query = "?"
  + "ispezioneId=" + ispezioneId;
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Operazione non riuscita: richiesta non corretta.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}

export const updateSezioneE = (requestBody) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = "/api/scu/sezionee"; 
  const URL = BASE_URL + PATH ;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404) {
      message = 'Aggiornamento della sezione E non riuscito: i dati non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
      'Authorization' : authtoken,
      'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}