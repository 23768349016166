import React from "react";
import { Button, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../theme.js";

const styles = {
  buttonStyle: {
    backgroundColor: "transparent",
    color: "white",
    border: "1px solid #ffffff",
    borderRadius: 30,
    margin: 10,

    width: 200,
  },
};

export default function Footer() {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          position: "static",
          bottom: 0,
          width: "100%",
          background: "linear-gradient(to right,#0d5fab,#00b1b7,#0d5fab)",
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap", // Aggiungi questa proprietà
          gap: 10,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Typography
          variant="body1"
          style={{
            fontSize: "10",
            color: theme.palette.secondary.main,
            alignSelf: "center",
          }}
        >
          Copyright© BIORSAF SRL Innovativa
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            href="https://bit.ly/bsfoodcondizioni"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.buttonStyle}
          >
            Condizioni D'uso
          </Button>
          <span>&nbsp;&nbsp;</span>
          <Button
            href="https://biorsaf.it/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.buttonStyle}
          >
            Privacy Policy
          </Button>
          <span>&nbsp;&nbsp;</span>
          <Button
            href="https://biorsaf.it/cookie-policy/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.buttonStyle}
          >
            Cookie policy
          </Button>
          <span>&nbsp;&nbsp;</span>
          <Button
            href="https://bit.ly/manualebsfood"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.buttonStyle}
          >
            Guida Piattaforma
          </Button>
          <span>&nbsp;&nbsp;</span>
          <Button
            href="https://bit.ly/bs-food-tickets"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.buttonStyle}
          >
            Segnalazioni
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
}
