import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import PropTypes from "prop-types";

const Table = selectTableHOC(ReactTable);

export default class SelectTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: this.props.selectedElement || [], // se this.props.selectedElement è null, undefined, o qualsiasi altro valore "falsy", selection verrà impostato su un array vuoto
      selectAll: false,
    };
  }

  componentDidMount() {
    const selectAll = this.areAllCurrentPageSelected();
    this.setState({ selectAll });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const selectAll = this.areAllCurrentPageSelected();
      this.setState({ selectAll });
    }
  }

  toggleSelection = (_key, _shift, row) => {
    const { selectType } = this.props;

    this.setState((prevState) => {
      let selection = [...prevState.selection];

      if (selection.includes(row.id)) {
        selection = selection.filter((item) => item !== row.id);
      } else {
        if (selectType === "radio") {
          selection = [row.id];
        }
        if (selectType === "checkbox") {
          selection.push(row.id);
        }
      }
      this.props.passSelectionToParent(selection);

      return { selection };
    });
  };

  isSelected = (key) => {
    return this.state.selection.includes(key);
  };

  toggleAll = () => {
    const selectAll = !this.state.selectAll;
    let selection = [...this.state.selection];

    // di seguito: ottengo l'istanza avvolta del componente ReactTable esteso con selectTableHOC. In questo modo accedo alle func e allo stato interno di ReactTable:
    const wrappedInstance = this.checkboxTable.getWrappedInstance();
    // di seguito: ottengo la lista dei record correnti dalla tabella. getResolvedState() è un metodo di ReactTable che restituisce lo stato corrente della tabella; sortedData è un array dei record nella tabella in base all'attuale ordinamento:
    const currentRecords = wrappedInstance.getResolvedState().sortedData;

    if (selectAll) {
      currentRecords.forEach((item) => {
        if (!selection.includes(item._original.id)) {
          selection.push(item._original.id);
        }
      });
    } else {
      selection = selection.filter(
        (id) => !currentRecords.some((item) => item._original.id === id)
      );
    }
    this.setState({ selectAll, selection }, () => {
      if (typeof this.props.passSelectionToParentAll === "function") {
        this.props.passSelectionToParentAll(selection);
      }
      if (typeof this.props.onToggleSelectAll === "function") {
        this.props.onToggleSelectAll(selectAll);
      }
    });
  };

  areAllCurrentPageSelected = () => {
    // const wrappedInstance = this.checkboxTable.getWrappedInstance();
    // const currentRecords = wrappedInstance.getResolvedState().sortedData;

    if (this.props.data.length === 0) {
      return false;
    } else {
      return this.props.data.every((item) =>
        this.state.selection.includes(item.id)
      );
    }
  };

  onPageChange = (page) => {
    if (this.props.onPageChange) {
      this.props.onPageChange(page);
    }

    setTimeout(() => {
      const selectAll = this.areAllCurrentPageSelected();
      this.setState({ selectAll });
    }, 100);
  };

  render() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;
    const {
      selectType,
      data,
      columns,
      defaultPageSize,
      style,
      pages,
      showPageSizeOptions,
      pageSizeOptions,
      onPageSizeChange,
    } = this.props;
    const selectionProps = {
      selectType,
      selectAll,
      toggleSelection,
      toggleAll,
      isSelected,
    };

    return (
      <div>
        <Table
          data={data}
          columns={columns}
          style={style}
          defaultPageSize={defaultPageSize}
          onPageChange={this.onPageChange}
          pages={pages}
          manual
          keyField={"id"}
          ref={(r) => {
            this.checkboxTable = r;
          }}
          filterable
          defaultFilterMethod={(filter, row, _column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              : true;
          }}
          className="-striped"
          previousText="Precedente"
          nextText="Successivo"
          noDataText="Nessuna riga trovata"
          pageText="Pagina"
          ofText="di"
          rowsText="righe"
          pageJumpText="Vai a pagina"
          rowsSelectorText="righe per pagina"
          showPageSizeOptions={showPageSizeOptions}
          pageSizeOptions={pageSizeOptions}
          onPageSizeChange={onPageSizeChange}
          {...selectionProps}
        />
      </div>
    );
  }
}

SelectTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultPageSize: PropTypes.number,
  pages: PropTypes.number,
  showPageSizeOptions: PropTypes.bool,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  selectType: PropTypes.oneOf(["checkbox", "radio"]),
  passSelectionToParent: PropTypes.func,
  passSelectionToParentAll: PropTypes.func,
  onToggleSelectAll: PropTypes.func,
  style: PropTypes.object,
  selectedElement: PropTypes.arrayOf(PropTypes.number),
};
