import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Background from "../../../background.png";
import theme from "../../../theme.js";
import FormModificaIspettore from "./FormModificaIspettore";
import FormRegistraIspettore from "./FormRegistraIspettore";
import IspettoreXIspezioni from "./IspettoreXIspezioni";
import { withRouter } from "react-router-dom";

export const Component = withRouter(({ history, location }) => {});

class DettaglioIspettore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      secondTabEnabled: true,
      showRegistrationForm: true,
      showRegistrationMessage: false,
      showChangesSavedMessage: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    if (this.props.ispettoreId === "nuovo") {
      this.setState({
        secondTabEnabled: false,
        showRegistrationForm: true,
      });
    } else {
      this.setState({
        secondTabEnabled: true,
        showRegistrationForm: false,
      });
    }
  }

  onChangesSaved = () => {
    this.setState(
      {
        showChangesSavedMessage: true,
      },
      () => {
        const { history } = this.props;
        history.push("/amministrazione/ispettori");
      }
    );
  };

  onRecordSaved = () => {
    this.setState(
      {
        secondTabEnabled: false,
        showRegistrationMessage: true,
      },
      () => {
        const { history } = this.props;
        history.push("/amministrazione/ispettori");
      }
    );
  };

  onFormModified = () => {
    this.setState({
      showRegistrationMessage: false,
      showChangesSavedMessage: false,
    });
  };

  render() {
    const showRegistrationForm = this.state.showRegistrationForm;
    const title = showRegistrationForm ? "Nuovo Auditor" : "Dettagli Auditor";
    let isAdmin = false;
    if (!this.props.ispettoreView) {
      isAdmin = true;
    }

    return (
      <div
        style={{
          minHeight: "100vh",
          textAlign: "center",
          color: "black",
          paddingTop: "70px",
          paddingBottom: "26px",
          paddingLeft: "10px",
          paddingRight: "10px",
          backgroundImage:
            this.state.width > 1160
              ? `url("${Background}"),url("${Background}")`
              : `url("")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "",
          backgroundAttachment: "fixed",
          backgroundPositionY: "50%",
          backgroundPositionX:
            this.state.width > 1899
              ? "130%, -30%"
              : this.state.width > 1799
              ? "140%, -40%"
              : this.state.width > 1599
              ? "150%, -50%"
              : this.state.width > 1399
              ? "170%, -70%"
              : this.state.width > 1299
              ? "190%, -90%"
              : this.state.width > 1250
              ? "210%, -110%"
              : this.state.width > 1200
              ? "230%, -130%"
              : "250%, -150%",
        }}
      >
        <Typography variant="h4">{title}</Typography>
        {this.props.ispettoreId !== "nuovo" ? (
          <>
            <Box style={{ paddingTop: "20px" }}>
              <Typography
                variant="body1"
                style={{
                  color: theme.palette.primary.main,
                  visibility: this.state.showChangesSavedMessage
                    ? "inherit"
                    : "hidden",
                }}
              >
                Dati aggiornati con successo.
              </Typography>
            </Box>
            <FormModificaIspettore
              isAdmin={isAdmin}
              ispettoreId={this.props.ispettoreId}
              gruppoId={this.props.gruppoId}
              onFormModified={this.onFormModified}
              onFormSubmitted={this.onChangesSaved}
              ispettoreView={this.props.ispettoreView}
            />
          </>
        ) : (
          <>
            <div hidden={this.state.tabValue !== 0}>
              <Box style={{ paddingTop: "20px" }}>
                {this.state.tabValue === 0 && showRegistrationForm ? (
                  <Fragment>
                    <Typography
                      variant="body1"
                      style={{
                        color: theme.palette.primary.main,
                        visibility: this.state.showRegistrationMessage
                          ? "inherit"
                          : "hidden",
                      }}
                    >
                      Registrazione effettuata con successo.
                    </Typography>
                    <FormRegistraIspettore
                      gruppoId={this.props.gruppoId}
                      onFormModified={this.onFormModified}
                      onFormSubmitted={this.onRecordSaved}
                      superAuditorView={this.props.superAuditorView}
                      ispettoreView={this.props.ispettoreView}
                    />
                  </Fragment>
                ) : null}
                {this.state.tabValue === 0 && !showRegistrationForm ? (
                  <Fragment>
                    <Typography
                      variant="body1"
                      style={{
                        color: theme.palette.primary.main,
                        visibility: this.state.showChangesSavedMessage
                          ? "inherit"
                          : "hidden",
                      }}
                    >
                      Dati aggiornati con successo.
                    </Typography>
                    <FormModificaIspettore
                      isAdmin={isAdmin}
                      ispettoreId={this.props.ispettoreId}
                      superAuditorView={this.props.superAuditorView}
                      ispettoreView={this.props.ispettoreView}
                      gruppoId={this.props.gruppoId}
                      onFormModified={this.onFormModified}
                      onFormSubmitted={this.onChangesSaved}
                    />
                  </Fragment>
                ) : null}
              </Box>
            </div>
            <div hidden={this.state.tabValue !== 1}>
              <Box style={{ paddingTop: "44px" }}>
                {this.state.tabValue === 1 && (
                  <IspettoreXIspezioni ispettoreId={this.props.ispettoreId} />
                )}
              </Box>
            </div>
          </>
        )}
      </div>
    );
  }
}

DettaglioIspettore.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  ispettoreId: PropTypes.string.isRequired,
  ispettoreView: PropTypes.bool,
  superAuditorView: PropTypes.bool,
  gruppoId: PropTypes.number,
};

export default withRouter(DettaglioIspettore);
