import React, { Component } from "react";
import BasicTable from "./BasicTable";
import ClearIcon from "@material-ui/icons/Clear";
import { Search } from "@material-ui/icons";
import {
  IconButton,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Modal,
} from "@material-ui/core";
import {
  getCountGruppi,
  getGruppiByEsercizioCommerciale,
} from "../../utils/api/gruppi-auditing_api";
import PropTypes from "prop-types";

const styles = {
  GruppiAuditingModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: "840px",
    borderRadius: "20px",
    padding: "35px",
    overflow: "auto",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    padding: "4px",
    backgroundColor: "red",
    color: "white",
    boxShadow: "3px 3px 10px 0px rgba(0,0,0,0.45)",
  },
};

class GruppiAuditingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gruppiAuditing: [],
      columns: [],
      gruppoId: "",
      gruppoName: "",
      gruppoType: "",
      loadedPage: 0,
      pageSize: 10,
      totalPages: 1,
      totalCount: 1,
    };
  }

  componentDidMount() {
    this.loadGruppiAuditing(this.state.loadedPage);
    this.fetchCount();
    this.setState({
      columns: this.getColumns(),
    });
  }

  fetchCount = async () => {
    const result = await getCountGruppi(this.props.recordId);
    this.setState((prevState) => ({
      totalCount: result,
      totalPages: Math.ceil(result / prevState.pageSize),
    }));
  };

  loadGruppiAuditing = async (pageIndex) => {
    const result = await getGruppiByEsercizioCommerciale(
      pageIndex,
      this.state.pageSize,
      "id",
      "",
      this.props.recordId,
      this.state.gruppoId,
      this.state.gruppoName,
      this.state.gruppoType
    );
    this.setState({ gruppiAuditing: result });
  };

  handleApplyFilterClick = () => {
    this.setState({ loadedPage: 0 }, () => this.loadGruppiAuditing(0));
  };

  handleChangeGruppoId = (e) => {
    if (/^[0-9]*$/.test(e.target.value)) {
      this.setState({ gruppoId: e.target.value });
    }
  };

  getColumns = () => {
    return [
      {
        Header: "ID gruppo di auditing",
        id: "id",
        accessor: (d) => d.id,
        minWidth: 180,
        style: { textAlign: "center" },
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            value={this.state.gruppoId}
            onChange={this.handleChangeGruppoId}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    size="small"
                    onClick={this.handleApplyFilterClick}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Nome gruppo di auditing",
        id: "name",
        accessor: (d) => d.name,
        minWidth: 240,
        style: { textAlign: "center" },
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            value={this.state.gruppoName}
            onChange={(e) => {
              this.setState({
                gruppoName: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    size="small"
                    onClick={this.handleApplyFilterClick}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Tipologia gruppo di auditing",
        id: "tipoGruppo",
        accessor: (d) =>
          d.tipoGruppo === "GRUPPO_RAPIDO"
            ? "Gruppo di auditing rapido"
            : "Gruppo di auditing personalizzato",
        minWidth: 240,
        style: { textAlign: "center" },
        filterable: true,
        Filter: (_cellInfo) => (
          <Select
            variant="outlined"
            style={{ height: "30.6px" }}
            fullWidth
            displayEmpty
            value={this.state.gruppoType || ""}
            onChange={(e) => {
              this.setState(
                {
                  gruppoType: e.target.value,
                },
                () => this.loadGruppiAuditing(0)
              );
            }}
          >
            <MenuItem value="">Tutti i gruppi</MenuItem>
            <MenuItem value="GRUPPO_RAPIDO">Gruppo Rapido</MenuItem>
            <MenuItem value="GRUPPO_PERSONALIZZATO">
              Gruppo Personalizzato
            </MenuItem>
          </Select>
        ),
      },
    ];
  };

  handleModalPageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () => this.loadGruppiAuditing(pageIndex)
    );
  };

  render() {
    const { open, onClose } = this.props;

    return (
      <Modal style={styles.gruppiAuditing} open={open} onClose={onClose}>
        <div style={styles.content}>
          <IconButton style={styles.closeButton} onClick={onClose}>
            <ClearIcon fontSize="small" />
          </IconButton>
          <BasicTable
            data={this.state.gruppiAuditing}
            columns={this.state.columns}
            page={this.state.loadedPage}
            defaultPageSize={this.state.pageSize}
            pages={this.state.totalPages}
            onPageChange={this.handleModalPageChange}
          />
        </div>
      </Modal>
    );
  }

  static propTypes = {
    recordId: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };
}

export default GruppiAuditingModal;
