import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import theme from "../../theme";

export default function CompleteIspezioneButton(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onComplete();
    history.goBack();
    history.goBack();
  };
  const handleCloseDisagree = () => {
    setOpen(false);
  };
  let history = useHistory();

  return (
    <div>
      <Button
        type="button"
        onClick={handleClickOpen}
        variant="contained"
        size="medium"
        style={{
          color: theme.palette.secondary.main,
          margin: "10px",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        COMPLETA
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Completa Ispezione?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Completando l'ispezione non sarà più possibile riprendere il
            completamento della checklist. Procedere?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisagree} color="primary">
            No
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CompleteIspezioneButton.propTypes = {
  onComplete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
