import React from 'react';
import { Route, BrowserRouter, Switch } from "react-router-dom";

import ResetPswSuccessPage from './scenes/resetpsw/ResetPswSuccessPage';
import AppMainComponent from './AppMainComponent';

import './App.css';
import Footer from './components/Footer'
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function App() {

  return (
    <BrowserRouter >
      <div >
        <Switch>
          <Route path="/reset_password_success" component={ResetPswSuccessPage} />
          <Route path="/" render={() => <AppMainComponent/>} />
        </Switch>
      </div>
      <Footer></Footer>
    </BrowserRouter>
  );
}
