import React from "react";
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";
import theme from "../../theme";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginTop: "150px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px grey",
    borderRadius: 20,
    marginLeft: "25%",
    marginRight: "25%",
    //height: '85%'
    minHeight: "350px",
    display: "flex",
    maxWidth: 1100,
  },
  miniModalContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #00b1b7",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    height: "400px",
    display: "flex",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  buttoncontainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "space-evenly",
    padding: "10px 0px",
  },
  modalTitle: {
    fontSize: 25,
    paddingTop: 20,
    paddingBottom: 10,
    alignSelf: "center",
  },
};
export const Component = withRouter(({ history, location }) => {});

class ModalSelezioneTipoGruppo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={styles.modalcontainer}>
          <div
            style={{
              border: "0.5px solid #e0e0e0",
              borderRadius: 20,
              width: "80%",
            }}
          >
            <div style={styles.modalsectioncontainer}>
              <Typography style={styles.modalTitle}>
                Selezionare il tipo di gruppo:{" "}
              </Typography>
              <div style={styles.buttoncontainer}>
                <Button
                  onClick={() => {
                    const { history } = this.props;
                    history.push("/gruppiDiAuditing/nuovo-gruppo/rapido");
                  }}
                  style={{
                    color: theme.palette.secondary.main,
                    margin: "10px",
                    padding: 12,
                    borderRadius: 30,
                    background: "#00b1b7",
                    width: "320px",
                  }}
                >
                  Gruppo di auditing rapido
                </Button>

                <Button
                  onClick={() => {
                    const { history } = this.props;
                    history.push(
                      "/gruppiDiAuditing/nuovo-gruppo/personalizzato"
                    );
                  }}
                  // disabled={true}
                  style={{
                    color: theme.palette.secondary.main,
                    margin: "10px",
                    padding: 12,
                    borderRadius: 30,
                    background: "#0d5fab",
                    width: "320px",
                  }}
                >
                  Gruppo di auditing personalizzato
                </Button>
              </div>
            </div>
          </div>
          <div
            style={
              this.state.width < 1320
                ? {}
                : { position: "relative", left: "35%" }
            }
          >
            <Button
              type="button"
              onClick={() => {
                this.props.onClose();
              }}
              variant="contained"
              size="medium"
              style={{
                color: theme.palette.secondary.main,
                margin: "10px",
                borderRadius: 30,
                background:
                  "linear-gradient(to right, #0d5fab, #00b1b7,#0d5fab)",
              }}
            >
              Indietro
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
export default withRouter(ModalSelezioneTipoGruppo);
