import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ErrorDialog from "../../components/ErrorDialog";
import IndietroButton from "../../components/IndietroButton";
import SpinnerComponent from "../../components/SpinnerComponent";
import theme from "../../../theme.js";

import { Checkbox, IconButton } from "@material-ui/core";
import {
  getIspettori,
  updateIspettore,
} from "../../../utils/api/ispettore_api";
import { clearToken } from "../../../utils/storage";
import { FormatListBulleted } from "@material-ui/icons";
import ModalSelezioneGruppoModificaAuditor from "./ModalSelezioneGruppoModificaAuditor.js";

const styles = {
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "20px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  userdatacontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "270px",
  },
};

export default class FormModificaIspettore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /* record: {
                 id: null,
                 email: null,
                 nome: null,
                 cognome: null,
                 telefono: null,
                 activated: null,
                 userId: null,
                 validated: null,
                 dataRegistrazione: null,
                 terminiUsoAccettati: null,
                 privacyPolicyAccettata: null,
                 zonaId: null
             },*/
      record: {
        activated: true,
        cognome: "Ads",
        dataRegistrazione: "2021-05-14T13:38:01.399Z",
        email: "test@gmail.com",
        gruppoId: 0,
        id: 6304,
        nome: "Dsa",
        privacyPolicyAccettata: true,
        telefono: "1233213",
        terminiUsoAccettati: true,
        userId: 6303,
        validated: true,
        nomeGruppo: "",
      },
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: false,
      notfoundRecord: false,
      saveButtonEnabled: false,
      _isMounted: false,
      isModalSelezioneGruppoAuditorOpen: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchRecord();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  handleChange = (event) => {
    this.props.onFormModified();
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let name = event.target.name;
    let value = event.target.value;
    let data = this.state.record;
    data[name] = value;
    this.setState({
      data,
    });
  };

  // This code is a 'patch': at the moment there is not an API request
  // Using the same GET request to fetch all responsabili associated to the.
  fetchRecord = () => {
    this.setState({
      loading: true,
    });

    getIspettori(this.props.ispettoreId, 0, 1000, "id", this.props.gruppoId)
      .then((result) => {
        if (this._isMounted) {
          this.handleFetchSuccess(result);
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
            notfoundRecord: true,
          });
        }
      });
  };

  handleFetchSuccess = (result) => {
    if (result.length === 0) {
      this.setState({
        loading: false,
        notfoundRecord: true,
      });
    } else {
      let ispettore = null;
      for (let i = 0; i < result.length; i++) {
        let ispettore1 = result[i];
        if (ispettore1.id === Number.parseInt(this.props.ispettoreId, 10)) {
          ispettore = ispettore1;
          break;
        }
      }
      if (ispettore === null) {
        this.setState({
          loading: false,
          notfoundRecord: true,
        });
      } else {
        if (!this.props.ispettoreView) {
          this.setState({
            record: ispettore,
            loading: false,
          });
          //  this.fetchGruppo();
        } else {
          this.setState({
            record: ispettore,
            loading: false,
          });
        }
      }
    }
  };
  handleModalSelezioneGruppoAuditorClose = () => {
    this.setState({
      isModalSelezioneGruppoAuditorOpen: false,
    });
  };
  handleModalSelezioneGruppoAuditorCloseSaveData = (selection, selectedGroupData) => {
    let modified = this.state.saveButtonEnabled;
    let record = { ...this.state.record };
    if (selection !== undefined && selection !== null && selection.length > 0) {
      record.gruppoId = selection[0];
      if (selectedGroupData !== undefined && selectedGroupData !== null) {
        record.nomeGruppo = selectedGroupData.name;
      }
      modified = true;
    }
    this.setState({
      isModalSelezioneGruppoAuditorOpen: false,
      record: record,
      saveButtonEnabled: modified,
    });
  }
  handleOpenModalSelezioneGruppoAuditorOpen = () => {
    this.setState({
      isModalSelezioneGruppoAuditorOpen: true,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    updateIspettore(this.props.isAdmin, this.state.record)
      .then((result) => {
        this.setState({
          loading: false,
          saveButtonEnabled: false,
        });
        this.props.onFormSubmitted();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    moment.locale("it-IT");
    const dateFormat = "DD-MM-YYYY HH:mm";
    const disabledSubmitbutton =
      this.state.loading || !this.state.saveButtonEnabled;
    return (
      <Container
        id="form_container"
        style={{
          paddingLeft: "4px",
          paddingRight: "4px",
          maxWidth: "700px",
          minHeight: "500px",
        }}
      >
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : this.state.notfoundRecord ? (
          <Typography
            variant="h6"
            style={{ paddingTop: "6px", paddingBottom: "6px" }}
          >
            Nessun record trovato
          </Typography>
        ) : (
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={styles.sectioncontainer}
              >
                <Typography
                  variant="h5"
                  style={{ paddingTop: "6px", paddingBottom: "6px" }}
                >
                  Dati registrazione auditor
                </Typography>
                <Grid container style={styles.userdatacontainer}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <TextField
                        label="ID ispettore"
                        disabled
                        value={
                          this.state.record.id === null
                            ? ""
                            : this.state.record.id
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        style={styles.textfield}
                      />
                      <TextField
                        label="Email"
                        disabled
                        value={
                          this.state.record.email === null
                            ? ""
                            : this.state.record.email
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        style={styles.textfield}
                      />
                      {!this.props.isAdmin ? (
                        <TextField
                          label="Superauditor"
                          disabled
                          value={
                            this.state.record.superauditor === null
                              ? ""
                              : this.state.record.superauditor === true
                              ? "Si"
                              : "No"
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          style={styles.textfield}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <TextField
                        label="Data registrazione"
                        disabled
                        value={
                          this.state.record.dataRegistrazione === null
                            ? ""
                            : moment(
                                this.state.record.dataRegistrazione
                              ).format(dateFormat)
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        style={styles.textfield}
                      />

                      {!this.props.isAdmin ? (
                        <TextField
                          label="Gruppo di auditing di appartenenza"
                          disabled
                          value={this.state.record.nomeGruppo}
                          style={styles.textfield}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={styles.sectioncontainer}
              >
                <Typography
                  variant="h5"
                  style={{ paddingTop: "6px", paddingBottom: "6px" }}
                >
                  Dati auditor
                </Typography>
                <Grid container style={styles.userdatacontainer}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container direction="column" alignItems="center">
                      <TextField
                        label="Nome"
                        value={
                          this.state.record.nome === null
                            ? ""
                            : this.state.record.nome
                        }
                        name="nome"
                        onChange={this.handleChange}
                        style={styles.textfield}
                        disabled={
                          !(
                            this.props.isAdmin ||
                            localStorage.getItem("superAuditor") === "true"
                          )
                        }
                      />
                      <TextField
                        label="Cognome"
                        value={
                          this.state.record.cognome === null
                            ? ""
                            : this.state.record.cognome
                        }
                        name="cognome"
                        onChange={this.handleChange}
                        style={styles.textfield}
                        disabled={
                          !(
                            this.props.isAdmin ||
                            localStorage.getItem("superAuditor") === "true"
                          )
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container direction="column" alignItems="center">
                      <TextField
                        label="Telefono"
                        value={
                          this.state.record.telefono === null
                            ? ""
                            : this.state.record.telefono
                        }
                        name="telefono"
                        onChange={this.handleChange}
                        style={styles.textfield}
                        disabled={
                          !(
                            this.props.isAdmin ||
                            localStorage.getItem("superAuditor") === "true"
                          )
                        }
                      />
                      {this.props.isAdmin ? (
                        <TextField
                          label="Gruppo di auditing di appartenenza"
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <IconButton
                                onClick={() => {
                                  this.handleOpenModalSelezioneGruppoAuditorOpen();
                                }}
                                size="small"
                              >
                                <FormatListBulleted
                                  style={{
                                    color: theme.palette.auditPalette.secondary,
                                  }}
                                />
                              </IconButton>
                            ),
                          }}
                          value={this.state.record.nomeGruppo}
                          style={styles.textfield}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>

                {this.props.isAdmin ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Checkbox
                      checked={this.state.record.superauditor}
                      style={{
                        color: theme.palette.auditPalette.primary,

                        "&.MaterialUiChecked": {
                          color: theme.palette.auditPalette.primary,
                        },
                      }}
                      onChange={() => {
                        let record = this.state.record;
                        record.superauditor = !record.superauditor;
                        this.setState({
                          record,
                          saveButtonEnabled: true,
                        });
                      }}
                    ></Checkbox>
                    <Typography style={{ margin: 10 }}>
                      Selezionare la casella per assegnare il ruolo di
                      superauditor
                    </Typography>
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              style={{ paddingTop: "10px", paddingBottom: "20px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ textAlign: "right" }}
              >
                <IndietroButton
                  isDisabled={this.state.loading}
                  style={{
                    borderRadius: 30,
                    color: "white",
                    background:
                      "linear-gradient(to right, #0d5fab, #00b1b7,#0d5fab)",
                  }}
                />
                {this.props.isAdmin ||
                localStorage.getItem("superAuditor") === "true" ? (
                  <Button
                    type="button"
                    onClick={this.handleSubmit}
                    variant="contained"
                    size="medium"
                    disabled={disabledSubmitbutton}
                    style={{
                      color: theme.palette.secondary.main,
                      margin: "10px",
                      borderRadius: 30,
                      background: disabledSubmitbutton
                        ? theme.palette.disabled.main
                        : "linear-gradient(to right, #00b1b7,#0d5fab,#00b1b7)",
                    }}
                  >
                    Salva
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </form>
        )}
        {this.state.isModalSelezioneGruppoAuditorOpen && (
          <ModalSelezioneGruppoModificaAuditor
            open={this.state.isModalSelezioneGruppoAuditorOpen}
            onClose={this.handleModalSelezioneGruppoAuditorClose}
            saveData={this.handleModalSelezioneGruppoAuditorCloseSaveData}
            selectedGroup={
              this.state.record.gruppoId === null
                ? null
                : [this.state.record.gruppoId]
            }
          ></ModalSelezioneGruppoModificaAuditor>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        ></ErrorDialog>
      </Container>
    );
  }
}

FormModificaIspettore.propTypes = {
  //   ispettoreId: PropTypes.number,
  ispettoreView: PropTypes.bool,
  onFormModified: PropTypes.func.isRequired,
  onFormSubmitted: PropTypes.func.isRequired,
};
