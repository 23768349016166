import React, { Component } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import PropTypes from "prop-types";
import ActionButton from "./ActionButton";

const styles = {
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "360px",
  },
};

export default class ResetPswForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        password: "",
        confirmPsw: "",
      },
      validationErrors: {
        password: "",
        confirmPsw: "",
      },
      showPassword1: false,
      showPassword2: false,
    };
  }

  handleClickShowPassword1 = () => {
    let flag = !this.state.showPassword1;
    this.setState({
      showPassword1: flag,
    });
  };
  handleClickShowPassword2 = () => {
    let flag = !this.state.showPassword2;
    this.setState({
      showPassword2: flag,
    });
  };

  validateForm = () => {
    //Validate every property:
    let data = this.state.data;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
  };

  isFormValid = () => {
    let valid = true;
    let errorMessages = this.state.validationErrors;
    Object.values(errorMessages).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let insertPsw = this.state.data.password;
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "password":
        if (value.length >= 8) {
          validationErrors.password = "";
        } else {
          validationErrors.password =
            "Inserire una password di almeno 8 caratteri";
        }
        break;
      case "confirmPsw":
        if (value.length === 0) {
          validationErrors.confirmPsw = "Ripetere la password";
        } else {
          if (value === insertPsw) {
            validationErrors.confirmPsw = "";
          } else {
            validationErrors.confirmPsw = "Le due password non corrispondono";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.validateForm();
    if (this.isFormValid()) {
      this.props.onSubmit(this.state.data.password);
    }
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let validationErrors = this.state.validationErrors;
    let data = this.state.data;
    data[name] = value;
    validationErrors[name] = "";
    this.setState({
      data,
      validationErrors,
    });
    this.props.onChange();
  };

  render() {
    return (
      <form autoComplete="off" onSubmit={this.handleSubmit}>
        <Grid container spacing={2} style={{ padding: "10px 0px 0px 0px" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              label="Nuova password"
              placeholder=""
              value={this.state.data.password}
              name="password"
              onChange={this.handleChange}
              variant="outlined"
              style={styles.textfield}
              disabled={this.props.disabled}
              helperText={this.state.validationErrors.password}
              error={
                this.state.validationErrors.password.length > 0 ? true : false
              }
              type={this.state.showPassword1 ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Mostra password">
                      <IconButton
                        label="Mostra password"
                        onClick={this.handleClickShowPassword1}
                      >
                        {this.state.showPassword1 ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              label="Digita nuovamente la nuova password"
              placeholder=""
              value={this.state.data.confirmPsw}
              name="confirmPsw"
              onChange={this.handleChange}
              variant="outlined"
              style={styles.textfield}
              disabled={this.props.disabled}
              helperText={this.state.validationErrors.confirmPsw}
              error={
                this.state.validationErrors.confirmPsw.length > 0 ? true : false
              }
              type={this.state.showPassword2 ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Mostra password">
                      <IconButton
                        label="Mostra password"
                        onClick={this.handleClickShowPassword2}
                      >
                        {this.state.showPassword2 ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Container style={{ textAlign: "center" }}>
          <ActionButton
            label="Indietro"
            onClick={this.handleCancel}
            disabled={false}
            grayversion={"true"}
          />
          <ActionButton
            label="Salva password"
            onClick={this.handleSubmit}
            disabled={this.props.disabled}
            grayversion={false}
          />
        </Container>
      </form>
    );
  }
}

ResetPswForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
