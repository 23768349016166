import React from "react";
// import { useRouteMatch, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import ListIcon from "@material-ui/icons/List";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";

import theme from "../../theme.js";

export default function EditRecordButton(props) {
  // let history = useHistory();
  // const match = useRouteMatch();

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <IconButton
      size="small"
      disabled={props.disabled}
      style={{
        color: props.disabled
          ? theme.palette.disabled.main
          : theme.palette.primary.main,
      }}
      onClick={handleClick}
      // onClick={() => {
      //   history.push(`${match.url}/${props.recordId}`);
      // }}
      edittype={props.editType}
    >
      {props.editType === "gruppi" ? (
        <ListIcon />
      ) : props.editType === "visualizza" ? (
        <SearchIcon />
      ) : props.editType === "ispeziona" ? (
        <OpenInNewIcon />
      ) : (
        <SettingsIcon />
      )}
    </IconButton>
  );
}

EditRecordButton.propTypes = {
  edittype: PropTypes.string,
  // recordId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};
