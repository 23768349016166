import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

const BasicTableForModifica = ({ data, columns, defaultPageSize, style }) => {
  return (
    <div>
      <ReactTable
        data={data}
        columns={columns}
        sortable={false}
        filterable
        resizable
        previousText={'Precedente'}
        nextText={'Successivo'}
        loadingText={'Caricamento...'}
        pageText={'Pagina'}
        ofText={'di'}
        defaultPageSize={10}
        style={style}
        defaultFilterMethod={(filter, row, _column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
            : true;
        }}
        showPageSizeOptions={false}
        noDataText="Nessuna riga trovata"
      />
    </div>
  );
};

export default BasicTableForModifica;
