import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search, Sync } from "@material-ui/icons";

import React, { Component } from "react";
// React-table library requires to import css as well:
import "react-table/react-table.css"; // eslint-disable-next-line
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import ErrorDialog from "../../components/ErrorDialog";
import SpinnerComponent from "../../components/SpinnerComponent";
import EditRecordButton from "../../components/EditRecordButton";
import BasicTable from "../../components/BasicTable";
import GruppiAuditingModal from "../../components/GruppiAuditingModal";
import {
  getEserciziCommerciali,
  getEserciziCommercialiCount,
  getSyncEserciziCommerciali,
} from "../../../utils/api/esercizi_commerciali_api";
import { clearToken } from "../../../utils/storage.js";
import theme from "../../../theme";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
  syncDate: {
    display: "flex",
    paddingTop: "20px",
    paddingBottom: "20px",
    justifyContent: "right",
    alignItems: " center",
  },
};

class EserciziCommerciali extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      columns: [],
      loading: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      totalCount: 1,
      loadedPage: 0,
      totalPages: 1,
      pageSize: 10,
      esercizioId: "",
      esercizioNome: "",
      ragioneSociale: "",
      provinciaSede: "",
      cittaSede: "",
      indirizzoSede: "",
      isGruppiModalOpen: false,
      dataSincronizzazione: "",
      selectedEsercizioId: "",
    };
  }

  componentDidMount() {
    this.setState({
      columns: this.getColumns(),
    });
    this.fetchCount();
    this.fetchRecords(this.state.loadedPage);
    this.syncEserciziCommerciali();
  }

  syncEserciziCommerciali = async () => {
    const result = await getSyncEserciziCommerciali();
    this.setState({ dataSincronizzazione: result.dataTermine });
  };

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  fetchCount = async () => {
    const result = await getEserciziCommercialiCount(this.props.gruppoId);
    this.setState({
      totalCount: result,
    });
  };

  fetchRecords = async (pageIndex) => {
    // setTimeout(() => {
    //   this.setState({ loading: true });
    // }, 100);
    try {
      const { ispettoreId, gruppoId } = this.props;

      const result = await getEserciziCommerciali(
        ispettoreId,
        pageIndex,
        this.state.pageSize,
        "id",
        gruppoId,
        this.state.esercizioId,
        this.state.esercizioNome,
        this.state.ragioneSociale,
        this.state.provinciaSede,
        this.state.cittaSede,
        this.state.indirizzoSede
      );
      this.setState({
        records: result,
        totalPages: Math.ceil(this.state.totalCount / this.state.pageSize),
        loading: false,
      });
    } catch (error) {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
        });
        this.handleError(true, error.message);
      }
    }
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  handleChangeEsercizioId = (e) => {
    if (/^[0-9]*$/.test(e.target.value)) {
      this.setState({ esercizioId: e.target.value });
    }
  };

  handleApplyFilterClick = () => {
    this.setState({ loadedPage: 0 });
    this.fetchRecords(0);
  };

  getColumns = () => {
    const isAdmin = this.props.ispettoreId === null;

    const columns = [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        minWidth: 150,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.esercizioId}
            onChange={this.handleChangeEsercizioId}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={this.handleApplyFilterClick}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Nome esercizio",
        id: "nome",
        accessor: (d) => d.nome,
        minWidth: 180,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.esercizioNome}
            onChange={(e) => {
              this.setState({
                esercizioNome: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={this.handleApplyFilterClick}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Ragione sociale",
        id: "ragioneSociale",
        accessor: (d) => d.ragioneSociale,
        minWidth: 180,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.ragioneSociale}
            onChange={(e) => {
              this.setState({
                ragioneSociale: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={this.handleApplyFilterClick}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Provincia",
        id: "provinciaSede",
        accessor: (d) => d.provinciaSede,
        minWidth: 180,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.provinciaSede}
            onChange={(e) => {
              this.setState({
                provinciaSede: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={this.handleApplyFilterClick}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Città",
        id: "cittaSede",
        accessor: (d) => d.cittaSede,
        minWidth: 180,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.cittaSede}
            onChange={(e) => {
              this.setState({
                cittaSede: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={this.handleApplyFilterClick}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Indirizzo",
        id: "indirizzoSede",
        accessor: (d) => d.indirizzoSede,
        width: 180,
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.indirizzoSede}
            onChange={(e) => {
              this.setState({
                indirizzoSede: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={this.handleApplyFilterClick}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Gruppo di auditing",
        id: "Gruppo",
        accessor: (row) => row,
        filterable: false,
        minWidth: 150,
        Cell: (props) => (
          <EditRecordButton
            editType="gruppi"
            onClick={() => {
              this.handleGruppiModalOpen(props.value.id);
            }}
            disabled={false}
          />
        ),
      },
      {
        id: "visualizza",
        Header: "Visualizza",
        accessor: (row) => row,
        sortable: false,
        filterable: false,
        minWidth: 100,
        Cell: (props) => (
          <EditRecordButton
            editType="visualizza"
            onClick={() => this.handleRedirect(props.value.id)}
            disabled={false}
          />
        ),
      },
      {
        id: "ispeziona",
        Header: "Ispeziona",
        accessor: (row) => row,
        sortable: false,
        filterable: false,
        minWidth: 100,
        Cell: (props) => (
          <EditRecordButton
            editType="ispeziona"
            onClick={() => {
              this.handleRedirectToIspeziona(props.value);
            }}
            disabled={false}
          />
        ),
      },
    ];

    if (!isAdmin) {
      columns.splice(6, 1);
    }
    return columns;
  };

  handlePageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () => this.fetchRecords(pageIndex)
    );
  };

  handleGruppiModalOpen = (id) => {
    this.setState({
      selectedEsercizioId: id,
      isGruppiModalOpen: true,
    });
  };

  handleGruppiModalClose = () => {
    this.setState({
      isGruppiModalOpen: false,
    });
  };

  handleRedirect = (recordId) => {
    this.props.history.push(
      `/amministrazione/esercizi_commerciali/${recordId}`
    );
  };

  handleRedirectToIspeziona = (record) => {
    this.props.history.push({
      pathname: `/amministrazione/esercizi_commerciali/${record.id}/ispezioni`,
      state: {
        id: record.id,
        nome: record.nome,
        ragioneSociale: record.ragioneSociale,
      },
    });
  };

  render() {
    const {
      columns,
      loading,
      records,
      pageSize,
      loadedPage,
      totalPages,
      errorDialogVisible,
      errorDialogMessage,
      isGruppiModalOpen,
      dataSincronizzazione,
    } = this.state;

    let dataSincLocale = moment(dataSincronizzazione);
    let orarioLocale = dataSincLocale.format("HH:mm");
    let dataLocale = dataSincLocale.format("DD.MM.YYYY");

    return (
      <div style={{ minHeight: "400px" }}>
        {loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <div style={styles.mainContainer}>
            <div style={styles.mainContainer}>
              <div style={styles.syncDate}>
                <Sync style={{ color: theme.palette.primary.main }} />
                <Typography
                  variant="subtitle2"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  Ultima sincronizzazione alle {orarioLocale} del {dataLocale}
                </Typography>
              </div>
            </div>

            <BasicTable
              data={records}
              columns={columns}
              page={loadedPage}
              defaultPageSize={pageSize}
              onPageChange={this.handlePageChange}
              pages={totalPages}
            />
          </div>
        )}
        <ErrorDialog
          open={errorDialogVisible}
          message={errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />

        {isGruppiModalOpen && (
          <GruppiAuditingModal
            open={isGruppiModalOpen}
            onClose={this.handleGruppiModalClose}
            recordId={this.state.selectedEsercizioId}
          />
        )}
      </div>
    );
  }
}

EserciziCommerciali.propTypes = {
  ispettoreId: PropTypes.number,
  gruppoId: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(EserciziCommerciali);
