import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../../../theme';

const styles = {
    container: {
        paddingBottom: '10px',
        //paddingTop: '4px',
        textAlign: 'left'
    },
    label: {
        textAlign: 'left',
    },
    textfield: {
        marginBottom: '12px',
        //width: '360px'
    },
}

export default function NumericFieldComponent(props) {
    return (
        <ThemeProvider theme={theme}>
            <Container style={styles.container}>
                {props.label.length > 0 && <Typography style={styles.label}>{props.label}</Typography> }
                <TextField
                    type="number"
                    value={props.value}
                    name={props.name}
                    onChange={props.onChange}
                    variant="outlined"
                    style={styles.textfield}
                    fullWidth
                    disabled={props.disabled}
                />
            </Container>
        </ThemeProvider>
    );
}

NumericFieldComponent.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}
