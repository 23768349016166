import {
  Box,
  Checkbox,
  FormControl,
  InputBase,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from "@material-ui/core";
import React, { Component } from "react";
import theme from "../../../theme";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    background:
      "linear-gradient(white, white) padding-box, linear-gradient(to right, #0d5fab, #00b1b7,#0d5fab) border-box;",
    borderRadius: "50em",
    border: "1px solid transparent",
    paddingTop: "10px",
  },
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
}));

class FiltroIspezione extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOpen: false,
    };
  }
  render() {
    return (
      <FormControl>
        <Box display={"flex"} justifyContent={"center"} marginY={2}>
          <Select
            value={this.props.controlliSelezionati}
            onChange={this.props.onChange}
            open={this.state.selectOpen}
            disabled={this.props.loading}
            onOpen={() => this.setState({ selectOpen: true })}
            onClose={() => {
              this.setState({ selectOpen: false });
              this.props.onClose();
            }}
            displayEmpty
            variant="outlined"
            multiple
            renderValue={(selected) => {
              return "Filtra procedure";
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              getContentAnchorEl: null,
            }}
            input={<BootstrapInput />}
            style={{
              width: "40%",
            }}
          >
            {this.props.filtroControlli.map((controllo, index) => (
              <MenuItem key={index} value={controllo}>
                <Checkbox
                  style={{
                    color: theme.palette.auditPalette.primary,
                    "&.MaterialUiChecked": {
                      color: theme.palette.auditPalette.primary,
                    },
                  }}
                  checked={this.props.controlliSelezionati.includes(controllo)}
                />
                <ListItemText  primary={controllo.titolo} style={{ fontSize: "8px" }}  />
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FormControl>
    );
  }
}

export default React.memo(FiltroIspezione);
