import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import ActionButton from "../../../components/ActionButton";
import FormWithRadioButtons from "./FormWithRadioButtons";
import FormSecondPartSezioneC from "./FormSecondPartSezioneC";

import moment from "moment";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";

import PropTypes from "prop-types";
import {
  sezione_c_part1,
  sezione_c_part2,
} from "../../../../utils/schemas/scu_sezione_c";
//import theme from '../../../../theme';

const styles = {
  root: {
    padding: "0px",
    margin: "0px",
  },
  title: {
    padding: "10px 0px",
    textAlign: "center",
  },
  paragraph: {
    //padding: '6px 0px',
    textAlign: "left",
  },
  textcontainer: {
    paddingBottom: "10px",
    paddingTop: "4px",
  },
  inputfieldcontainer: {
    paddingBottom: "10px",
    paddingTop: "4px",
    textAlign: "left",
  },
  textfield: {
    marginBottom: "12px",
    //width: '360px'
  },
  formcontrol: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
  },
  datetimeComponent: {
    padding: "4px 0px 4px 0px",
  },
  buttoncontainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 0px",
  },
};

export default class SezioneC extends Component {
  constructor(props) {
    super(props);
    const sezioneC = this.props.sezioneC;
    this.state = {
      record: {
        id: sezioneC.id,
        ispezioneId: sezioneC.ispezioneId,
        dataUltimaModifica: sezioneC.dataUltimaModifica,
        nonConformitaPresenti:
          sezioneC.nonConformitaPresenti === null
            ? ""
            : sezioneC.nonConformitaPresenti.toString(),
        voceSelezionata:
          sezioneC.voceSelezionata === null
            ? ""
            : sezioneC.voceSelezionata.toString(),
        sospensioneAttivitaDi:
          sezioneC.sospensioneAttivitaDi === null
            ? ""
            : sezioneC.sospensioneAttivitaDi,
        sospensioneAttivitaOptScelta:
          sezioneC.sospensioneAttivitaOptScelta === null
            ? ""
            : sezioneC.sospensioneAttivitaOptScelta.toString(),
        attoSeparatoNumero:
          sezioneC.attoSeparatoNumero === null
            ? ""
            : sezioneC.attoSeparatoNumero,
        attoSeparatoDel:
          sezioneC.attoSeparatoDel === null
            ? moment()
            : moment(sezioneC.attoSeparatoDel),
        verbaleSequestroNumero:
          sezioneC.verbaleSequestroNumero === null
            ? ""
            : sezioneC.verbaleSequestroNumero,
        contestazioneInRifA:
          sezioneC.contestazioneInRifA === null
            ? ""
            : sezioneC.contestazioneInRifA,
        autorita: sezioneC.autorita === null ? "" : sezioneC.autorita,
        altro: sezioneC.altro === null ? "" : sezioneC.altro,
        documentiEInfo:
          sezioneC.documentiEInfo === null ? "" : sezioneC.documentiEInfo,
        signor: sezioneC.signor === null ? "" : sezioneC.signor,
        dichiarazioni:
          sezioneC.dichiarazioni === null ? "" : sezioneC.dichiarazioni,
        noteOsservazioni:
          sezioneC.noteOsservazioni === null ? "" : sezioneC.noteOsservazioni,
        importoDaPagare:
          sezioneC.importoDaPagare === null
            ? 0.0
            : parseFloat(sezioneC.importoDaPagare),
        numeroFaxPagamento:
          sezioneC.numeroFaxPagamento === null
            ? ""
            : sezioneC.numeroFaxPagamento,
        numeroPagine:
          sezioneC.numeroPagine === null ? 0.0 : sezioneC.numeroPagine,
        sezioniSelezionate:
          sezioneC.sezioniSelezionate === null
            ? []
            : sezioneC.sezioniSelezionate.split(","),
        luogoCompilazione:
          sezioneC.luogoCompilazione === null ? "" : sezioneC.luogoCompilazione,
        dataCompilazione:
          sezioneC.dataCompilazione === null
            ? moment()
            : moment(sezioneC.dataCompilazione),
        orarioCompilazione:
          sezioneC.orarioCompilazione === null
            ? moment()
            : moment(sezioneC.orarioCompilazione, "H:mm:ss"),
      },
      sezioni: [
        {
          name: "A",
          value: "A",
        },
        {
          name: "B",
          value: "B",
        },
        {
          name: "C",
          value: "C",
        },
        {
          name: "D",
          value: "D",
        },
        {
          name: "E",
          value: "E",
        },
      ],
      disableFormSecondPart: false,
      saveButtonEnabled: false,
    };
  }

  onChangeSelection = (fieldName, event) => {
    let record = this.state.record;
    record[fieldName] = event.target.value;
    if (fieldName === "voceSelezionata" && event.target.value !== 4) {
      record.sospensioneAttivitaDi = "";
      record.sospensioneAttivitaOptScelta = "";
      record.attoSeparatoNumero = "";
      record.attoSeparatoDel = moment();
    }
    if (fieldName === "voceSelezionata" && event.target.value !== 5) {
      record.verbaleSequestroNumero = "";
    }
    if (fieldName === "voceSelezionata" && event.target.value !== 6) {
      record.contestazioneInRifA = "";
    }
    if (fieldName === "voceSelezionata" && event.target.value !== 7) {
      record.autorita = "";
    }
    if (fieldName === "voceSelezionata" && event.target.value !== 9) {
      record.altro = "";
    }
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  onChangeText = (event) => {
    let record = this.state.record;
    record[event.target.name] = event.target.value;
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  onChangeDate = (fieldName, date) => {
    let record = this.state.record;
    record[fieldName] = moment(date);
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  updateDate = (date) => {
    let record = this.state.record;
    record.dataCompilazione = moment(date);
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  updateTime = (date) => {
    let record = this.state.record;
    record.orarioCompilazione = moment(date);
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  handleSectionSelection = (e) => {
    let record = this.state.record;
    let value = null;
    for (let i = 0; i < this.state.sezioni.length; i++) {
      if (e.target.name === this.state.sezioni[i].name) {
        value = this.state.sezioni[i].value;
        break;
      }
    }
    if (e.target.checked) {
      record.sezioniSelezionate.push(value);
    } else {
      let newarray = record.sezioniSelezionate.filter(function (el) {
        return el !== value;
      });
      record.sezioniSelezionate = newarray;
    }
    this.setState({
      record,
      saveButtonEnabled: true,
    });
    this.props.onChangesNotSaved();
  };

  handleSubmit = () => {
    let data = Object.assign({}, this.state.record);
    data.nonConformitaPresenti =
      data.nonConformitaPresenti === ""
        ? null
        : this.state.record.nonConformitaPresenti === "true";
    data.voceSelezionata =
      data.voceSelezionata === "" ? null : parseInt(data.voceSelezionata);
    data.sospensioneAttivitaDi =
      data.sospensioneAttivitaDi === "" ? null : data.sospensioneAttivitaDi;
    data.sospensioneAttivitaOptScelta =
      data.sospensioneAttivitaOptScelta === ""
        ? null
        : parseInt(data.sospensioneAttivitaOptScelta);
    if (data.sospensioneAttivitaOptScelta === null) {
      data.attoSeparatoNumero = null;
      data.attoSeparatoDel = null;
    } else {
      data.attoSeparatoNumero =
        data.attoSeparatoNumero === "" ? null : data.attoSeparatoNumero;
      data.attoSeparatoDel = moment.utc(data.attoSeparatoDel).format();
    }
    data.verbaleSequestroNumero =
      data.verbaleSequestroNumero === "" ? null : data.verbaleSequestroNumero;
    data.contestazioneInRifA =
      data.contestazioneInRifA === "" ? null : data.contestazioneInRifA;
    data.autorita = data.autorita === "" ? null : data.autorita;
    data.altro = data.altro === "" ? null : data.altro;
    data.documentiEInfo =
      data.documentiEInfo === "" ? null : data.documentiEInfo;
    data.signor = data.signor === "" ? null : data.signor;
    data.dichiarazioni = data.dichiarazioni === "" ? null : data.dichiarazioni;
    data.noteOsservazioni =
      data.noteOsservazioni === "" ? null : data.noteOsservazioni;
    data.importoDaPagare =
      data.importoDaPagare === "" ? null : parseFloat(data.importoDaPagare);
    data.numeroFaxPagamento =
      data.numeroFaxPagamento === "" ? null : data.numeroFaxPagamento;
    data.numeroPagine =
      data.numeroPagine === "" ? null : parseInt(data.numeroPagine);
    data.sezioniSelezionate = data.sezioniSelezionate.join();
    data.luogoCompilazione =
      data.luogoCompilazione === "" ? null : data.luogoCompilazione;
    data.dataCompilazione = data.dataCompilazione.format("YYYY-MM-DD");
    data.orarioCompilazione = data.orarioCompilazione.format("HH:mm:ss");
    this.props.onSubmit(data);
  };

  render() {
    return (
      <Container style={styles.root}>
        <Typography style={styles.title} variant="h5">
          Sezione C
        </Typography>
        <Typography style={styles.title} variant="h6">
          Esiti del controllo - Eventuali provvedimenti conseguenti
        </Typography>
        <FormWithRadioButtons
          row={false}
          name={"nonconf-radio-buttons-group"}
          label={sezione_c_part1.label}
          options={sezione_c_part1.options}
          value={this.state.record.nonConformitaPresenti}
          fieldName={"nonConformitaPresenti"}
          disabled={false}
          onChange={this.onChangeSelection}
        />
        <FormSecondPartSezioneC
          name={"secondpart-radio-buttons-group"}
          label={sezione_c_part2.label}
          options={sezione_c_part2.options}
          voceSelezionata={this.state.record.voceSelezionata}
          sospensioneAttivitaDi={this.state.record.sospensioneAttivitaDi}
          optionsSospensioneAttivita={
            sezione_c_part2.optionsSospensioneAttivita
          }
          sospensioneAttivitaOptScelta={
            this.state.record.sospensioneAttivitaOptScelta
          }
          attoSeparatoNumero={this.state.record.attoSeparatoNumero}
          attoSeparatoDel={this.state.record.attoSeparatoDel}
          record={this.state.record}
          disabled={this.state.disableFormSecondPart}
          onOptionSelected={this.onChangeSelection}
          onTextChanged={this.onChangeText}
          onDateChanged={this.onChangeDate}
        />
        <Container style={styles.textcontainer}>
          <Typography style={styles.paragraph} variant="body1">
            {
              "Ai sensi dell'art. 54 del reg. CE 882/04, il Responsabile della Struttura Organizzativa potrà trasmettere eventuali prescrizioni relative a quanto segnalato sotto la voce di “Rimesse a valutazioni successive” ovvero ulteriori provvedimenti per motivi di Sicurezza Alimentare."
            }
          </Typography>
        </Container>
        <Container style={styles.inputfieldcontainer}>
          <Typography style={styles.paragraph}>
            {
              "Nel corso delle attività sono stati acquisiti i seguenti documenti e/o informazioni:"
            }
          </Typography>
          <TextField
            placeholder=""
            value={this.state.record.documentiEInfo}
            name="documentiEInfo"
            onChange={this.onChangeText}
            variant="outlined"
            style={styles.textfield}
            fullWidth
            multiline
            rows={4}
            disabled={false}
          />
        </Container>
        <Container style={styles.textcontainer}>
          <Typography style={styles.paragraph} variant="body1">
            {"SI AVVERTE: L'Operatore del Settore Alimentare può far pervenire entro il termine prescritto per la risoluzione delle non conformità/inadeguatezze alla Struttura Organizzativa in intestazione, comunicazione e/o quant’altro nel merito ritenuto opportuno, circa la risoluzione delle criticità, compresa la richiesta, motivata, di un eventuale proroga dei tempi di risoluzione, nonché prendere visione degli atti relativi al procedimento in oggetto." +
              "Avverso al presente provvedimento può essere presentato ricorso al T.A.R. della Toscana entro 60 giorni dalla sua notifica (L. 1034/71) oppure ricorso straordinario al Presidente della Repubblica entro 120 giorni dalla notifica (D.P.R. 1199/71)"}
          </Typography>
        </Container>
        <Container style={styles.inputfieldcontainer}>
          <Typography style={styles.paragraph}>{"Il Sig."}</Typography>
          <TextField
            placeholder=""
            value={this.state.record.signor}
            name="signor"
            onChange={this.onChangeText}
            variant="outlined"
            style={styles.textfield}
            fullWidth
            disabled={false}
          />
          <Typography style={styles.paragraph}>
            {
              "presente al sopralluogo trattiene una copia del presente atto letto e sottoscritto, rilasciando spontaneamente le seguenti dichiarazioni:"
            }
          </Typography>
          <TextField
            placeholder=""
            value={this.state.record.dichiarazioni}
            name="dichiarazioni"
            onChange={this.onChangeText}
            variant="outlined"
            style={styles.textfield}
            fullWidth
            multiline
            rows={4}
            disabled={false}
          />
        </Container>
        <Container style={styles.inputfieldcontainer}>
          <Typography style={styles.paragraph}>
            {"Note/osservazioni"}
          </Typography>
          <TextField
            placeholder=""
            value={this.state.record.noteOsservazioni}
            name="noteOsservazioni"
            onChange={this.onChangeText}
            variant="outlined"
            style={styles.textfield}
            fullWidth
            multiline
            rows={4}
            disabled={false}
          />
        </Container>
        <Container style={styles.inputfieldcontainer}>
          <Typography style={styles.paragraph}>
            {
              "L'importo da pagare ai sensi art.4 del D.Lgs. 19/11/08 n.194 è di €"
            }
          </Typography>
          <TextField
            type="number"
            value={this.state.record.importoDaPagare}
            name="importoDaPagare"
            onChange={this.onChangeText}
            variant="outlined"
            style={styles.textfield}
            fullWidth
            disabled={false}
          />
        </Container>
        <Container style={styles.inputfieldcontainer}>
          <Typography style={styles.paragraph}>
            {
              "Copia dell'avvenuto pagamento dovrà essere trasmesso, entro 30 giorni, anche via fax, al numero:"
            }
          </Typography>
          <TextField
            placeholder=""
            value={this.state.record.numeroFaxPagamento}
            name="numeroFaxPagamento"
            onChange={this.onChangeText}
            variant="outlined"
            style={styles.textfield}
            fullWidth
            disabled={false}
          />
          <Typography style={styles.paragraph} variant="body1">
            {
              "In caso di mancata dimostrazione del pagamento si attiverà d'ufficio la procedura per il recupero dei relativi crediti."
            }
          </Typography>
        </Container>
        <Container style={styles.inputfieldcontainer}>
          <Typography style={styles.paragraph}>
            {"La presente scheda è composta da n° "}
          </Typography>
          <TextField
            type="number"
            value={this.state.record.numeroPagine}
            name="numeroPagine"
            onChange={this.onChangeText}
            variant="outlined"
            style={styles.textfield}
            fullWidth
            disabled={false}
          />
          <Typography style={styles.paragraph}>
            {
              "pagine e dalle sezioni (indicare in numero delle sez. compilate):"
            }
          </Typography>
          <FormControl style={styles.formcontrol}>
            <FormGroup row>
              {this.state.sezioni.map((sezione, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={this.state.record.sezioniSelezionate.includes(
                        sezione.value
                      )}
                      onChange={this.handleSectionSelection}
                      name={sezione.name}
                    />
                  }
                  label={sezione.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Container>
        <Container style={styles.inputfieldcontainer}>
          <Typography style={styles.paragraph}>{"Luogo:"}</Typography>
          <TextField
            value={this.state.record.luogoCompilazione}
            name="luogoCompilazione"
            onChange={this.onChangeText}
            variant="outlined"
            style={styles.textfield}
            fullWidth
            disabled={false}
          />
        </Container>
        <Container style={styles.inputfieldcontainer}>
          <Typography style={styles.paragraph}>{"Data:"}</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <DatePicker
              value={this.state.record.dataCompilazione}
              onChange={this.updateDate}
              format="dd-MM-yyyy"
              disabled={false}
              style={styles.datetimeComponent}
            />
          </MuiPickersUtilsProvider>
        </Container>
        <Container style={styles.inputfieldcontainer}>
          <Typography style={styles.paragraph}>{"Ora:"}</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <TimePicker
              value={this.state.record.orarioCompilazione}
              ampm={false}
              format="HH:mm"
              onChange={this.updateTime}
              disabled={false}
              style={styles.datetimeComponent}
            />
          </MuiPickersUtilsProvider>
        </Container>
        <div style={styles.buttoncontainer}>
          <ActionButton
            label="Salva sezione C"
            disabled={!this.state.saveButtonEnabled}
            grayversion={"false"}
            onClick={this.handleSubmit}
          />
        </div>
      </Container>
    );
  }
}

SezioneC.propTypes = {
  sezioneC: PropTypes.object.isRequired,
  onChangesNotSaved: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
