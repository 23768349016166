import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import theme from "../../../theme";
import IndietroButton from "../../components/IndietroButton";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import {
  downloadPDFAllegati,
  downloadPDFControlliForProcedure,
  downloadPDFManuale,
  downloadPDFProdotti,
  downloadPDFSchede,
  getProcedureAutocontrollo,
  getSchede,
  getStatoFile,
} from "../../../utils/api/ispezione_api";
import "../../../App.css";
import IspezioneTab from "../interfacciaIspezioni/tab-ispezione";
import FiltroIspezione from "./tab-filter-ispezione";
import ErrorDialog from "../../components/ErrorDialog";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
    minHeight: "80vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: 50,
    overflow: "scroll",
  },
  headerContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  bodyContainer: {
    flex: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: 10,
  },
  fontPresentation: {
    fontSize: "2em",
    fontWeight: "bold",
  },
  fontPresentationSubtitle: {
    fontSize: "1.5em",
  },
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          style={{
            minWidth: "70vw",
            minHeight: "76vh",
            display: "flex",
            flexDirection: "column",
            border: "1px solid #d0d0d0",
            borderRadius: 30,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
class DettaglioRevisione extends React.Component {
  intervalId = 0;
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      controlliSelezionati: [],
      schedeSelezionati: [],
      isDocumentFull: false,
      loading: true,
      pdfResponse: null,
      pageNumber: 1,
      numPages: null,
      procedure: [],
      schede: [],
      filtroControlli: [],
      filtroSchede: [],
      selectOpen: false,
      errorLoading: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      readyToDownload:false,
    };
  }

  componentDidMount() {
    this.fetchProcedureAutocontrollo();
    this.fetchSchede();
    this.setState({
      pdfResponse: null,
      loading: false,
    });
    //this.downloadPDFManualeHandler();
  }

  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  checkStatus = (esercizioId,revisione,interval) => {
    if(!this.state.readyToDownload){
    getStatoFile(esercizioId,revisione).then((result) => {
      if(result.stato === "DONE"){
        this.setState({
          readyToDownload:true,
        })
      }
    }).catch((error) => {
      this.setState({
        pdfloading: false,
        errorDialogVisible: true,
        errorDialogMessage:
          "Si è verificato un errore, operazione non riuscita",
        loading: false,
        errorLoading: true,
      },() => {
        if(!this.state.readyToDownload){
        clearInterval(this.intervalId);
        }
      });
    })
    }
  }

  downloadPDFManualeHandler = () => {
    const esercizioId = this.props.location.state.esercizioSelected;
    const revisioneId = this.props.location.state.revisioneSelected;
    this.setState({
      loading: true,
      readyToDownload:false,
    });
    getStatoFile(esercizioId,revisioneId).then((result) => {
      if(result.stato === "PROGRESS"){
        let counter = 0;
        this.intervalId = setInterval(() => {
          if(counter < 40){
            this.checkStatus(esercizioId,revisioneId,this.intervalId);
            counter++;
          }
          else{
            clearInterval(this.intervalId);
            this.setState({
              pdfloading:false,
              errorDialogVisible: true,
              errorDialogMessage:
                "Si è verificato un errore, operazione non riuscita",
              loading: false,
              errorLoading: true,
            })
          }
          if(this.state.readyToDownload){
            clearInterval(this.intervalId);
            this.setState({
              pdfloading:false,
              //readyToDownload:false,
            },() => {
              downloadPDFManuale(revisioneId)
              .then(async (response) => {
                let file = URL.createObjectURL(await response.blob());
                this.setState({
                  pdfResponse: file,
                  loading: false,
                });
              })
              .catch((error) => {
                this.setState({
                  errorDialogVisible: true,
                  errorDialogMessage:
                    "Si è verificato un errore, operazione non riuscita",
                  loading: false,
                  errorLoading: true,
                });
              });
            })
          }
        },3000)
      }else{
        this.setState({
          pdfloading:false,
        },() => {
          downloadPDFManuale(this.props.location.state.revisioneSelected)
          .then(async (response) => {
            let file = URL.createObjectURL(await response.blob());
            this.setState({
              pdfResponse: file,
              loading: false,
            });
          })
          .catch((error) => {
            this.setState({
              errorDialogVisible: true,
              errorDialogMessage:
                "Si è verificato un errore, operazione non riuscita",
              loading: false,
              errorLoading: true,
            });
          });
        })
      }
    }).catch((error) => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          errorDialogVisible: true,
          errorDialogMessage:
            "Si è verificato un errore, operazione non riuscita",
          loading: false,
          errorLoading: true,
        });
      }
    })
  }

  fetchProcedureAutocontrollo = () => {
    const revisioneId = this.props.location.state.revisioneSelected;
    getProcedureAutocontrollo(revisioneId)
      .then((result) => {
        const data = result.procedure.map((item) => item.id);
        this.setState({
          procedure: data,
          filtroControlli: result.procedure,
        });
      })
      .catch((error) => {
        this.setState({
          errorDialogVisible: true,
          errorDialogMessage:
            "Si è verificato un errore, operazione non riuscita",
          loading: false,
        });
      });
  };

  fetchSchede = () => {
    const esercizioId = this.props.location.state.esercizioSelected;
    const revisioneId = this.props.location.state.revisioneSelected;
    getSchede(esercizioId, revisioneId)
      .then((result) => {
        const data = result.map((item) => item.id);
        this.setState({
          schede: data,
          filtroSchede: result,
        });
      })
      .catch((error) => {
        this.setState({
          errorDialogVisible: true,
          errorDialogMessage:
            "Si è verificato un errore, operazione non riuscita",
          loading: false,
        });
      });
  };

  handleChange = (event, newValue) => {
    if (this.state.loading) {
      alert("Attendere che il caricamento del pdf sia terminato.")
      return;
    }
    const esercizioId = this.props.location.state.esercizioSelected;
    const moment = require("moment");
    const dataInizio = moment(this.props.location.state.dataInizio).format(
      "YYYY-MM-DD"
    );
    const dataFine = moment(this.props.location.state.dataFine).format(
      "YYYY-MM-DD"
    );
    this.setState({
      tabValue: newValue,
      controlliSelezionati: [],
      schedeSelezionati: [],
      pdfResponse: null,
      pageNumber: 1,
      loading: true,
      errorLoading: false,
    });
    if (newValue === 0) {
      this.downloadPDFManualeHandler();
      /*downloadPDFManuale(revisioneId)
        .then(async (response) => {
          let file = URL.createObjectURL(await response.blob());
          this.setState({
            pdfResponse: file,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            errorDialogVisible: true,
            errorDialogMessage:
              "Si è verificato un errore, operazione non riuscita",
            loading: false,
            errorLoading: true,
          });
        });*/
    }
    if (newValue === 1) {
      this.setState({
        pdfResponse: null,
        loading: false,
      });
    }
    if (newValue === 2) {
      downloadPDFAllegati(esercizioId, dataInizio, dataFine)
        .then(async (response) => {
          let file = URL.createObjectURL(await response.blob());
          this.setState({
            pdfResponse: file,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            errorDialogVisible: true,
            errorDialogMessage:
              "Si è verificato un errore, operazione non riuscita",
            loading: false,
            errorLoading: true,
          });
        });
    }
    if (newValue === 3) {
      this.setState({
        pdfResponse: null,
        loading: false,
      });
    }
    if (newValue === 4) {
      downloadPDFProdotti(esercizioId, dataInizio, dataFine)
        .then(async (response) => {
          let file = URL.createObjectURL(await response.blob());
          this.setState({
            pdfResponse: file,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            errorDialogVisible: true,
            errorDialogMessage:
              "Si è verificato un errore, operazione non riuscita",
            loading: false,
            errorLoading: true,
          });
        });
    }
  };

  handleControlliFilter = () => {
    const revisioneId = this.props.location.state.revisioneSelected;
    const moment = require("moment");
    const dataInizio = moment(this.props.location.state.dataInizio).format(
      "YYYY-MM-DD"
    );
    const dataFine = moment(this.props.location.state.dataFine).format(
      "YYYY-MM-DD"
    );
    const controlliSelezionatiId = this.state.controlliSelezionati.map(
      (elem) => elem.id
    );

    if (controlliSelezionatiId.length > 0) {
      this.setState({
        loading: true,
      });
      downloadPDFControlliForProcedure(
        revisioneId,
        controlliSelezionatiId,
        dataInizio,
        dataFine
      )
        .then(async (response) => {
          let file = URL.createObjectURL(await response.blob());
          this.setState({
            pdfResponse: file,
            pageNumber: 1,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            errorDialogVisible: true,
            errorDialogMessage:
              "Si è verificato un errore, operazione non riuscita",
            loading: false,
            errorLoading: true,
          });
        });
    } else {
      this.setState({
        pdfResponse: null,
        pageNumber: 1,
        loading: false,
      });
    }
  };

  handleSchedeFilter = () => {
    const moment = require("moment");
    const dataInizio = moment(this.props.location.state.dataInizio).format(
      "YYYY-MM-DD"
    );
    const dataFine = moment(this.props.location.state.dataFine).format(
      "YYYY-MM-DD"
    );
    const filtriSelezionatiId = this.state.schedeSelezionati.map(
      (elem) => elem.id
    );
    this.setState({
      loading: true,
    });

    if (filtriSelezionatiId.length > 0) {
      downloadPDFSchede(filtriSelezionatiId, dataInizio, dataFine)
        .then(async (response) => {
          let file = URL.createObjectURL(await response.blob());
          this.setState({
            pdfResponse: file,
            pageNumber: 1,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            errorDialogVisible: true,
            errorDialogMessage:
              "Si è verificato un errore, operazione non riuscita",
            loading: false,
            errorLoading: true,
          });
        });
    } else {
      this.setState({
        pdfResponse: null,
        pageNumber: 1,
        loading: false,
      });
    }
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages: numPages,
      isDocumentFull: true,
      loading: false,
    });
  };

  handleFilterChange = (e) => {
    this.setState({
      controlliSelezionati: e.target.value,
    });
  };

  handleFilterSchedeChange = (e) => {
    this.setState({
      schedeSelezionati: e.target.value,
    });
  };

  goToPreviousPage = () => {
    this.setState((prevState) => ({
      pageNumber: Math.max(prevState.pageNumber - 1, 1),
    }));
  };

  goToNextPage = () => {
    this.setState((prevState) => ({
      pageNumber: Math.min(prevState.pageNumber + 1, prevState.numPages),
    }));
  };

  updateParentState = (newState) => {
    this.setState(newState);
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    const { location } = this.props;
    if (!location || !location.state) {
      return <div>Error: Form data not found.</div>;
    }
    return (
      <div style={styles.mainContainer}>
        <div style={styles.headerContainer}>
          <Typography style={styles.fontPresentation}>
            Ispezione{" "}
            {this.state.tabValue === 0
              ? "Manuale"
              : this.state.tabValue === 1
              ? "Controlli"
              : this.state.tabValue === 2
              ? "Allegati"
              : this.state.tabValue === 3
              ? "Schede"
              : "Prodotti"}
          </Typography>
          <Typography style={styles.fontPresentationSubtitle}>
            Revisione dal{" "}
            {location.state.dataInizio &&
              location.state.dataInizio.toLocaleString("it-IT", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}{" "}
            al{" "}
            {location.state.dataFine &&
              location.state.dataFine.toLocaleString("it-IT", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
          </Typography>
        </div>
        <div style={styles.bodyContainer}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              orientation="vertical"
              TabIndicatorProps={{
                style: {
                  background: theme.palette.auditPalette.secondary,
                  left: 0,
                },
              }}
              value={this.state.tabValue}
              onChange={this.handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Manuale" />
              <Tab label="Controlli" />
              <Tab label="Allegati" />
              <Tab label="Schede" />
              <Tab label="Prodotti" />
            </Tabs>
          </Box>
          <CustomTabPanel value={this.state.tabValue} index={0}>
            <IspezioneTab
              loading={this.state.loading}
              pdfResponse={this.state.pdfResponse}
              numPages={this.state.numPages}
              pageNumber={this.state.pageNumber}
              goToPreviousPage={this.goToPreviousPage}
              goToNextPage={this.goToNextPage}
              updateParentState={this.updateParentState}
              errorLoading={this.state.errorLoading}
            />
          </CustomTabPanel>
          <CustomTabPanel value={this.state.tabValue} index={1}>
            <FiltroIspezione
              loading={this.state.loading}
              controlliSelezionati={this.state.controlliSelezionati}
              filtroControlli={this.state.filtroControlli}
              onChange={this.handleFilterChange}
              onClose={this.handleControlliFilter}
            />
            <IspezioneTab
              loading={this.state.loading}
              pdfResponse={this.state.pdfResponse}
              numPages={this.state.numPages}
              pageNumber={this.state.pageNumber}
              goToPreviousPage={this.goToPreviousPage}
              goToNextPage={this.goToNextPage}
              updateParentState={this.updateParentState}
              errorLoading={this.state.errorLoading}
            />
          </CustomTabPanel>
          <CustomTabPanel value={this.state.tabValue} index={2}>
            <IspezioneTab
              loading={this.state.loading}
              pdfResponse={this.state.pdfResponse}
              numPages={this.state.numPages}
              pageNumber={this.state.pageNumber}
              goToPreviousPage={this.goToPreviousPage}
              goToNextPage={this.goToNextPage}
              updateParentState={this.updateParentState}
              errorLoading={this.state.errorLoading}
            />
          </CustomTabPanel>
          <CustomTabPanel value={this.state.tabValue} index={3}>
            <FiltroIspezione
              loading={this.state.loading}
              controlliSelezionati={this.state.schedeSelezionati}
              filtroControlli={this.state.filtroSchede}
              onChange={this.handleFilterSchedeChange}
              onClose={this.handleSchedeFilter}
            />
            <IspezioneTab
              loading={this.state.loading}
              pdfResponse={this.state.pdfResponse}
              numPages={this.state.numPages}
              pageNumber={this.state.pageNumber}
              goToPreviousPage={this.goToPreviousPage}
              goToNextPage={this.goToNextPage}
              updateParentState={this.updateParentState}
              errorLoading={this.state.errorLoading}
            />
          </CustomTabPanel>
          <CustomTabPanel value={this.state.tabValue} index={4}>
            <IspezioneTab
              loading={this.state.loading}
              pdfResponse={this.state.pdfResponse}
              numPages={this.state.numPages}
              pageNumber={this.state.pageNumber}
              goToPreviousPage={this.goToPreviousPage}
              goToNextPage={this.goToNextPage}
              updateParentState={this.updateParentState}
              errorLoading={this.state.errorLoading}
            />
          </CustomTabPanel>
        </div>
        <div style={{ flex: 1 }}>
          <IndietroButton
            isDisabled={false}
            style={{
              float: "right",
              borderRadius: 20,
              marginTop: 60,
              color: "white",
              background: "linear-gradient(to right, #0d5fab, #00b1b7,#0d5fab)",
            }}
          />
        </div>
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}

export default withRouter(DettaglioRevisione);
