import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { Component } from "react";
// React-table library requires to import css as well:
import "react-table/react-table.css"; // eslint-disable-next-line
import PropTypes from "prop-types";
import ErrorDialog from "../../components/ErrorDialog";
import SpinnerComponent from "../../components/SpinnerComponent";
import theme from "../../../theme.js";
import { clearToken } from "../../../utils/storage.js";
import EditRecordButton from "../../components/EditRecordButton";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { Add, Search } from "@material-ui/icons";
import ModalSelezioneTipoGruppo from "../../components/ModalSelezioneTipoGruppo";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import {
  getAllPagingGruppiAuditing,
  getCountGruppi,
} from "../../../utils/api/gruppi-auditing_api";
import BasicTable from "../../components/BasicTable";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
};

class GruppiTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [{ id: "023", name: "prova", gruppoTipo: "Gruppo_rapido" }],
      columns: [],
      gruppi: [],
      loading: true,
      errorDialogVisible: false,
      errorDialogMessage: "",
      isGruppoSelectionModalOpen: false,

      id: "",
      nome: "",
      tipo: "",

      loadedPage: 0,
      totalPages: 1,
      pageSize: 10,
      totalCount: 1,
    };
  }

  componentDidMount() {
    this.fetchRecords(this.state.loadedPage);
    this.getColumns();
  }

  openGruppoSelectionModal = () => {
    this.setState({
      isGruppoSelectionModalOpen: true,
    });
  };

  closeSelectionModal = () => {
    this.setState({
      isGruppoSelectionModalOpen: false,
    });
  };

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  fetchRecords = (pageIndex) => {
    const { ispettoreId, gruppoId } = this.props;
    const fetchFunction =
      ispettoreId === null
        ? () =>
            getAllPagingGruppiAuditing(
              pageIndex,
              this.state.pageSize,
              "id",
              this.state.id,
              this.state.nome,
              this.state.tipo
            )
        : () =>
            getAllPagingGruppiAuditing(
              pageIndex,
              this.state.pageSize,
              "id",
              gruppoId,
              this.state.nome,
              this.state.tipo
            );

    getCountGruppi("", this.props.gruppoId)
      .then((result) => {
        this.setState({
          totalCount: result,
        });
        return fetchFunction();
      })
      .then((result) => {
        this.setState({
          records: result,
          totalPages: Math.ceil(this.state.totalCount / this.state.pageSize),
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  getColumns = () => {
    this.setState({
      columns: [
        {
          Header: "ID",
          id: "id",
          accessor: (d) => d.id,
          minWidth: 80,
          filterable: true,

          Filter: (cellInfo) => (
            <TextField
              fullWidth
              variant="outlined"
              value={this.state.id}
              onChange={(e) => {
                let inputValue = e.target.value;
                let regex = /^[0-9]+$/;

                if (regex.test(inputValue) || inputValue === "") {
                  this.setState({
                    id: inputValue,
                  });
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        this.setState({
                          loadedPage: 0,
                        });
                        this.fetchRecords(0);
                      }}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ),
        },
        {
          Header: "Nome gruppo di auditing",
          id: "name",
          accessor: (d) => d.name,
          minWidth: 120,
          Filter: (cellInfo) => (
            <TextField
              variant="outlined"
              fullWidth
              value={this.state.nome}
              onChange={(e) => {
                this.setState({
                  nome: e.target.value,
                });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        this.setState({
                          loadedPage: 0,
                        });
                        this.fetchRecords(0);
                      }}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ),
        },
        {
          Header: "Tipologia gruppo di auditing",
          id: "tipoGruppo",
          accessor: (d) =>
            d.tipoGruppo === "GRUPPO_RAPIDO"
              ? "Gruppo di auditing rapido"
              : "Gruppo di auditing personalizzato",
          minWidth: 120,
          Filter: (cellInfo) => (
            <>
              <Select
                variant="outlined" // Add this line to set the variant to "outlined"
                value={this.state.tipo || ""}
                fullWidth
                displayEmpty
                style={{ height: "31px" }}
                onChange={(e) => {
                  this.setState(
                    {
                      tipo: e.target.value,
                      loadedPage: 0,
                    },
                    () => {
                      this.fetchRecords(0);
                    }
                  );
                }}
              >
                <MenuItem value="">Tutti i gruppi</MenuItem>
                <MenuItem value="GRUPPO_RAPIDO">Gruppo Rapido</MenuItem>
                <MenuItem value="GRUPPO_PERSONALIZZATO">
                  Gruppo Personalizzato
                </MenuItem>
              </Select>
            </>
          ),
        },
        {
          Header: "Visualizza",
          id: "editBtn",
          accessor: (row) => row,
          sortable: false,
          filterable: false,
          width: 100,
          Cell: (props) => (
            <EditRecordButton
              onClick={() => this.handleRedirect(props.value.id)}
              recordId={props.value.id}
              disabled={false}
            />
          ),
        },
      ],
    });
  };

  handleRedirect = (gruppoId) => {
    this.props.history.push(`/gruppi_di_auditing/${gruppoId}`);
  };

  handlePageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () => this.fetchRecords(this.state.loadedPage)
    );
  };
  render() {
    const isAdmin = this.props.ispettoreId === null;
    const { records, totalPages, pageSize, columns, loadedPage } = this.state;

    const { handlePageChange } = this;

    return (
      <div style={{ paddingTop: "30px", minHeight: "400px" }}>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <div style={styles.mainContainer}>
            {isAdmin ? (
              <div
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "right",
                }}
              >
                <Button
                  disabled={false}
                  variant="contained"
                  style={{
                    color: theme.palette.secondary.main,
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 30,
                  }}
                  onClick={() => {
                    this.openGruppoSelectionModal();
                  }}
                >
                  <Add></Add>
                </Button>
              </div>
            ) : null}

            <BasicTable
              data={records}
              columns={columns}
              page={loadedPage}
              defaultPageSize={pageSize}
              onPageChange={handlePageChange}
              pages={totalPages}
            />
          </div>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        {this.state.isGruppoSelectionModalOpen && (
          <ModalSelezioneTipoGruppo
            open={this.state.isGruppoSelectionModalOpen}
            onClose={this.closeSelectionModal}
          ></ModalSelezioneTipoGruppo>
        )}
      </div>
    );
  }
}

GruppiTable.propTypes = {
  ispettoreId: PropTypes.number,
  gruppoId: PropTypes.number,
  history: PropTypes.object,
  location: PropTypes.object,
};

GruppiTable.defaultProps = {
  ispettoreId: null,
  gruppoId: null,
};

export default withRouter(GruppiTable);
