import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

import theme from "../../theme";

export default function IndietroButton(props) {
  let history = useHistory();
  return (
    <Button
      onClick={() => {
        if (props.alert === true) {
          var r = window.confirm("Modifiche non salvate, andare indietro?");
          if (r === true) {
            history.goBack();
          }
        } else {
          history.goBack();
        }
      }}
      variant="contained"
      className="float-right"
      size="medium"
      disabled={props.isDisabled}
      style={
        props.style !== undefined
          ? props.style
          : {
              color: "white",
              margin: "10px",
              borderRadius: 30,
              background: props.isDisabled
                ? theme.palette.disabled.main
                : "linear-gradient(to right, #0d5fab, #00b1b7,#0d5fab)",
            }
      }
    >
      Indietro
    </Button>
  );
}

IndietroButton.propTypes = {
  isDisabled: PropTypes.bool,
  alert: PropTypes.bool,
};
