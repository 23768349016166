import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";

import LoginResult from "./LoginResult";
import LoginForm from "./LoginForm";

import { ThemeProvider } from "@material-ui/styles";
import theme from "../../theme.js";
import Background from "../../background.png";
import {
  saveToken,
  retrieveToken,
  clearToken,
  setIspettoreId,
  setGruppoId,
  setSuperAuditor,
} from "../../utils/storage";
import { logIn, getAccountData } from "../../utils/api/account_api";

// const styles = {
//   header: {
//     backgroundColor: theme.palette.primary.main,
//     padding: "10px",
//     color: theme.palette.secondary.main,
//   },
// };

export default class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        username: "",
        password: "",
      },
      showForm: true,
      loginError: false,
      loginMessage: "",
      userAlreadyLogged: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    const token = retrieveToken();
    window.addEventListener("resize", this.updateDimensions);
    if (token !== null) {
      this.setState({
        loginError: true,
        showForm: false,
        loginMessage: "Eseguire il logout prima di ripetere il login.",
        userAlreadyLogged: true,
      });
    }
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  executeLogin = (loginData) => {
    let data = this.state.data;
    data.username = loginData.username;
    data.password = loginData.password;
    this.setState({
      data,
    });

    logIn(loginData.username, loginData.password)
      .then((result) => {
        let jwtToken = result.token;
        let message = "";
        if (jwtToken !== null) {
          saveToken(jwtToken);
          this.getUserData();
        } else {
          message = "Errore di servizio. Per favore, riprovare più tardi.";
          this.setState({
            loginError: true,
            showForm: false,
            loginMessage: message,
          });
        }
      })
      .catch((exception) => {
        let errorMessage = exception.message;
        this.setState({
          loginError: true,
          showForm: false,
          loginMessage: errorMessage,
        });
      });
  };

  handleInvalidToken = () => {
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  handleInvalidToken = () => {
    this.setState({
      loginError: true,
      showForm: false,
      loginMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  getUserData = () => {
    getAccountData()
      .then((result) => {
        const ruoli = result.ruoli;
        const ispettoreId = result.id;
        const gruppoId = result.gruppoId;
        ruoli.forEach(function (item, _index, _array) {
          if (item === "ROLE_SUPERAUDITOR") {
            setSuperAuditor(true);
          } else if (item === "ROLE_AUDITOR") {
            setSuperAuditor(false);
          }
        });
        setIspettoreId(ispettoreId);
        setGruppoId(gruppoId);

        this.setState({
          loginError: false,
          showForm: false,
          loginMessage: "Log in effettuato con successo",
        });
      })
      .catch((error) => {
        if (error.status === 401 || error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loginError: true,
          showForm: false,
          loginMessage: error.message,
        });
      });
  };

  returnToForm = () => {
    this.setState({ showForm: true });
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundImage:
              this.state.width > 1160
                ? `url("${Background}"),url("${Background}")`
                : `url("")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "",
            backgroundAttachment: "fixed",
            backgroundPositionY: "50%",
            backgroundPositionX:
              this.state.width > 1899
                ? "130%, -30%"
                : this.state.width > 1799
                ? "140%, -40%"
                : this.state.width > 1599
                ? "150%, -50%"
                : this.state.width > 1399
                ? "170%, -70%"
                : this.state.width > 1299
                ? "190%, -90%"
                : this.state.width > 1250
                ? "210%, -110%"
                : this.state.width > 1200
                ? "230%, -130%"
                : "250%, -150%",
          }}
        >
          {this.state.showForm ? (
            <LoginForm onLoginButtonPressed={this.executeLogin} />
          ) : this.state.userAlreadyLogged ? (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ fontSize: "20px" }}>{this.state.loginMessage}</p>
              <div style={{ textAlign: "center" }}>
                <NavLink to="/" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => {
                      clearToken();
                    }}
                    style={{
                      color: theme.palette.secondary.main,
                      backgroundColor: theme.palette.primary.main,
                      margin: 10,
                      borderRadius: 30,
                    }}
                  >
                    Logout
                  </Button>
                </NavLink>
              </div>
            </div>
          ) : (
            <LoginResult
              hasError={this.state.loginError}
              message={this.state.loginMessage}
              onBackButtonPressed={this.returnToForm}
            />
          )}
        </div>
      </ThemeProvider>
    );
  }
}
