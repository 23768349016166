import React from "react";
import Modal from "@material-ui/core/Modal";
import { Button, Typography } from "@material-ui/core";
import { getEserciziCommercialiDaGruppi, getEserciziCommercialiForGruppiPersonalizzati, getSyncEserciziCommerciali } from "../../../utils/api/esercizi_commerciali_api";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import SelectTableForModifica from "../../components/SelectTableForModifica";
import PropTypes from "prop-types";
import moment from "moment";
import { Sync } from "@material-ui/icons";
import theme from "../../../theme";

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px grey",
    borderRadius: 20,
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "600px",
    display: "flex",
    width: "75%",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evely",
    gap: "20px",
  },
  tableContainer: {
    maxHeight: "500px",
    overflow: "auto",
    width: "100%",
    minWidth: "70vw",
  },
  modalTitle: {
    fontSize: 20,
    paddingTop: 20,
    paddingBottom: 10,
    alignSelf: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  button: {
    background: "linear-gradient(to right,#0d5fab,#00b1b7,#0d5fab)",
    color: "white",
    borderRadius: 30,
    width: "115px",
    marginRight: "20px",
  },
  syncDate: {
    display: "flex",
    paddingTop: "5px",
    paddingBottom: "5px",
    justifyContent: "right",
    alignItems: " center",
  },
};

export default class ModalModificaEserciziGruppoPersonalizzato extends React.Component {
  // Definizione della soglia come proprietà statica della classe
  static SOGLIA_SELEZIONI = 1000;

  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      esercizi: [],
      originalEserciziIds: [],
      selectedEsercizi: this.props.newSelectionForPersonalizzato || [],
      loadedPage: 0,
      tablePageNumber: 1,
      pageSize: 10,
      id: "",
      dataDaMostrare:null,
      orarioDaMostrare:null,
      nome: "",
      ragioneSociale: "",
      provincia: "",
      citta: "",
      indirizzo: "",

      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    const originalEserciziIds =
      this.props.originalEserciziGruppoPersonalizzato.map(
        (esercizio) => esercizio.id
      );
    this.setState(
      {
        columns: this.getColumns(),

        selectedEsercizi: this.props.originalEserciziGruppoPersonalizzato,
        originalEserciziIds: originalEserciziIds,
      },
      () => {
        this.fetchAllEserciziByGroups(this.state.loadedPage);
      }
    );
  }

  componentDidUpdate(prevProps) {
    // Verifica se la modal è stata riaperta
    if (!prevProps.open && this.props.open) {
      this.setState({ showConfirmationDialog: false });
      this.setOrarioSincronizzazione();
    }
  }
  setOrarioSincronizzazione = async () => {
    const result = await getSyncEserciziCommerciali();
    let dataSincLocale = moment(result.dataTermine);
    let orarioLocale = dataSincLocale.format("HH:mm");
    let dataLocale = dataSincLocale.format("DD.MM.YYYY");
    this.setState({
      orarioDaMostrare:orarioLocale,
      dataDaMostrare:dataLocale
    })
  };

  fetchAllEserciziByGroups = (pageIndex) => {
    const { id, nome, ragioneSociale, indirizzo, citta, provincia } =
      this.state;
    getEserciziCommercialiForGruppiPersonalizzati(
      this.props.selectedGroups,
    )
      .then((result) => {
        this.setState({
          esercizi: result,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  handleSelectionChange = (selectedEserciziIds) => {
    this.setState((prevState) => {
      const newSelectedEsercizi = prevState.esercizi.filter((esercizio) =>
        selectedEserciziIds.includes(esercizio.id)
      );
      return { selectedEsercizi: newSelectedEsercizi };
    });
  };

  handleSelectAllChange = (selectedAll) => {
    this.setState((prevState) => {
      let newSelectedEsercizi;
      if (selectedAll) {
        newSelectedEsercizi = [...prevState.esercizi];
      } else {
        newSelectedEsercizi = [];
      }

      return { selectedEsercizi: newSelectedEsercizi };
    });
  };

  saveNewEserciziSelection = () => {
    const numeroSelezionati = this.state.selectedEsercizi.length;
    if (
      numeroSelezionati >=
      ModalModificaEserciziGruppoPersonalizzato.SOGLIA_SELEZIONI
    ) {
      this.setState({ showConfirmationDialog: true });
    } else {
      this.proceedWithSaving();
    }
  };

  proceedWithSaving = () => {
    this.props.onSelectedEserciziChange(this.state.selectedEsercizi);

    if (this.props.onClose) this.props.onClose();
  };

  handleCloseDialog = () => {
    this.setState({ showConfirmationDialog: false });
  };

  getColumns = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        filterable: true,
        // Filter: (_cellInfo) => (
        //   <TextField
        //     variant="outlined"
        //     fullWidth
        //     value={this.state.id}
        //     onChange={(e) => {
        //       this.setState({
        //         id: e.target.value,
        //       });
        //     }}
        //     InputProps={{
        //       endAdornment: (
        //         <InputAdornment position="end">
        //           <IconButton size="small" onClick={this.searchEsercizi}>
        //             <Search />
        //           </IconButton>
        //         </InputAdornment>
        //       ),
        //     }}
        //   />
        // ),
      },
      {
        Header: "Nome Esercizio",
        id: "nome",
        accessor: (d) => d.nome,
        filterable: true,
        // Filter: (_cellInfo) => (
        //   <TextField
        //     variant="outlined"
        //     fullWidth
        //     value={this.state.nome}
        //     onChange={(e) => {
        //       this.setState({
        //         nome: e.target.value,
        //       });
        //     }}
        //     InputProps={{
        //       endAdornment: (
        //         <InputAdornment position="end">
        //           <IconButton size="small" onClick={this.searchEsercizi}>
        //             <Search />
        //           </IconButton>
        //         </InputAdornment>
        //       ),
        //     }}
        //   />
        // ),
      },
      {
        Header: "Ragione Sociale",
        id: "ragioneSociale",
        accessor: (d) => d.ragioneSociale,
        filterable: true,
        // Filter: (_cellInfo) => (
        //   <TextField
        //     variant="outlined"
        //     fullWidth
        //     value={this.state.ragioneSociale}
        //     onChange={(e) => {
        //       this.setState({
        //         ragioneSociale: e.target.value,
        //       });
        //     }}
        //     InputProps={{
        //       endAdornment: (
        //         <InputAdornment position="end">
        //           <IconButton size="small" onClick={this.searchEsercizi}>
        //             <Search />
        //           </IconButton>
        //         </InputAdornment>
        //       ),
        //     }}
        //   />
        // ),
      },
      {
        Header: "Provincia",
        id: "provincia",
        accessor: (d) => d.provinciaSede,
        filterable: true,
        // Filter: (_cellInfo) => (
        //   <TextField
        //     variant="outlined"
        //     fullWidth
        //     value={this.state.provincia}
        //     onChange={(e) => {
        //       this.setState({
        //         provincia: e.target.value,
        //       });
        //     }}
        //     InputProps={{
        //       endAdornment: (
        //         <InputAdornment position="end">
        //           <IconButton size="small" onClick={this.searchEsercizi}>
        //             <Search />
        //           </IconButton>
        //         </InputAdornment>
        //       ),
        //     }}
        //   />
        // ),
      },
      {
        Header: "Città",
        id: "citta",
        accessor: (d) => d.cittaSede,
        filterable: true,
        // Filter: (_cellInfo) => (
        //   <TextField
        //     variant="outlined"
        //     fullWidth
        //     value={this.state.citta}
        //     onChange={(e) => {
        //       this.setState({
        //         citta: e.target.value,
        //       });
        //     }}
        //     InputProps={{
        //       endAdornment: (
        //         <InputAdornment position="end">
        //           <IconButton size="small" onClick={this.searchEsercizi}>
        //             <Search />
        //           </IconButton>
        //         </InputAdornment>
        //       ),
        //     }}
        //   />
        // ),
      },
      {
        Header: "Indirizzo",
        id: "indirizzo",
        accessor: (d) => d.indirizzoSede,
        filterable: true,
        // Filter: (_cellInfo) => (
        //   <TextField
        //     variant="outlined"
        //     fullWidth
        //     value={this.state.indirizzo}
        //     onChange={(e) => {
        //       this.setState({
        //         indirizzo: e.target.value,
        //       });
        //     }}
        //     InputProps={{
        //       endAdornment: (
        //         <InputAdornment position="end">
        //           <IconButton size="small" onClick={this.searchEsercizi}>
        //             <Search />
        //           </IconButton>
        //         </InputAdornment>
        //       ),
        //     }}
        //   />
        // ),
      },
    ];
  };

  // handlePageChange = (pageIndex) => {
  //   this.setState(
  //     {
  //       loadedPage: pageIndex,
  //     },
  //     () => this.fetchAllEserciziByGroups(pageIndex)
  //   );
  // };

  // handlePageSizeChange = (newPageSize) => {
  //   this.setState({ pageSize: newPageSize }, () => {
  //     this.fetchAllEserciziByGroups(this.state.loadedPage);
  //     this.setPageNumber();
  //   });
  // };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={styles.modalcontainer}>
          <div style={styles.modalsectioncontainer}>
            <Typography style={styles.modalTitle}>
              Scegliere gli esercizi da associare al nuovo gruppo di auditing
            </Typography>
            <div style={styles.syncDate}>
            <Sync style={{ color: theme.palette.primary.main }} />
            <Typography
                  variant="subtitle2"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  Ultima sincronizzazione alle {this.state.orarioDaMostrare} del {this.state.dataDaMostrare}
                </Typography>
            </div>
            <SelectTableForModifica
              selectType={"checkbox"}
              data={this.state.esercizi}
              columns={this.state.columns}
              selectedElement={this.state.selectedEsercizi.map(
                (item) => item.id
              )}
              passSelectionToParent={this.handleSelectionChange}
              onToggleSelectAll={this.handleSelectAllChange}
              style={styles.tableContainer}
            />
            {/* <SelectTable
              selectType={"checkbox"}
              data={this.state.esercizi}
              columns={this.state.columns}
              defaultPageSize={this.state.pageSize}
              pageSizeOptions={[10, 20, 50, 100, 500]}
              onPageSizeChange={this.handlePageSizeChange}
              page={this.state.loadedPage}
              pages={this.state.tablePageNumber}
              onPageChange={this.handlePageChange}
              selectedElement={this.state.originalEserciziIds}
              passSelectionToParent={this.handleSelectionChange}
              onToggleSelectAll={this.handleSelectAllChange}
              style={styles.tableContainer}
              showPageSizeOptions={false}
            /> */}
            <div style={styles.buttonContainer}>
              <Button onClick={this.props.onClose} style={styles.button}>
                Indietro
              </Button>
              <Button
                variant="contained"
                onClick={this.saveNewEserciziSelection}
                style={styles.button}
              >
                Conferma
              </Button>
            </div>
          </div>
          {this.state.showConfirmationDialog && (
            <ConfirmationDialog
              open={this.state.showConfirmationDialog}
              onClose={this.handleCloseDialog}
              onConfirm={this.proceedWithSaving}
              title="Conferma Selezione"
              message={`Attenzione hai selezionato più di 1000 elementi, il sistema potrebbe non funzionare correttamente.`}
            />
          )}
        </div>
      </Modal>
    );
  }
}

ModalModificaEserciziGruppoPersonalizzato.propTypes = {
  selectedGroups: PropTypes.arrayOf(PropTypes.number),
  handleInvalidToken: PropTypes.func,
  handleError: PropTypes.func,
  originalEserciziGruppoPersonalizzato: PropTypes.arrayOf(PropTypes.object),
  onSelectedEserciziChange: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  newSelectionForPersonalizzato: PropTypes.arrayOf(PropTypes.object),
};
