import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PropTypes from "prop-types";
import React from "react";
import validator from "validator";
import ErrorDialog from "../../components/ErrorDialog";
import IndietroButton from "../../components/IndietroButton";
import SpinnerComponent from "../../components/SpinnerComponent";
import theme from "../../../theme.js";
import { registerIspettori } from "../../../utils/api/ispettore_api";
import { clearToken } from "../../../utils/storage";
import { Checkbox, withStyles } from "@material-ui/core";
import { List } from "@material-ui/icons";
import ModalSelezioneGruppoRegistraAuditor from "./ModalSelezioneGruppoRegistraAuditor";

const styles = {
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "20px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
  },
  userdatacontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
};

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.auditPalette.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.auditPalette.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.auditPalette.primary,
      },
    },
  },
})(TextField);

export default class FormRegistraIspettore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      record: {
        email: "",
        password: "",
        confirmPassword: "",
        nome: "",
        cognome: "",
        telefono: "",
        superAuditor: false,
        terminiUsoAccettati: false,
        privacyPolicyAccettata: false,
        gruppoId: "",
        nomeGruppo: "",
      },

      validationErrors: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      zone: [],
      showPassword1: false,
      showPassword2: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: false,
      disableForm: false,
      saveButtonEnabled: false,
      _isMounted: false,
      selectedGroup: null,

      isModalSelezioneGruppoAuditorOpen: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  handleClickShowPassword1 = () => {
    let flag = this.state.showPassword1;
    this.setState({
      showPassword1: !flag,
    });
  };
  handleClickShowPassword2 = () => {
    let flag = this.state.showPassword2;
    this.setState({
      showPassword2: !flag,
    });
  };

  validateForm = () => {
    let data = this.state.record;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let insertedPsw = this.state.record.password;
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "email":
        if (value.length === 0) {
          validationErrors.email = "Inserire l'indirizzo email";
        } else {
          if (validator.isEmail(value)) {
            validationErrors.email = "";
          } else {
            validationErrors.email = "L'indirizzo email inserito non è valido";
          }
        }
        break;
      case "password":
        if (value.length >= 8) {
          validationErrors.password = "";
        } else {
          validationErrors.password =
            "Inserire una password di almeno 8 caratteri";
        }
        break;
      case "confirmPassword":
        if (value.length === 0) {
          validationErrors.confirmPassword = "Ripetere la password";
        } else {
          if (value === insertedPsw) {
            validationErrors.confirmPassword = "";
          } else {
            validationErrors.confirmPassword =
              "Le due password non corrispondono";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleChange = (event) => {
    this.handleFormChange(event.target.name, event.target.value);
  };

  handleFormChange = (fielName, value) => {
    this.props.onFormModified();
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let validationErrors = this.state.validationErrors;
    let data = this.state.record;
    data[fielName] = value;
    //Reset validation :
    if (
      fielName === "email" ||
      fielName === "password" ||
      fielName === "confirmPassword"
    ) {
      validationErrors[fielName] = "";
    }
    this.setState({
      data,
      validationErrors,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let isFormValid = this.validateForm();

    const isSuperAuditor = this.props.superAuditorView;

    let data = {
      email: this.state.record.email,
      password: this.state.record.password,
      nome: this.state.record.nome,
      cognome: this.state.record.cognome,

      telefono: this.state.record.telefono,
      superauditor: this.state.record.superAuditor,
      privacyPolicyAccettata: false,
      terminiUsoAccettati: false,
      gruppoId: this.state.record.gruppoId || this.props.gruppoId,
    };

    if (isFormValid && data.gruppoId) {
      this.setState({
        loading: true,
      });

      registerIspettori(data, isSuperAuditor)
        .then((result) => {
          this.setState({
            loading: false,
            saveButtonEnabled: false,
            disableForm: true,
          });
          this.props.onFormSubmitted();
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            let enableSave = true;
            if (error.status === 409) {
              enableSave = false;
            }
            this.setState({
              loading: false,
              saveButtonEnabled: enableSave,
            });
            this.handleError(true, error.message);
          }
        });
    }

    if (!this.state.record.gruppoId && isSuperAuditor === false) {
      this.setState({
        errorDialogVisible: true,
        errorDialogMessage: "Attenzione! Scegliere un gruppo di auditing.",
      });
    }
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  /* handleSelectedGroup = (selectedGroup, selectedGroupData) => {
    this.setState({
      selectedGroup: selectedGroup,
    });

    this.setState((prevState) => ({
      record: {
        ...prevState.record,
        gruppoId: selectedGroup,
      },
    }));

    this.setState((prevState) => ({
      record: {
        ...prevState.record,
        nomeGruppo: selectedGroupData.name,
      },
    }));

 
  };
 */

  handleModalSelezioneGruppoAuditorClose = () => {
    this.setState({
      isModalSelezioneGruppoAuditorOpen: false,
    });
  };
  handleModalSelezioneGruppoAuditorSaveData = (selection, selectedGroupData) => {
    let modified = this.state.saveButtonEnabled;
    let record = { ...this.state.record };
    if (selection !== undefined && selection !== null && selection.length > 0) {
      record.gruppoId = selection[0];
      if (selectedGroupData !== undefined && selectedGroupData !== null) {
        record.nomeGruppo = selectedGroupData.name;
      }
      modified = true;
    }
    this.setState({
      isModalSelezioneGruppoAuditorOpen: false,
      record: record,
      saveButtonEnabled: modified,
    });

    this.setState((prevState) => ({
      record: {
        ...prevState.record,
        gruppoId: record.gruppoId,
      },
    }));
  };

  handleOpenModalSelezioneGruppoAuditorOpen = () => {
    this.setState({
      isModalSelezioneGruppoAuditorOpen: true,
    });
  };

  render() {
    // const isSuperAuditor = this.props.superAuditorView;

    const disabledSubmitbutton =
      this.state.loading || !this.state.saveButtonEnabled;

    return (
      <Container
        id="form_container"
        style={{
          paddingLeft: "4px",
          paddingRight: "4px",
          maxWidth: "700px",
          minHeight: "500px",
        }}
      >
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={styles.sectioncontainer}
              >
                <Typography
                  variant="h5"
                  style={{ paddingTop: "6px", paddingBottom: "6px" }}
                >
                  Dati utente
                </Typography>
                <Grid container style={styles.userdatacontainer}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <TextField
                        label="Email*"
                        value={
                          this.state.record.email === null
                            ? ""
                            : this.state.record.email
                        }
                        name="email"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                        helperText={this.state.validationErrors.email}
                        error={
                          this.state.validationErrors.email.length > 0
                            ? true
                            : false
                        }
                      />
                      <TextField
                        label="Password*"
                        value={this.state.record.password}
                        name="password"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                        helperText={this.state.validationErrors.password}
                        error={
                          this.state.validationErrors.password.length > 0
                            ? true
                            : false
                        }
                        type={this.state.showPassword1 ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Mostra password">
                                <IconButton
                                  label="Mostra password"
                                  onClick={this.handleClickShowPassword1}
                                >
                                  {this.state.showPassword1 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Conferma password*"
                        value={this.state.record.confirmPassword}
                        name="confirmPassword"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                        helperText={this.state.validationErrors.confirmPassword}
                        error={
                          this.state.validationErrors.confirmPassword.length > 0
                            ? true
                            : false
                        }
                        type={this.state.showPassword2 ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Mostra password">
                                <IconButton
                                  label="Mostra password"
                                  onClick={this.handleClickShowPassword2}
                                >
                                  {this.state.showPassword2 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <TextField
                        label="Nome"
                        value={
                          this.state.record.nome === null
                            ? ""
                            : this.state.record.nome
                        }
                        name="nome"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                      />
                      <TextField
                        label="Cognome"
                        value={
                          this.state.record.cognome === null
                            ? ""
                            : this.state.record.cognome
                        }
                        name="cognome"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                      />
                      <TextField
                        label="Telefono"
                        defaultValue={
                          this.state.record.telefono === null
                            ? ""
                            : this.state.record.telefono
                        }
                        name="telefono"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {!this.props.superAuditorView && (
              <Container style={styles.sectioncontainer}>
                <Typography variant="h6" style={styles.text}>
                  Dati Gruppo
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Checkbox
                    checked={this.state.record.superAuditor}
                    style={{
                      color: theme.palette.auditPalette.primary,
                      "&.MaterialUiChecked": {
                        color: theme.palette.auditPalette.primary,
                      },
                    }}
                    onChange={() => {
                      let record = this.state.record;
                      record.superAuditor = !record.superAuditor;
                      this.setState({
                        record,
                      });
                    }}
                  ></Checkbox>
                  <Typography style={{ margin: 10 }}>
                    Selezionare la casella per assegnare il ruolo di
                    superauditor
                  </Typography>
                </div>

                <Typography>
                  {" "}
                  Scegliere il gruppo di auditing di appartenza del nuovo
                  auditor
                </Typography>

                <CssTextField
                  variant="outlined"
                  value={
                    this.state.record.nomeGruppo
                      ? this.state.record.nomeGruppo
                      : "SELEZIONA UN GRUPPO DI AUDITING"
                  }
                  InputProps={{
                    readOnly: true,
                    style: {
                      borderRadius: 50,
                      borderColor: theme.palette.auditPalette.primary,
                      width: 400,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            this.handleOpenModalSelezioneGruppoAuditorOpen();
                          }}
                        >
                          <List
                            style={{ color: "#00b1b7" }}
                            width={35}
                            height={35}
                          ></List>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></CssTextField>
              </Container>
            )}

            <Grid
              container
              justify="center"
              style={{ paddingTop: "10px", paddingBottom: "20px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ textAlign: "right" }}
              >
                <IndietroButton
                  isDisabled={false}
                  onClick={() => {}}
                  style={{
                    borderRadius: 20,
                    color: "white",
                    marginRight: 10,
                    background:
                      "linear-gradient(to right, #0d5fab, #00b1b7,#0d5fab)",
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  disabled={disabledSubmitbutton}
                  style={{
                    color: theme.palette.secondary.main,
                    borderRadius: 20,
                    background: disabledSubmitbutton
                      ? theme.palette.disabled.main
                      : "linear-gradient(to right, #00b1b7,#0d5fab,#00b1b7)",
                  }}
                >
                  Registra
                </Button>
              </Grid>
            </Grid>
          </form>
        )}

        {/*  {this.state.isModalOpen && <ModalSelezioneGruppoRegistraAuditor
          open={this.state.isModalOpen}
          onClose={this.handleCloseModal}
          onSelectedGroupChange={this.handleSelectedGroup}
          selectedGroup={
            this.state.record.gruppoId === null
              ? null
              : [this.state.record.gruppoId]}
        />} */}

        {this.state.isModalSelezioneGruppoAuditorOpen && (
          <ModalSelezioneGruppoRegistraAuditor
            open={this.state.isModalSelezioneGruppoAuditorOpen}
            onClose={this.handleModalSelezioneGruppoAuditorClose}
            saveData={this.handleModalSelezioneGruppoAuditorSaveData}
            selectedGroup={this.props.gruppoId}
            selectedGroupId={[this.state.record.gruppoId]}
          />
        )}

        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        ></ErrorDialog>
      </Container>
    );
  }
}

FormRegistraIspettore.propTypes = {
  onFormModified: PropTypes.func.isRequired,
  onFormSubmitted: PropTypes.func.isRequired,
};
