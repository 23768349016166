import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";

import ErrorDialog from "../../components/ErrorDialog";
import IndietroButton from "../../components/IndietroButton";
import SpinnerComponent from "../../components/SpinnerComponent";
import theme from "../../../theme.js";
import { getZone } from "../../../utils/api/zone_api";
import { clearToken } from "../../../utils/storage";
import SelectEsercizio from "../../components/SelectEsercizio";
import SelectIspettore from "../../components/SelectIspettore";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";
import SelectChecklist from "../../components/SelectChecklist";
import Typography from "@material-ui/core/Typography";
import SelezioneGruppo from "../../components/SelezioneGruppo";
import { registerIspezione } from "../../../utils/api/ispezione_api";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
const dateFormat = "dd-MM-yyyy";

const styles = {
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  formcontrol: {
    marginBottom: "30px",
    width: "250px",
  },
  periodo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "5%",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  userdatacontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
};
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "146px",
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "250px",
    },
  },
};
export default class FormRegistraIspezione extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEsercizio: null,
      selectedIspettore: null,
      selectedChecklist: null,
      tipoIspezione: "PROGRAMMATO",
      record: {
        email: "",
        password: "",
        confirmPassword: "",
        nome: null,
        cognome: null,
        telefono: null,
        terminiUsoAccettati: false,
        privacyPolicyAccettata: false,
        zonaId: null,
      },
      validationErrors: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      gruppi: [],
      dataInizioPeriodo: null,
      dataFinePeriodo: null,
      dataInizioIspezione: null,
      dataFineIspezione: null,

      showPassword1: false,
      showPassword2: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: true,
      disabledSubmitbutton: false,
      saveButtonEnabled: false,
    };
  }

  componentDidMount() {
    this.fetchzone();
  }

  fetchzone = () => {
    this.setState({
      loading: true,
    });
    getZone(null, null, 0, 1000, "id")
      .then((result) => {
        let newarray = [{ id: -1, nome: "Tutte le zone" }];
        newarray.push(...result);
        let zonaId = this.state.zonaId;
        if (zonaId === null) {
          zonaId = -1;
        }
        this.setState({
          zone: newarray,
          zonaId,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  handleClickShowPassword1 = () => {
    let flag = this.state.showPassword1;
    this.setState({
      showPassword1: !flag,
    });
  };
  handleClickShowPassword2 = () => {
    let flag = this.state.showPassword2;
    this.setState({
      showPassword2: !flag,
    });
  };

  validateForm = () => {
    let data = this.state.record;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  handleSelectedGruppo = (zonaId) => {
    this.handleFormChange("zonaId", zonaId);
  };

  handleChange = (event) => {
    this.handleFormChange(event.target.name, event.target.value);
  };

  handleFormChange = (fielName, value) => {
    this.props.onFormModified();
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let validationErrors = this.state.validationErrors;
    let data = this.state.record;
    data[fielName] = value;
    //Reset validation :
    if (
      fielName === "email" ||
      fielName === "password" ||
      fielName === "confirmPassword"
    ) {
      validationErrors[fielName] = "";
    }
    this.setState({
      data,
      validationErrors,
    });
  };
  onDateTimeChanged = (name, value) => {
    let date = value;
    this.handleChangeTime(name, date);
  };

  handleChangeTime = (name, value) => {
    if (this.state.saveDisabled) {
      this.setState({
        saveDisabled: false,
      });
    }
    this.setState({
      [name]: value,
    });
  };

  CheckFormSubmit = () => {
    if (
      this.state.selectedEsercizio !== null &&
      this.state.selectedIspettore !== null &&
      this.state.selectedChecklist !== null &&
      this.state.dataInizioPeriodo !== null &&
      this.state.dataFinePeriodo !== null &&
      this.state.dataInizioIspezione !== null &&
      this.state.dataFineIspezione !== null &&
      this.state.tipoIspezione !== null
    ) {
      return true;
    } else {
      return false;
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    let check = this.CheckFormSubmit();
    if (check === true) {
      let record = {
        esercizioCommercialeId: this.state.selectedEsercizio.id,
        ispettoreId: this.state.selectedIspettore.id,
        checklistId: this.state.selectedChecklist.id,
        dataInizioPeriodo: this.state.dataInizioPeriodo,
        dataFinePeriodo: this.state.dataFinePeriodo,
        dataInizioIspezione: this.state.dataInizioIspezione,
        dataFineIspezione: this.state.dataFineIspezione,
        tipoIspezione: this.state.tipoIspezione,
      };
      registerIspezione(record)
        .then((result) => {
          this.setState({
            loading: false,
            saveButtonEnabled: false,
            disabledSubmitbutton: true,
          });
          alert("record registrato");
          //      this.props.onFormSubmitted();
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            let enableSave = true;
            if (error.status === 409) {
              enableSave = false;
            }
            this.setState({
              loading: false,
              saveButtonEnabled: enableSave,
            });
            this.handleError(true, error.message);
          }
        });
    } else {
      alert("completare tutti i campi della form");
    }

    // }
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };
  updateParentStateEsercizio = (selectedEsercizio) => {
    this.setState({
      selectedEsercizio,
    });
  };
  updateParentStateIspettore = (selectedIspettore) => {
    this.setState({
      selectedIspettore,
    });
  };

  updateParentStateChecklist = (selectedChecklist) => {
    this.setState({
      selectedChecklist,
    });
  };
  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };
  handleSelectedZona = (zonaId) => {
    this.setState({
      zonaId,
    });
  };

  handleSelection = (event) => {
    let value = event.target.value;
    this.setState({ tipoIspezione: value });
  };
  render() {
    // const disabledSubmitbutton = this.state.loading || !this.state.saveButtonEnabled;
    const disabledSubmitbutton = this.state.disabledSubmitbutton;

    return (
      <Container
        id="form_container"
        style={{
          paddingLeft: "4px",
          paddingRight: "4px",
          maxWidth: "700px",
          minHeight: "500px",
        }}
      >
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={styles.sectioncontainer}
              >
                <Typography
                  variant="h5"
                  style={{ paddingTop: "6px", paddingBottom: "6px" }}
                >
                  Nuova ispezione
                </Typography>
                <FormControl style={styles.formcontrol}>
                  <InputLabel id="gruppo-label">
                    Seleziona il tipo dell'ispezione
                  </InputLabel>
                  <Select
                    value={this.state.tipoIspezione}
                    name="id"
                    onChange={this.handleSelection}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"PROGRAMMATO"}>
                      <em>Programmata</em>
                    </MenuItem>
                    <MenuItem value={"DOMANDA"}>
                      <em>Su domanda</em>
                    </MenuItem>
                    <MenuItem value={"SEGNALAZIONE"}>
                      <em>Su segnalazione</em>
                    </MenuItem>
                    <MenuItem value={"FOLLOWUP"}>
                      <em>Su follow up</em>
                    </MenuItem>
                  </Select>
                </FormControl>
                <SelezioneGruppo
                  zonaId={this.props.zonaId}
                  zone={this.state.zone}
                  disabled={this.state.disableForm}
                  description="Selezionare la zona per l'ispezione"
                  onZonaSelected={this.handleSelectedZona}
                  onZonaAdded={this.fetchzone}
                  onError={this.handleError}
                  onAuthError={this.handleInvalidToken}
                />
                <SelectEsercizio
                  zonaId={this.state.zonaId}
                  updateParentState={this.updateParentStateEsercizio}
                ></SelectEsercizio>

                <SelectIspettore
                  zonaId={this.state.zonaId}
                  updateParentState={this.updateParentStateIspettore}
                ></SelectIspettore>

                <div style={styles.periodo}>
                  <Typography
                    variant="h5"
                    style={{ paddingTop: "6px", paddingBottom: "6px" }}
                  >
                    Periodo da ispezionare
                  </Typography>

                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={itLocale}
                  >
                    <DatePicker
                      ampm={false}
                      value={this.state.dataInizioPeriodo}
                      onChange={(date) => {
                        this.onDateTimeChanged("dataInizioPeriodo", date);
                      }}
                      format={dateFormat}
                      label="Data inizio"
                      name="dataInizioPeriodo"
                      style={styles.textfield}
                    />
                    <DatePicker
                      ampm={false}
                      value={this.state.dataFinePeriodo}
                      onChange={(date) => {
                        this.onDateTimeChanged("dataFinePeriodo", date);
                      }}
                      format={dateFormat}
                      label="Data fine"
                      name="dataFinePeriodo"
                      style={styles.textfield}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={styles.periodo}>
                  <Typography
                    variant="h5"
                    style={{ paddingTop: "6px", paddingBottom: "6px" }}
                  >
                    Periodo ispezione
                  </Typography>

                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={itLocale}
                  >
                    <DatePicker
                      disablePast={true}
                      ampm={false}
                      value={this.state.dataInizioIspezione}
                      onChange={(date) => {
                        this.onDateTimeChanged("dataInizioIspezione", date);
                      }}
                      format={dateFormat}
                      label="Data inizio"
                      name="dataInizioIspezione"
                      style={styles.textfield}
                    />
                    <DatePicker
                      disablePast={true}
                      ampm={false}
                      value={this.state.dataFineIspezione}
                      onChange={(date) => {
                        this.onDateTimeChanged("dataFineIspezione", date);
                      }}
                      format={dateFormat}
                      label="Data fine"
                      name="dataFineIspezione"
                      style={styles.textfield}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <SelectChecklist
                  updateParentState={this.updateParentStateChecklist}
                ></SelectChecklist>

                <Grid
                  container
                  justify="center"
                  style={{ paddingTop: "10px", paddingBottom: "20px" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "right" }}
                  >
                    <IndietroButton isDisabled={this.state.loading} />
                    <Button
                      type="button"
                      onClick={this.handleSubmit}
                      variant="contained"
                      size="medium"
                      disabled={disabledSubmitbutton}
                      style={{
                        color: theme.palette.secondary.main,
                        margin: "10px",
                        backgroundColor: disabledSubmitbutton
                          ? theme.palette.disabled.main
                          : theme.palette.primary.main,
                      }}
                    >
                      Registra
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        ></ErrorDialog>
      </Container>
    );
  }
}

FormRegistraIspezione.propTypes = {
  onFormModified: PropTypes.func.isRequired,
  onFormSubmitted: PropTypes.func.isRequired,
  zonaId: PropTypes.number,
};
