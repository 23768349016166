import { retrieveToken } from "../storage";
import { wrapFetch, ApiError } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getIspezioni = (ispettoreId, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = "/api/ispezioni";
  let query =
    "?" +
    "page=" +
    page +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC";
  if (ispettoreId !== null) {
    query = query + "&ispettoreId.equals=" + ispettoreId;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
export const getIspezione = (ispezioneId, ispettoreId, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = "/api/ispezioni";
  let query =
    "?" +
    "id.equals=" +
    ispezioneId +
    "&page=" +
    page +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC";
  if (ispettoreId !== null) {
    query = query + "&ispettoreId.equals=" + ispettoreId;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const registerIspezione = (requestBody) => {
  const token = retrieveToken();
  const PATH = "/api/ispezione";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message = "Registrazione non riuscita: dati non corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};
export const avviaIspezione = (id) => {
  const token = retrieveToken();
  const PATH = "/api/ispezione/avvia?ispezioneId=" + id;
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message = "Registrazione non riuscita: dati non corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
export const completaIspezione = (id) => {
  const token = retrieveToken();
  const PATH = "/api/ispezione/completa?ispezioneId=" + id;
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message = "Registrazione non riuscita: dati non corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
export const getIspezioniEsercizio = (
  ispettoreId,
  esercizioId,
  status,
  page,
  size,
  sortby
) => {
  const token = retrieveToken();
  const PATH = "/api/ispezioni";
  let query =
    "?" +
    "page=" +
    page +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC";
  if (ispettoreId !== null) {
    query = query + "&ispettoreId.equals=" + ispettoreId;
  }
  if (esercizioId !== null) {
    query = query + "&esercizioId.equals=" + esercizioId;
  }
  if (status !== null) {
    query = query + "&statoIspezione.equals=" + status;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getIspezioneRevisioni = (esercizioId) => {
  const token = retrieveToken();
  const PATH = "/api/ispezioni/revisioni/all";
  let query = "?esercizioCommercialeId.equals=" + esercizioId+"&size=10000";
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getProcedureAutocontrollo = (revisioneManualeId) => {
  const token = retrieveToken();
  const PATH = "/api/ispezioni/procedure";
  let query = "?revisione=" + revisioneManualeId;
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getSchede = (esercizioId, revisioneManualeId) => {
  const token = retrieveToken();
  const PATH = "/api/ispezioni/schede";
  let query = `?esercizio=${esercizioId}&revisione=${revisioneManualeId}`;
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const downloadPDFManuale = (revisioneManualeId) => {
  const token = retrieveToken();
  const PATH = `/api/ispezioni/download/pdf/${revisioneManualeId}`;
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const downloadPDFControlliForProcedure = (
  revisioneManualeId,
  procedureId,
  dataInizio,
  dataFine
) => {
  const token = retrieveToken();
  const PATH = "/api/ispezioni/download/pdf/controlli";
  let query = "?";

  if (revisioneManualeId) {
    query += `&revisione=${encodeURIComponent(revisioneManualeId)}`;
  }

  if (procedureId) {
    query += `&procedure=${encodeURIComponent(procedureId)}`;
  }
  if (dataInizio) {
    query += `&startdate=${encodeURIComponent(dataInizio)}`;
  }

  if (dataFine) {
    query += `&enddate=${encodeURIComponent(dataFine)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const downloadPDFAllegati = (esercizioId, dataInizio, dataFine) => {
  const token = retrieveToken();
  const PATH = "/api/ispezioni/download/pdf/allegati";
  let query = "?";

  if (esercizioId) {
    query += `esercizioId=${encodeURIComponent(esercizioId)}`;
  }

  if (dataInizio) {
    query += `&dataInizio=${encodeURIComponent(dataInizio)}`;
  }

  if (dataFine) {
    query += `&dataFine=${encodeURIComponent(dataFine)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const downloadPDFSchede = (ids, dataInizio, dataFine) => {
  const token = retrieveToken();
  const PATH = "/api/ispezioni/schede/pdf";
  let query = "?";

  if (ids) {
    query += `ids=${encodeURIComponent(ids)}`;
  }

  if (dataInizio) {
    query += `&startdate=${encodeURIComponent(dataInizio)}`;
  }

  if (dataFine) {
    query += `&enddate=${encodeURIComponent(dataFine)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const downloadPDFProdotti = (esercizioId, dataInizio, dataFine) => {
  const token = retrieveToken();
  const PATH = "/api/ispezioni/download/pdf/prodotti";
  let query = "?";

  if (esercizioId) {
    query += `esercizioId=${encodeURIComponent(esercizioId)}`;
  }

  if (dataInizio) {
    query += `&dataInizio=${encodeURIComponent(dataInizio)}`;
  }

  if (dataFine) {
    query += `&dataFine=${encodeURIComponent(dataFine)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
//Questa GET verrà ripetuta finchè lo stato non è DONE
export const getStatoFile = (esercizioId,revisioneId) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = "/api/ispezioni/download/stato_pdf?esercizioId="+esercizioId+"&revisioneId="+revisioneId;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
        'Authorization': authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}
