import {
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@material-ui/core";
import React, { Component } from "react";
import BasicTable from "../../components/BasicTable";
import { Search } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import { getGruppiFromBSFOODFiltered } from "../../../utils/api/gruppi-auditing_api";
import PropTypes from "prop-types";

const styles = {
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px grey",
    borderRadius: 20,
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75vw",
    minWidth: "820px",
    minHeight: "60vh",
  },
  modalSectionContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evely",
    gap: "20px",
  },
  tableContainer: {
    maxHeight: "500px",
    overflow: "hidden",
    width: "100%",
    minWidth: "70vw",
  },
  closeButton: {
    position: "absolute",
    top: "0.5em",
    right: "0.5em",
    padding: "4px",
    backgroundColor: "red",
    color: "white",
    boxShadow: "3px 3px 10px 0px rgba(0,0,0,0.45)",
    zIndex: 1,
  },
};

export class ModalVisualizzaGruppiBSFood extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gruppiBSFood: [],
      columns: [],
      loadedPage: 0,
      totalPages: 1,
      filter: {
        gruppoId: "",
        nome: "",
      },
    };
  }

  componentDidMount() {
    this.setState({
      columns: this.getColumns(),
      totalPages: Math.ceil(this.props.selectedGroups.length / 10),
    });
    this.fetchBSFoodGruppi(this.state.loadedPage);
  }

  fetchBSFoodGruppi = (pageIndex) => {
    getGruppiFromBSFOODFiltered(
      pageIndex,
      10,
      "id",
      this.props.selectedGroups,
      this.state.filter.nome,
      this.state.filter.gruppoId
    )
      .then((result) => {
        let filteredResult = result;
        if (this.state.filter.gruppoId) {
          filteredResult = result.filter(
            (item) => String(item.id) === this.state.filter.gruppoId
          );
        }
        this.setState({ gruppiBSFood: filteredResult });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  searchGruppiBSFood = () => {
    this.setState({
      loadedPage: 0,
    });
    this.fetchBSFoodGruppi(0);
  };

  getColumns = () => {
    return [
      {
        Header: "ID gruppo BS-FOOD",
        id: "id",
        accessor: (d) => d.id,
        style: { textAlign: "center", height: "44px" },
        filterable: true,
        Filter: (_cellinfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.filter.gruppoId}
            onChange={(e) => {
              const value = e.target.value;
              this.setState((prevState) => ({
                ...prevState,
                filter: { ...prevState.filter, gruppoId: value },
              }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton size="small" onClick={this.searchGruppiBSFood}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Nome gruppo BS-FOOD",
        id: "nome",
        accessor: (d) => d.nome,
        style: { textAlign: "center", height: "44px" },
        filterable: true,
        Filter: (_cellInfo) => (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.filter.nome}
            onChange={(e) => {
              const value = e.target.value;
              this.setState((prevState) => ({
                ...prevState,
                filter: { ...prevState.filter, nome: value },
              }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton size="small" onClick={this.searchGruppiBSFood}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
    ];
  };

  handlePageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () => this.fetchBSFoodGruppi(pageIndex)
    );
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={styles.modalContainer}>
          <div style={styles.modalSectionContainer}>
            <IconButton style={styles.closeButton} onClick={this.props.onClose}>
              <ClearIcon fontSize="small" />
            </IconButton>
            <BasicTable
              data={this.state.gruppiBSFood}
              columns={this.state.columns}
              defaultPageSize={10}
              page={this.state.loadedPage}
              pages={this.state.totalPages}
              onPageChange={this.handlePageChange}
              style={styles.tableContainer}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

ModalVisualizzaGruppiBSFood.propTypes = {
  selectedGroups: PropTypes.arrayOf(PropTypes.number),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleInvalidToken: PropTypes.func,
  handleError: PropTypes.func,
};

export default ModalVisualizzaGruppiBSFood;
