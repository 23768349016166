import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../theme";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import ReactTable from "react-table";
import { getEserciziCommerciali } from "../../utils/api/esercizi_commerciali_api";

const SelectTable = selectTableHOC(ReactTable);

const styles = {
  mainContainer: {
    flexgrow: 1,
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  modalcontainer: {
    overflowY: "scroll",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "15%",
    marginRight: "15%",
    height: "80%",
  },
  paper: {
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
      theme.palette.primary.main,
  },
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfieldscontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  rowcontainer: {
    flexgrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  buttoncontainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
};

export default class SelectEsercizio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      loading: true,
      records: [],
      modal: false,
      selected: false,
    };
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };
  fetchRecords = (zonaId) => {
    this.setState({
      loading: true,
    });
    getEserciziCommerciali(zonaId, null, 0, 1000, "id")
      .then((result) => {
        this.setState({
          records: result,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };
  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null) {
      return row[id] !== undefined
        ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
        : true;
    }
  };

  handleOpen = () => {
    this.setState({ modal: true });
    this.fetchRecords(this.props.zonaId);
  };

  handleClose = () => {
    this.setState({ modal: false, manualeIdSelezionato: null });
  };

  updateParentState(data) {
    this.props.updateParentState(data[0]);
    this.setState({ modal: false, selected: true });
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  toggleSelection = (key, shift, row) => {
    let selection = [...this.state.selection];

    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection = [];
      selection.push(row);
    }
    this.setState({ selection });
  };
  isSelected = (key) => {
    if (this.state.selection.some((e) => e.id === key)) {
      return true;
    }
    //  return this.state.selection.includes(key.id);
  };
  getColumns = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        minWidth: 80,
      },
      {
        Header: "Ragione sociale",
        id: "ragioneSociale",
        accessor: (d) => d.ragioneSociale,
        minWidth: 180,
      },
      {
        Header: "Partita IVA",
        id: "partitaIva",
        accessor: (d) => d.partitaIva,
        minWidth: 120,
      },
      {
        Header: "Codice ATECO",
        id: "codiceAteco",
        accessor: (d) => d.codiceAteco,
        minWidth: 120,
      },
      {
        Header: "Telefono",
        id: "telefono",
        accessor: (d) => d.telefono,
        width: 180,
      },
      {
        Header: "Città sede",
        id: "cittaSede",
        accessor: (d) => d.cittaSede,
        minWidth: 180,
      },
      {
        Header: "Zona",
        id: "zona",
        accessor: (d) => d.zona,
        minWidth: 180,
      },
    ];
  };

  render() {
    const columns = this.getColumns();

    return (
      <div>
        <Modal
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={styles.modalcontainer}>
            <SelectTable
              onFilteredChange={this.props.onFilteredChange}
              keyField={"id"}
              ref={(r) => (this.checkboxTable = r)}
              toggleSelection={this.toggleSelection}
              selectType="radio"
              isSelected={this.isSelected}
              filterable={true}
              resizable={true}
              showPageSizeOptions={true}
              showPageJump={true}
              defaultPageSize={10}
              data={this.state.records}
              columns={columns}
              previousText="Precedente"
              nextText="Successivo"
              noDataText="Nessun record"
              pageText="Pagina"
              ofText="di"
              rowsText="righe"
              pageJumpText="Vai a pagina"
              rowsSelectorText="righe per pagina"
              defaultFilterMethod={this.filterCaseInsensitive}
            />

            <Button
              type="button"
              onClick={() => this.updateParentState(this.state.selection)}
              variant="contained"
              size="medium"
              //  disabled={disabledSubmitbutton}
              style={{
                color: theme.palette.secondary.main,
                margin: "10px",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              Conferma
            </Button>
          </div>
        </Modal>

        <Button
          type="button"
          onClick={() => this.handleOpen()}
          variant="contained"
          size="medium"
          style={{
            color: theme.palette.secondary.main,
            margin: "10px",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          SELEZIONA ESERCIZIO
        </Button>
        {this.state.selected ? (
          <ReactTable
            filterable={false}
            resizable={false}
            showPageSizeOptions={true}
            showPageJump={true}
            defaultPageSize={1}
            maxRow={1}
            //pages={this.state.pages}
            data={this.state.selection}
            columns={columns}
            //manual // informs React Table that you'll be handling sorting and pagination server-side
            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
            showPagination={false}
            showPaginationTop={false}
            showPaginationBottom={false}
          />
        ) : null}
      </div>
    );
  }
}

SelectEsercizio.propTypes = {
  updateParentState: PropTypes.func.isRequired,
};
