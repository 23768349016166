
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import React, { Fragment } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ErrorDialog from '../../components/ErrorDialog';
import IndietroButton from "../../components/IndietroButton";
import SpinnerComponent from '../../components/SpinnerComponent';
import theme from '../../../theme';
import { getIspezione } from "../../../utils/api/ispezione_api";
import { clearToken } from '../../../utils/storage';
import Button from "@material-ui/core/Button";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

const styles = {
    mainContainer: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    paper: {
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '350px'
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    buttoncontainer: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'center',
    },
    overflowContainer: {
        overflowY: 'scroll',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: 600

    },
}

export default class RegistrazioniPDF extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
            record: {
                modeltext: 'Testo del modello ',
                inputtext: '',
            },
            edited: false,
            manual: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            model: null,
            errorDialogVisible: false,
            errorDialogMessage: '',
            loading: true
        };
    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    changePage = (offset) => {
        let pageNumber = this.state.pageNumber
        pageNumber = pageNumber + offset
        this.setState({ pageNumber });
    }

    previousPage = () => {
        this.changePage(-1);
    }

    nextPage = () => {
        this.changePage(1);
    }
    componentDidMount() {
        this.fetchRecord();
 }
 fetchRecord = () => {
     this.setState({
         loading: true,
     });
     let ispezioneId = this.props.ispezioneId;

     getIspezione(ispezioneId,null, 0, 1000, "id")
     .then(result => {
         this.setState({
             record: result[0],
             loading:false
         });
             })
             .catch(error => {
                 if (error.status === 403) {
                     this.handleInvalidToken();
                 } else {
                     this.setState({
                         loading: false,
                         errorDialogVisible: true,
                         errorDialogMessage: error.message,
                         notfoundRecord: true
                     });
                 }
             });
     
 }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }
    updateParentState(data) {
        this.props.edit(data);
        this.setState({ edited: true })
    }

    handleChange = (event, id) => {
        // this.updateParentState(true)
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let text = event.target.value;
        this.setState({
            text
        });
        // this.updateParentState({p1:data.inputtext,alertmodifica:true})
    }



    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    render() {
        return (
            <Fragment>
                <div style={styles.mainContainer} >
                    <Paper style={styles.paper} >
                        <Container id="paperContainer" style={{ paddingLeft: '4px', paddingRight: '4px', minHeight: '480px' }} >
                            {this.state.loading ?
                                <SpinnerComponent size={24} />
                                :
                                <Fragment>
                                    <div style={styles.rowcontainer}>

                                        <div>

                                            <form autoComplete="off" onSubmit={this.handleSubmit}>
                                            <Button
href={BASE_URL+ "/api/bsaudit/controlli_conformita/log/pdf?esercizioId="+this.state.record.esercizioCommercialeId+"&dataInizio="+this.state.record.dataInizioPeriodo+"&dataFine="+this.state.record.dataFinePeriodo}
color="transparent"
      target="_blank"
      download>FULLSCREEN
 </Button>
 
                                                <Grid container justify="center" alignItems="center">

                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                                        <Grid container style={styles.textfieldscontainer}>
                                                            <div style={styles.overflowContainer}>
                                                                <Document loading={<div style={styles.mainContainer}>Caricamento pdf in corso, attendere!</div>
                                                                } file={BASE_URL+ "/api/bsaudit/controlli_conformita/log/pdf?esercizioId="+this.state.record.esercizioCommercialeId+"&dataInizio="+this.state.record.dataInizioPeriodo+"&dataFine="+this.state.record.dataFinePeriodo} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                                {Array.from(
                                                                        new Array(this.state.numPages),
                                                                        (el, index) => (
                                                                            <Page
                                                                                key={`page_${index + 1}`}
                                                                                pageNumber={index + 1}
                                                                            />
                                                                        ),
                                                                    )}
                                                                </Document>

                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </form>
                                        </div>
                                      
                                    </div>
                                </Fragment>

                            }
                        </Container>
                        <div style={styles.buttoncontainer}>


                            <IndietroButton alert={this.state.edited} isDisabled={this.state.loading} />


                           
                        </div>
                    </Paper>
                </div>
                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>
            </Fragment>
        );
    }

}

