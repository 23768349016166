import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import React, { Component, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
// React-table library requires to import css as well:
import ReactTable from "react-table";
import "react-table/react-table.css"; // eslint-disable-next-line
import ErrorDialog from "../../components/ErrorDialog";
import IndietroButton from "../../components/IndietroButton";
import SpinnerComponent from "../../components/SpinnerComponent";
import theme from "../../../theme";
import { getIspezioniEsercizio } from "../../../utils/api/ispezione_api";
import { clearToken } from "../../../utils/storage";

export default class EsercizixIspezione extends Component {
  constructor(props) {
    super(props);
    const ID = parseInt(this.props.responsabileId, 10);
    // let incorrectId = false;
    // if (isNaN(ID)) {
    //     incorrectId = true;
    // }
    this.state = {
      ispezioni: [],
      pdfloading: false,

      consulenteId: null,
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: false,
      isResponsabileAssociatedToEsercizio: false,
      responsabileId: ID,
      recordNotFound: false,
    };
  }

  componentDidMount() {
    if (!this.state.recordNotFound) {
      this.fetchIspezioniEsercizio();
    }
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  fetchIspezioniEsercizio = () => {
    this.setState({
      loading: true,
    });
    getIspezioniEsercizio(
      this.props.ispettoreId,
      this.props.esercizioId,
      "COMPLETATO",
      0,
      1000,
      "id"
    )
      .then((result) => {
        this.setState({
          loading: false,
          ispezioni: result,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };
  scaricaSCU = (id) => {
    this.setState({ pdfloading: true });
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    window.location.href = ENDPOINT + "/api/ispezioni/" + id + "/scu";
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    let ispezioni = this.state.ispezioni;
    const columns = [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        minWidth: 80,
      },
      {
        Header: "Ispettore id",
        id: "idIspettore",
        accessor: (d) => d.ispettoreId,
        minWidth: 180,
      },
      {
        Header: "Data Inizio",
        id: "dataInizio",
        accessor: (d) => d.dataInizioPeriodo,
        minWidth: 120,
      },
      {
        Header: "Data Fine",
        id: "dataFine",
        accessor: (d) => d.dataFinePeriodo,
        minWidth: 120,
      },
      {
        Header: "Esito",
        id: "esito",
        accessor: (d) => d.esitoIspezione,
        minWidth: 180,
      },
      {
        id: "reportbtn",
        Header: "Scarica SCU",
        accessor: (row) => row,
        sortable: false,
        filterable: false,
        width: 180,
        Cell: (props) => (
          <IconButton
            size="small"
            style={{ color: theme.palette.primary }}
            onClick={() => this.scaricaSCU(props.row.id)}
          >
            <CloudDownloadIcon />
          </IconButton>
        ),
      },
    ];

    return (
      <Container
        id="mainContainer"
        style={{ paddingLeft: "4px", paddingRight: "4px", minHeight: "500px" }}
      >
        <LoadingOverlay
          active={this.state.pdfloading}
          spinner
          text="Caricamento pdf in corso ..."
        ></LoadingOverlay>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <Fragment>
            {this.state.recordNotFound ? (
              <Typography
                variant="h6"
                style={{ paddingTop: "6px", paddingBottom: "6px" }}
              >
                Nessun record trovato
              </Typography>
            ) : (
              <Fragment>
                <Typography
                  variant="h4"
                  style={{ paddingTop: "6px", paddingBottom: "20px" }}
                >
                  Ispezioni esercizio
                </Typography>
                <Container
                  id="tableContainer"
                  style={{ paddingBottom: "20px" }}
                >
                  <ReactTable
                    filterable={true}
                    resizable={false}
                    showPageSizeOptions={true}
                    showPageJump={true}
                    defaultPageSize={10}
                    //pages={this.state.pages}
                    data={ispezioni}
                    columns={columns}
                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                    previousText="Precedente"
                    nextText="Successivo"
                    noDataText="Nessun record"
                    pageText="Pagina"
                    ofText="di"
                    rowsText="righe"
                    pageJumpText="Vai a pagina"
                    rowsSelectorText="righe per pagina"
                  />
                </Container>
              </Fragment>
            )}
            <Grid
              container
              justify="center"
              style={{
                paddingBottom: "20px",
                paddingLeft: "24px",
                paddingRight: "24px",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ textAlign: "right" }}
              >
                <IndietroButton isDisabled={this.state.loading} />
              </Grid>
            </Grid>
          </Fragment>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        ></ErrorDialog>
      </Container>
    );
  }
}

EsercizixIspezione.propTypes = {};
