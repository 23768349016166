import React from "react";
import PropTypes from "prop-types";
import ActionButton from "../components/ActionButton";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "10px",
  },
};

export default class LoginResult extends React.Component {
  goBackToForm = (event) => {
    event.preventDefault();
    this.props.onBackButtonPressed();
  };

  componentDidMount() {
    if (!this.props.hasError) {
      window.location.href = "/";
      /*window.setTimeout(function () {
                window.location.href = "/";
            }, 4000);*/
    }
  }

  render() {
    const message = this.props.message;
    const hasError = this.props.hasError;
    return (
      <div style={styles.mainContainer}>
        <p style={{ fontSize: "20px" }}>{message}</p>
        <div style={{ textAlign: "center" }}>
          {hasError ? (
            <ActionButton
              onClick={this.goBackToForm}
              label="Indietro"
              disabled={false}
              grayversion={"true"}
            />
          ) : (
            <a href="/">Stai per essere reinderizzato alla home page</a>
          )}
        </div>
      </div>
    );
  }
}

LoginResult.propTypes = {
  hasError: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onBackButtonPressed: PropTypes.func.isRequired,
};
