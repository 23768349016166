import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";

import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../../../theme';

const styles = {
    container: {
        paddingBottom: '10px',
        textAlign: 'left'
    },
    label: {
        textAlign: 'left',
    },
    datetimeComponent: {
        padding: '4px 0px 4px 0px',
    },
}

export default function DatePickerComponent(props) {
    return (
        <ThemeProvider theme={theme}>
            <Container style={styles.container}>
                <Typography style={styles.label}>{props.label}</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                    <DatePicker
                        value={props.value}
                        onChange={(date) => {props.onChange(props.name, date)}}
                        format="dd-MM-yyyy"
                        disabled={props.disabled}
                        style={styles.datetimeComponent}
                    />
                </MuiPickersUtilsProvider>
            </Container>
        </ThemeProvider>
    );
}

DatePickerComponent.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}