import { retrieveToken } from "../storage";
import { wrapFetch, ApiError } from "./api";
const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getAllPagingGruppiAuditing = (
  page,
  size,
  sortby,
  id,
  name,
  tipoGruppo
) => {
  const token = retrieveToken();
  const PATH = "/api/gruppi/all";
  let query = `?page=${page}&size=${size}&sort=${sortby}&${sortby}.dir=ASC`;

  if (id) {
    query += `&id.equals=${encodeURIComponent(id)}`;
  }
  if (name) {
    query += `&nome.contains=${encodeURIComponent(name)}`;
  }
  if (tipoGruppo) {
    query += `&tipoGruppo.equals=${encodeURIComponent(tipoGruppo)}`;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getGroupById = (id) => {
  const token = retrieveToken();
  const PATH = "/api/gruppi/id/" + id;

  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getCountGruppi = (esercizioId, gruppoId) => {
  const token = retrieveToken();
  const PATH = "/api/gruppi/count";
  let query = "?";
  if (esercizioId || esercizioId !== "") {
    query += `&esercizioId.equals=${encodeURIComponent(esercizioId)}`;
  }
  if (gruppoId) {
    query += `&id.equals=${encodeURIComponent(gruppoId)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getGruppiByEsercizioCommerciale = (
  page,
  size,
  sortby,
  auditorId,
  esercizioId,
  id,
  name,
  tipoGruppo
) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = `/api/gruppi/all?page=${page}&size=${size}&${sortby}.dir=ASC`;
  let query = auditorId ? `&id.equals=${encodeURIComponent(auditorId)}&` : "";
  query += `&esercizioId.equals=${encodeURIComponent(esercizioId)}`;

  if (id) {
    query += `&id.equals=${encodeURIComponent(id)}`;
  }
  if (name) {
    query += `&nome.contains=${encodeURIComponent(name)}`;
  }
  if (tipoGruppo) {
    query += `&tipoGruppo.equals=${encodeURIComponent(tipoGruppo)}`;
  }

  const URL = BASE_URL + PATH + query;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      credentials: "include",
    }),
    errorFunction
  );
};

export const getGruppiFromBSFOODFiltered = (
  page,
  size,
  sortby,
  gruppiIds,
  filterName
) => {
  const token = retrieveToken();
  const PATH = "/api/bsfood/gruppi";
  let query = `?page=${page}&size=${size}&sort=${sortby},ASC`;
  if (gruppiIds) {
    query += `&id.in=${gruppiIds}`;
  }
  if (filterName) {
    query += `&nome.contains=${encodeURIComponent(filterName)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getGruppiFromBSFOOD = (page, size, sortby) => {
  const token = retrieveToken();
  const PATH = "/api/bsfood/gruppi";
  let query = `?page=${page}&size=${size}&sort=${sortby},ASC`;
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
export const getCountGruppiAuditingFromBSFood = () => {
  const token = retrieveToken();
  const PATH = "/api/bsfood/gruppi/count";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const updateGruppo = (requestBody) => {
  const token = retrieveToken();
  let PATH = "/api/gruppi/update/name";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: ispettore non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const updateGruppoPersonalizzato = (requestBody) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = "/api/gruppi/update/esercizi";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: ispettore non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const getEserciziByGruppoBsFood = (
  page,
  size,
  sortby,
  gruppoId,
  idEsercizio,
  name,
  ragioneSociale,
  provincia,
  citta,
  indirizzo
) => {
  const token = retrieveToken();
  const PATH = "/api/bsfood/esercizi_commerciali";
  let query = `?page=${page}&size=${size}&sort=${sortby},ASC`;

  if (gruppoId) {
    query += `&gruppoId.equals=${encodeURIComponent(gruppoId)}`;
  }

  if (idEsercizio) {
    query += `&id.equals=${encodeURIComponent(idEsercizio)}`;
  }
  if (name) {
    query += `&nome.contains=${encodeURIComponent(name)}`;
  }
  if (ragioneSociale) {
    query += `&ragioneSociale.contains=${encodeURIComponent(ragioneSociale)}`;
  }

  if (provincia) {
    query += `&provincia.contains=${encodeURIComponent(provincia)}`;
  }

  if (citta) {
    query += `&citta.contains=${encodeURIComponent(citta)}`;
  }

  if (indirizzo) {
    query += `&indirizzo.contains=${encodeURIComponent(indirizzo)}`;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getCountEserciziByGruppoBsfood = (gruppoId) => {
  const token = retrieveToken();
  const PATH = `/api/bsfood/esercizi_commerciali/count`;
  let query = "?";
  if (gruppoId) {
    query += `gruppoId.equals=${encodeURIComponent(gruppoId)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getSyncEserciziGruppiRapidi = (gruppoId) => {
  const token = retrieveToken();
  const PATH = `/api/sinc_esercizi/latest`;
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
